import { ComponentType, FC } from 'react'

import CustomerProvider from '../components/BuyerProfile/Account/CustomerProvider'

const withCustomer = <Props extends {}>(
  WrappedComponent: ComponentType<Props>,
) => {
  const WithCustomer: FC<Props> = (props) => (
    <CustomerProvider>
      <WrappedComponent {...props} />
    </CustomerProvider>
  )

  return WithCustomer
}

export default withCustomer
