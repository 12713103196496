import { useRouter } from 'next/router'

import { isFreeDelivery } from '../lib/product'
import { getProductPrice } from '../lib/resources/price'
import { getSupplierDeliveryDetails } from '../lib/service'
import { Product } from '../utils/types/Product'
import { getCountryAndLocaleStrings } from '../utils/locales'
import { useCart } from '../components/Cart/CartProvider'
import { selectSupplierTotal } from '../components/Cart/selectors'
import { getCurrentUpcomingDiscount } from '../lib/resources/discount'
import { isProductInclude } from '../utils/types/guards/Order'

/**
 * Calculates the shipping weighted product price
 * @param product - The product to calculate the shipping weighted price for
 * @param totalQuantity - The total quantity of the product variants
 * @param supplierTotalValue - The total sum of current supplier's products in cart or catalog,
 * calculated automatically from current cart if not provided as parameter.
 * @returns Object containing shipping weighted product price for single and sum of all variants,
 *  with and without discounts
 */
const useProductWeightedPrice = (
  product: Product | undefined,
  totalQuantity: number | undefined,
  supplierTotalValue?: number,
): {
  // Includes shipping cost:
  weightedPrice: number
  totalWeightedPrice: number
  weightedPriceDiscounted: number
  totalWeightedPriceDiscounted: number
  // Does not include shipping cost:
  productPrice: number
  totalProductPrice: number
  productPriceDiscounted: number
  totalProductPriceDiscounted: number
} => {
  const { locale: countryAndLangString } = useRouter()
  const { country } = getCountryAndLocaleStrings(countryAndLangString)
  const { modifiedCart } = useCart()

  const supplier = product?.supplier[0]
  const cartSupplier = modifiedCart?.supplierMappedProducts.find(
    (supp) => supp.supplierCode === product?.supplier[0]['supplier.code'],
  )

  // Use cart value unless supplierTotalValue is provided
  const supplierTotal = supplierTotalValue || (modifiedCart && cartSupplier
    ? selectSupplierTotal(modifiedCart, cartSupplier) || 0
    : 0)

  const { currentDiscount } = getCurrentUpcomingDiscount(product, Number(totalQuantity))
  const { deliveryCost } = product && isFreeDelivery(product, country, supplierTotal)
    ? { deliveryCost: 0 }
    : getSupplierDeliveryDetails(supplier, country)
  const productPrice = getProductPrice(product)
  const cartProductPrice = Number(modifiedCart
    ?.included
    ?.filter(isProductInclude)
    ?.find((cartProduct) => (
      cartProduct.attributes['order.product.parentproductid']
      || cartProduct.attributes['order.product.productid']) === product?.id)
    ?.attributes['order.product.price'])
  const discountedPrice = cartProductPrice || Number(currentDiscount?.['price.value'] || productPrice)

  // Ratio for how large part of the total supplier cart value this current product is
  // If a supplierTotalValue is passed then use full product price,
  // otherwise try using the cart product price, as it might be lower because of discounts
  const productRatio = supplierTotalValue
    ? productPrice / supplierTotalValue
    : (cartProductPrice || productPrice) / supplierTotal

  const weightedPrice = supplierTotal > 0
    ? productPrice + (deliveryCost * productRatio)
    : productPrice
  const weightedPriceDiscounted = supplierTotal > 0
    ? discountedPrice + (deliveryCost * productRatio)
    : discountedPrice

  return {
    // Includes shipping cost
    weightedPrice,
    totalWeightedPrice: weightedPrice * (totalQuantity || 1),
    weightedPriceDiscounted,
    totalWeightedPriceDiscounted: weightedPriceDiscounted * (totalQuantity || 1),
    // Does not include shipping cost
    productPrice,
    totalProductPrice: productPrice * (totalQuantity || 1),
    productPriceDiscounted: discountedPrice,
    totalProductPriceDiscounted: discountedPrice * (totalQuantity || 1),
  }
}

export default useProductWeightedPrice
