import useTrackingContext, { TrackingDispatchParams } from '../../../services/trackingContext/useTrackingContext'
import { MODAL_HASHES } from '../../../utils/constants'
import { AnalyticsContext, ContactContext } from '../../../utils/types/analytics'
import useModalTrigger from './useModalTrigger'

export type OpenContactModal = (
  contactContext?: ContactContext,
  contextTrigger?: AnalyticsContext
) => void

/**
 * Returns contact modal trigger.
 *
 * Analytics context parameter can be set at hook level,
 * which will populate every modal trigger with that context.
 * It can be overriden for individual triggers by
 * providing it in the trigger function's parameter.
 *
 * @param contactContextValue DEPRECATED - Contact context parameter for analytics events.
 * Populates contact context parameter, but can be overriden in modal trigger. NOTE:
 * contact_context param will be completely replaced by the context param after 6-12 months,
 * after a significant amount of data has populated.
 * @param contextValue The context parameter used to populate analytics events.
 * @returns openContactModal() function
 */
const useContactModal = (contactContextValue?: ContactContext, contextValue?: AnalyticsContext) => {
  const { openModalTrigger } = useModalTrigger(MODAL_HASHES.CONTACT_URL_HASH)
  const { state, dispatchTrackingContext } = useTrackingContext()

  const openContactModal: OpenContactModal = (contactContextTrigger, contextTrigger) => {
    const contactEvent: TrackingDispatchParams = {
      eventName: 'contact_event',
      eventParameters: {
        contact_context: contactContextTrigger || contactContextValue || 'contact_modal',
        context: contextTrigger || contextValue || 'contact_modal',
      },
      util: { ...state.util },
    }

    // Push event data to context
    dispatchTrackingContext(contactEvent)
    openModalTrigger()
  }

  return openContactModal
}

export default useContactModal
