import { useHits } from 'react-instantsearch'

import { CurrencyId, Product } from './Product'
import { Locale } from '../../external'
import { CountryId } from '../constants'

export type TypesenseProduct = {
  id: string
  sku: string
  'variant-skus': string[]
  price: number
  texts: {
    brand?: string
    modal?: string
  } & {
    [locale in Locale]: {
      name: string
      description: string
    }
  }
  categories: {
    [level in 'one' | 'two' | 'three']: {
      [locale in Locale]: string
    }
  }
  supplier: string
  labels: string[]
  'use-cases': string[]
  'feature-types': {
    [locale in Locale]: Record<string, string>
  }
  standards: {
    base?: string[]
    version?: string[]
    test?: string[]
  }
  image: string
  'country-availability': Uppercase<CountryId>[]
} & {
  // Needed to allow accessing nested fields via full field path in instantsearch
  [field in `texts.${Locale}.${'name' | 'description'}`]: string
} & {
  // Needed to allow accessing nested fields via full field path in instantsearch
  [field in `texts.${'brand' | 'model'}`]: string
}

export type TypesenseSupplier = {
  id: string
  name: string
  code: string
  image: string
} & {
  [description in `description_${Locale}`]: string
}

export type SearchResultsTypesenseProduct = ReturnType<typeof useHits<TypesenseProduct>>['results']
export type SearchResultsTypesenseSupplier = ReturnType<typeof useHits<TypesenseSupplier>>['results']

export type SearchResults = SearchResult[] | Product[] | null

export const isProductArray = (array: SearchResults): array is Product[] => (
  array !== null && ((array[0] as Product)?.id !== undefined)
)
export interface SearchResult {
  item_id: string
  item_name: string
  price: number
  currency: CurrencyId
  item_brand: string
  supplier?: string
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
}
