import TagManager from 'react-gtm-module'

import { roundToTwoDecimals } from '../math'
import { AnalyticsContext } from '../types/analytics'
import { emptyEcomDataLayer, getCartTotalDiscount, getCartTotalValue, getCartEcomEventItems } from './utils'
import { CartResponse, WithSuppliers } from '../types/Order'
import { ActiveCountryId } from '../constants'
import { Product } from '../types/Product'

export type FlowType = 'single_product' | 'multiple_products' | '' // Used for Ortto abandonment flow

interface AddContactInfoProps {
  context?: AnalyticsContext
  productSKU?: string
  email?: string
  phoneNumber?: string
  countryPhonePrefix?: string
  productUrl?: string
  productName?: string
  flowType?: FlowType
  isCheckout?: boolean
  cartData?: WithSuppliers<CartResponse>
  country: ActiveCountryId
  getLocalizedProductUrl: (product?: Product) => string
}

// Add contact info can be used in two different contexts:
// 1. Checkout flow (params isCheckout, cartData and categories are required)
// 2. Any other form that requires the user to input their email address
const addContactInfo = (params: AddContactInfoProps) => {
  const {
    context = '',
    productSKU = '',
    email = '',
    phoneNumber = '',
    flowType = '',
    productUrl = '',
    productName = '',
    isCheckout = false,
    cartData,
    countryPhonePrefix,
    country,
    getLocalizedProductUrl,
  } = params

  emptyEcomDataLayer() // First empty datalayer

  TagManager.dataLayer({ dataLayer: {
    event: 'add_contact_info',
    context,
    product_id: productSKU,
    product_url: productUrl,
    product_name: productName,
    email,
    phone_number: phoneNumber,
    phone_country_code: countryPhonePrefix,
    is_checkout: isCheckout,
    flow_type: flowType,
    ecommerce: cartData
      ? {
        affiliation: 'Droppe Marketplace',
        value: roundToTwoDecimals(cartData.data?.attributes['order.price'] || 0),
        tax: roundToTwoDecimals(cartData.data?.attributes['order.taxvalue'] || 0),
        shipping: roundToTwoDecimals(cartData.data?.attributes['order.costs'] || 0),
        currency: cartData.data?.attributes['order.currencyid'],
        coupon: 'none',
        totalValue: getCartTotalValue(cartData),
        discount: getCartTotalDiscount(cartData),
        items: getCartEcomEventItems({
          cartData,
          country,
          getLocalizedProductUrl,
        }),
      }
      : null,
  } })
}

export default addContactInfo
