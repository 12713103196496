import { FC, MouseEvent, useEffect } from 'react'
import { Highlight, HitsProps, useHits } from 'react-instantsearch'
import { useRouter } from 'next/router'
import { FlexboxGrid, List, Stack } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'

import useUrls from '../../services/useUrls'
import Img from '../Img'
import { getCountryAndLocaleStrings } from '../../utils/locales'
import { normalizeString } from '../../utils/util'
import { SearchResultsTypesenseProduct, TypesenseProduct } from '../../utils/types/search'
import searchResultClickEvent from '../../utils/analytics/searchResultClickEvent'
import { HIT_COUNT_POPUP } from '../../utils/typesense/config'

import styles from '../../styles/Search.module.less'

interface ProductHitsProps extends HitsProps<TypesenseProduct> {
  closeModal: () => void
  onResultsChange: (results: SearchResultsTypesenseProduct) => void
  triggerSearchEvent: () => void
}

const ProductHits: FC<ProductHitsProps> = (props) => {
  const { closeModal, onResultsChange, triggerSearchEvent, ...defaultProps } = props

  const { locale: countryAndLocale } = useRouter()
  const { locale, country } = getCountryAndLocaleStrings(countryAndLocale)
  const { urlT } = useUrls()
  const { t } = useTranslation('products')

  const {
    hits,
    results,
  } = useHits(defaultProps)

  // Callback to access search results in parent component
  useEffect(() => {
    if (results) {
      onResultsChange(results)
    }
  }, [results, onResultsChange])

  const url = (sku: string, name: string) => urlT(`/product/${sku.toLowerCase()}+${normalizeString(name).toLowerCase()}`)

  const handleSearchResultClick = (
    event: MouseEvent<HTMLAnchorElement>,
    hit: TypesenseProduct,
  ) => {
    if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button === 0) {
      closeModal()
    }

    // Trigger search event if it hasn't yet been triggered
    triggerSearchEvent()

    // Analytics event
    searchResultClickEvent({
      searchTerm: results?.query || '',
      locale,
      country,
      resultQuantity: results?.nbHits || 0,
      productSku: hit.sku,
      supplier: hit.supplier,
      clickType: event.button === 2 ? 'right' : undefined,
      context: 'search_modal',
      searchDomain: 'product',
    })
  }

  return (
    <>
      <FlexboxGrid
        align="middle"
        justify="space-between"
      >
        <FlexboxGrid.Item>
          <h5>{t('Products')}</h5>
        </FlexboxGrid.Item>
        { results && (
          <FlexboxGrid.Item>
            {t('common:Showing {{ hits }} of {{ total }} results', { hits: Math.min(results.hits.length, HIT_COUNT_POPUP), total: results.nbHits })}
          </FlexboxGrid.Item>
        ) }
      </FlexboxGrid>
      <List
        size="sm"
        className={styles['hits-list']}
        data-testid="search-product-hits"
      >
        { hits.slice(0, HIT_COUNT_POPUP).map((hit) => (
          <List.Item
            key={hit.id}
            data-testid="search-product-hit"
          >
            <Link
              href={url(hit.sku, hit.texts[locale ?? 'en'].name)}
              onClick={(event) => handleSearchResultClick(event, hit)}
              // // Track right clicks
              onContextMenu={(event) => handleSearchResultClick(event, hit)}
            >
              <FlexboxGrid
                className={styles['hit-row']}
                align="middle"
                justify="start"
              >
                <FlexboxGrid.Item className={styles['image-container']}>
                  <Img
                    src={hit.image}
                    layout="fill"
                  />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className={styles['text-box']}>
                  <Stack
                    direction="column"
                    justifyContent="space-between"
                  >
                    <Stack.Item>
                      <h6 className={styles.name}>
                        <Highlight
                          hit={hit}
                          attribute={`texts.${locale ?? 'en'}.name`}
                        />
                      </h6>
                    </Stack.Item>
                    <Stack.Item>
                      <Highlight
                        className={styles.sku}
                        hit={hit}
                        attribute="sku"
                      />
                    </Stack.Item>
                  </Stack>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </Link>
          </List.Item>
        )) }
      </List>
    </>
  )
}

export default ProductHits
