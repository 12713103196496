/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
// Above eslint rules are disabled because of using <a> in <SafeTranslate> component
import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import SafeTranslate from '../../../common/SafeTranslate'
import useGetAnalyticsLocation from '../../../../services/analytics/useGetAnalyticsLocation'
import { AnalyticsSubLocation } from '../../../../utils/types/analytics'
import useRFQModal from '../../../Modals/hooks/useRFQModal'
import SearchGraphic from '../../../Icons/SearchGraphic'

import styles from '../../../../styles/SearchPopover.module.less'
import buttonStyles from '../../../../styles/CustomButtons.module.less'

interface SearchPopoverContentProps {
  className?: string
}

const SearchPopoverContent: FC<SearchPopoverContentProps> = (props) => {
  const { className = '' } = props

  const { t } = useTranslation('common')

  const analyticsLocation = useGetAnalyticsLocation(AnalyticsSubLocation.SearchPopover)
  const openRFQModal = useRFQModal({ analyticsLocation })

  return (
    <div className={`${styles['search-popover-content']} ${className}`}>
      <SearchGraphic />
      <p className={`h5 ${styles.headline}`}>{t('landing:Over 20,000 products from 100s of brands, all on one platform')}</p>
      <p className={styles.subheadline}>
        <SafeTranslate
          i18nKey="modals:Start by typing the product you want in the search field or request a quote in seconds"
          components={[<button
            className={buttonStyles['link-button']}
            type="button"
            onClick={(e) => {
              e.preventDefault()
              openRFQModal()
            }}
          />]}
        />
      </p>
    </div>
  )
}

export default SearchPopoverContent
