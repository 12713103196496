import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { getCountryAndLocaleStrings } from '../utils/locales'
import useUrls from './useUrls'

const usePersistLocale = () => {
  const {
    locale: countryAndLocale,
    defaultLocale: defaultCountryAndLocale,
    asPath,
    replace,
  } = useRouter()
  const { urlT } = useUrls()

  const { locale } = getCountryAndLocaleStrings(countryAndLocale)
  const { locale: defaultLocale } = getCountryAndLocaleStrings(defaultCountryAndLocale)

  useEffect(() => {
    // Only translate product and supplier pages
    // If making changes to URL formatting, don't touch query params!
    if (/^\/pro|\/sup|\/tuo|\/toim/.test(asPath)) {
      const translated = urlT(asPath)
      if (asPath !== translated && !translated.includes('catalog:')) {
        // The url is not in the selected locale,
        // redirect to correctly localized url
        replace(translated)
      }
    }
  }, [locale, defaultLocale, asPath])

  useEffect(() => {
    const date = new Date()
    const expireMs = 100 * 24 * 60 * 60 * 1000 // 100 days
    date.setTime(date.getTime() + expireMs)
    document.cookie = `NEXT_LOCALE=${countryAndLocale};expires=${date.toUTCString()};path=/;domain=${window.location.host}`
  }, [countryAndLocale, defaultLocale])
}

export default usePersistLocale
