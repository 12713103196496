import { FC, useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { FlexboxGrid, Form } from 'rsuite'
import { useRouter } from 'next/router'

import { ProductText } from '../../../utils/types/Product'
import TextareaWithLangPicker from '../../Input/TextareaWithLangPicker'
import { Locale } from '../../../external'
import { getCountryAndLocaleStrings } from '../../../utils/locales'
import { SUPPLIER_DESCRIPTION_MAX_LENGTH } from '../../../utils/constants'

import styles from '../../../styles/Profile.module.less'
import customFormStyles from '../../../styles/CustomForm.module.less'

interface SupplierTextFieldProps {
  labelText: string
  required: boolean
  value?: ProductText[]
  placeholder: string
  onChange: (value: ProductText[]) => void
  lang?: ProductText['text.languageid']
  domain?: ProductText['text.domain']
}

const SupplierTextField: FC<SupplierTextFieldProps> = (props) => {
  const {
    labelText,
    value,
    placeholder,
    onChange,
    domain = 'supplier',
  } = props
  const { t } = useTranslation('profile')

  const { locale: countryAndLocale } = useRouter()
  const { locale } = getCountryAndLocaleStrings(countryAndLocale)

  let currentText = value?.find((text) => text['text.type'] === 'long')

  const [sourceLang, setSourceLang] = useState(currentText?.['text.languageid'] || locale)

  useEffect(() => {
    const currentLanguage = currentText?.['text.languageid']

    if (currentLanguage) {
      setSourceLang(currentLanguage)
    }
  }, [!!currentText])

  const onChangeToText = (newValue: string) => {
    if (!currentText) {
      currentText = {
        id: '',
        'text.type': 'long',
        'text.label': `Default long ${sourceLang}`,
        'text.languageid': sourceLang,
        'text.content': newValue,
        'text.domain': domain,
      }
      value?.push(currentText)
    }

    currentText['text.content'] = newValue
    currentText['text.languageid'] = sourceLang

    onChange(value || [currentText])
  }

  const onChangeLanguage = (newLang: Locale) => {
    setSourceLang(newLang)
  }

  const valueLength = currentText?.['text.content']?.length || 0
  const isTooLong = valueLength > SUPPLIER_DESCRIPTION_MAX_LENGTH

  return (
    <>
      <FlexboxGrid justify="space-between">
        <Form.ControlLabel htmlFor="story">{t(labelText)}</Form.ControlLabel>
        <p className={`
          ${customFormStyles['character-count']}
          ${isTooLong ? customFormStyles.error : ''}`}
        >
          {valueLength}
          {' / '}
          {SUPPLIER_DESCRIPTION_MAX_LENGTH}
        </p>
      </FlexboxGrid>
      <TextareaWithLangPicker
        className={isTooLong ? styles['description-error'] : ''}
        name="story"
        id="story"
        aria-label="Supplier story"
        onChange={onChangeToText}
        value={currentText?.['text.content'] || ''}
        placeholder={`${t(placeholder)} ...`}
        onChangeLanguage={onChangeLanguage}
        lang={sourceLang || 'en'}
      />
    </>
  )
}

export default SupplierTextField
