import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { FC } from 'react'

import { useAuth } from '../../services/useAuth'
import BaseModal from './BaseModal'

const InvalidSessionModal: FC = () => {
  const { closeInvalidSessionModal, isInvalidSessionModalOpen } = useAuth()
  const { asPath, push } = useRouter()
  const { t } = useTranslation('url')

  const redirectToLogin = () => {
    closeInvalidSessionModal()
    const loginUrl = `/${t('login')}`

    // If not already on the login page, Redirect the user to the login page
    // on unauthenticated error, with the origin URL in the params
    if (!asPath.includes(loginUrl)) {
      push({
        pathname: loginUrl,
        query: { from: asPath },
      })
    }
  }

  const testIds = {
    modalTestId: 'invalid-session-modal',
    closeButtonTestId: 'close-invalid-session-modal',
  }

  return (
    <BaseModal
      testIds={testIds}
      isOpen={isInvalidSessionModalOpen}
      closeModal={closeInvalidSessionModal}
      title="Your session has expired"
      actionButtonText="Log in"
      closeButtonText="Close"
      modalAction={redirectToLogin}
    >
      If you want to continue session, please log in again.
    </BaseModal>
  )
}

export default InvalidSessionModal
