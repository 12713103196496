import TagManager from 'react-gtm-module'

import { Locale } from '../../external'
import { ActiveCountryId } from '../constants'

export interface SearchResultClickEventParams {
  searchTerm: string
  locale: Locale
  country: ActiveCountryId
  resultQuantity: number
  productSku?: string
  supplier: string
  clickType?: 'right' | undefined // Used to track right click events
  context: 'search_modal' | 'search_results_page' | 'search_results_page_supplier_card'
  searchDomain: 'product' | 'supplier'
}

const searchResultClickEvent = (searchResultClickEventParams: SearchResultClickEventParams) => {
  const {
    searchTerm,
    locale,
    country,
    resultQuantity,
    productSku,
    supplier,
    clickType,
    context,
    searchDomain,
  } = searchResultClickEventParams

  TagManager.dataLayer({
    dataLayer: {
      event: 'search_result_click',
      language: locale,
      country,
      search_term: searchTerm,
      result_quantity: resultQuantity,
      product_id: productSku,
      supplier,
      click_type: clickType,
      context,
      search_domain: searchDomain,
    },
  })
}

export default searchResultClickEvent
