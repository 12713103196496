import TagManager from 'react-gtm-module'

export interface AddPersonalInfoBuyerOnboardingParams {
  fullName: string
  phoneNumber: string
}

const addPersonalInfoBuyerOnboarding = ({
  fullName,
  phoneNumber,
}: AddPersonalInfoBuyerOnboardingParams) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'add_personal_info_buyer_onboarding',
      full_name: fullName,
      phone_number: phoneNumber,
    },
  })
}

export default addPersonalInfoBuyerOnboarding
