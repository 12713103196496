/**
 * Tracking event names and parameters
 * A comprehensive list can be found from the official Droppe Analytics documentation
 */

import { Dispatch } from 'react'

import { Product } from './Product'
import { CountryAndLocale } from '../../external'
import { CartSupplierProducts } from './Cart'

// RFQ Modal
export const RFQModalEvents = {
  Begin: 'begin_request_for_quote',
  AddContactInfo: 'add_contact_info',
  AddProductInfo: 'add_product_info',
  Submit: 'submit_request_for_quote',
  BeginLowPurchaseVolumeFlow: 'begin_low_purchase_volume_flow',
} as const

export type RFQModalEventsValue = typeof RFQModalEvents[keyof typeof RFQModalEvents]

// Sample request modal
export const SampleRequestModalEvents = {
  Begin: 'begin_sample_request',
  AddContactInfo: 'add_contact_info',
  AddProductInfo: 'add_product_info',
  Submit: 'submit_sample_request',
  BeginLowPurchaseVolumeFlow: 'begin_low_purchase_volume_flow',
} as const

export type SampleRequestModalEventsValue = typeof SampleRequestModalEvents[
  keyof typeof SampleRequestModalEvents
]

// Document request modal
export const DocumentRequestModalEvents = {
  Begin: 'begin_document_request',
  AddContactInfo: 'add_contact_info',
  AddDocumentInfo: 'add_document_info',
  Submit: 'submit_document_request',
} as const

export type DocumentRequestModalEventsValue = typeof DocumentRequestModalEvents[
  keyof typeof DocumentRequestModalEvents
]

// Lopin / Register modal
export const LoginModalEvents = {
  ViewLoginModal: 'view_login_modal',
  ViewRegisterModal: 'view_register_modal',
  Login: 'login',
  Logout: 'logout',
  SignUp: 'sign_up', // Used for Register event
  ForgotPassword: 'forgot_password',
} as const

export type LoginModalEventsValue = typeof LoginModalEvents[keyof typeof LoginModalEvents]

export interface ModalTrackingParameters {
  context?: AnalyticsContext
  product_id?: string
  estimated_volume?: string
}

// Contact modal
export type ContactEvent = 'contact_event'

export interface ContactParameters {
  contact_context: ContactContext
  context?: AnalyticsContext
}

// DEPRACATED
// Kept for now to maintain backwards compatility
// TODO: Remove once only context param is being used in GA4
export type ContactContext =
| 'front_page_modal'
| 'main_header_modal'
| 'main_header_call'
| `single_supplier_form_${string}` // string represents supplier name
| 'product_specialist'
| 'product_specialist_single_product'
| 'product_specialist_empty_search'
| 'footer_call'
| 'footer_email'
| 'footer_modal'
| 'top_banner_modal'
| 'contact_modal'
| 'product-standards'
| 'chat_started'
| 'single_supplier'
| 'error'

/**
 * Context parameter
 * Feel free to expand the list when additional context locations / functions are needed.
 * See the full list and definitions from the official Droppe Analytics documentation
 */
export const AnalyticsMainLocation = {
  ProductCatalog: 'product_catalog',
  SupplierCatalog: 'supplier_catalog',
  Cart: 'cart',
  SingleProduct: 'single_product',
  SingleProductStandards: 'single_product_standards',
  SingleSupplier: 'single_supplier',
  SingleSupplierCatalog: 'single_supplier_catalog',
  MainHeader: 'main_header',
  MainFooter: 'main_footer',
  FrontPage: 'front_page',
  TopHeaderBar: 'top_header_bar',
  MobileBlocker: 'mobile_blocker',
  Checkout: 'checkout',
  NotFound404: '404',
  Error: 'error',
  BuyerCatalog: 'buyer_catalog',
  BuyerOrderHistory: 'buyer_order_history',
  BuyerOrderedProducts: 'buyer_previously_ordered_products',
  BuyerSavedProducts: 'buyer_saved_products',
} as const

export type AnalyticsMainLocationValue = typeof AnalyticsMainLocation[
  keyof typeof AnalyticsMainLocation
]

// Only used after main location
// Check definitions from analytics documentation
export const AnalyticsSubLocation = {
  EmptyState: 'empty_state',
  ProductSpecialist: 'product_specialist',
  Banner: 'banner',
  BannerCard: 'banner_card',
  ProductCard: 'product_card',
  SupplierCard: 'supplier_card',
  SearchPopover: 'search_popover',
  CtaElement: 'cta_element',
  SingleProductStandards: 'single_product_standards',
} as const

export type AnalyticsSubLocationValue = typeof AnalyticsSubLocation[
  keyof typeof AnalyticsSubLocation
]

// Analytics functions
export const AnalyticsModalFunction = {
  RFQ: 'request_for_quote',
  SampleRequest: 'sample_request',
  DocumentRequest: 'document_request',
  ContactModal: 'contact_modal',
  Login: 'login_modal',
  Register: 'register_modal',
} as const

export type AnalyticsModalFunctionValue = typeof AnalyticsModalFunction[
  keyof typeof AnalyticsModalFunction
]

export const AnalyticsFunction = {
  ...AnalyticsModalFunction,
  SupplierContactForm: 'supplier_contact_form',
  Email: 'email',
  Call: 'call',
  Chat: 'chat',
  SupplierInquiry: 'supplier_inquiry',
  SaveProduct: 'save_product',
  UnsaveProduct: 'unsave_product',
} as const

export type AnalyticsFunctionValue = typeof AnalyticsFunction[keyof typeof AnalyticsFunction]

// Passed into analytics hooks as a param
// Informs the marketplace location where the analytics event is triggered
export type AnalyticsLocation = AnalyticsMainLocationValue
| AnalyticsSubLocationValue
| `${AnalyticsSubLocationValue}_${AnalyticsSubLocationValue}`
| `${AnalyticsMainLocationValue}_${AnalyticsSubLocationValue}`
| `${AnalyticsMainLocationValue}_${AnalyticsSubLocationValue}_${AnalyticsSubLocationValue}` // Example: product_catalog_empty_state_product_specialist

// Final value used for analytics "context" parameter
export type AnalyticsContext = AnalyticsLocation
| AnalyticsFunctionValue
| `${AnalyticsLocation}_${AnalyticsFunctionValue}`
// Used when triggered from a direct link, #modal links for example
| `direct_${AnalyticsFunctionValue}`
| 'direct'
| ''

/**
 * Global state object type
 *
 * @example:
 * {
 *    begin_sample_request: {
 *      context: 'single_product_product_specialist_sample_request'
 *     },
 *    submit_sample_request: {
 *      context: 'single_product_product_specialist_sample_request'
 *     },
 *    util: {
 *      product: Product
 *    }
 * }
 */
export type GlobalTrackingObject = Partial<
Record<ContactEvent, ContactParameters>
& Record<RFQModalEventsValue, ModalTrackingParameters>
& Record<SampleRequestModalEventsValue, ModalTrackingParameters>
& Record<DocumentRequestModalEventsValue, ModalTrackingParameters>
& Record<LoginModalEventsValue, ModalTrackingParameters>
& Record<'util', UtilParameters>
>

/**
 *
 * React context and provider types.
 *
 */
// List of all event names, expand when new tracking events are added.
// Used when dispatching events to GlobalTrackingObject
export type TrackingEventName = ContactEvent
| RFQModalEventsValue
| SampleRequestModalEventsValue
| DocumentRequestModalEventsValue
| LoginModalEventsValue

// Object containing all possible event parameter values.
// Used when dispatching events to GlobalTrackingObject
export type TrackingEventParameters = ContactParameters
| ModalTrackingParameters

// Util data to supplement components
// Example: Ensure RFQ modal shows product when triggered from SPP
export interface UtilParameters {
  product?: Product
  localizedUrls?: Record<CountryAndLocale, string>
  countryPhonePrefix?: string
}

export enum TrackingActions {
  Add = 'ADD_EVENT',
  Remove = 'REMOVE_EVENT',
  Clear = 'CLEAR_EVENTS',
}

// Object structure when dispatching to GlobalTrackingObject
export interface TrackingActionObject {
  type: TrackingActions.Add | TrackingActions.Clear | TrackingActions.Remove
  payload?: {
    eventName?: TrackingEventName
    eventParameters?: TrackingEventParameters
    util?: UtilParameters
  }
}

export interface TrackingContextInterface {
  state: GlobalTrackingObject
  dispatch: Dispatch<TrackingActionObject>
}

export type AuthMethod = 'manual' | 'google' | 'linkedin' | 'azure'

export interface AnalyticsPriceData {
  default_price: number
  default_price_with_shipping: number
  default_price_with_min_consumption_margin: number
  default_price_without_consumption_margin: number
  shipping_fee: number
  bulk_discount_amount: number
  contract_discount_amount: number
  account_pricing_tier: string
  consumption_margin_amount: number
  consumption_margin_percentage: number
  consumption_discount_amount: number
  final_price: number
  final_price_with_shipping: number
  sales_unit: string
  reference_unit: string
  reference_unit_price: number
  currency: string
}

export interface AnalyticsProduct {
  item_id: string
  item_name: string
  affiliation?: string
  coupon?: string
  currency: string // Deprecated
  discount?: number
  index?: number
  item_brand: string
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
  item_list_id?: string
  item_list_name?: string
  item_variant?: string
  location_id?: string
  price: number // Deprecated - using final_price definition
  quantity?: number
  supplier?: string
  is_under_mov?: boolean
  price_data?: AnalyticsPriceData
  product_url: string
}
export interface AddToCartEventParams {
  productsData: {
    product: Product
    productVariants: Record<string, number>
    isUnderMov?: boolean
    modifiedCart?: CartSupplierProducts
  }[]
  context?: AnalyticsContext
  isUnderMOQ?: boolean
  catalogName?: string
  modifiedCart?: CartSupplierProducts
}

export interface AddToCartBuyerCatalogEventParams extends AddToCartEventParams {
  catalogName: string
  catalogId: string
  isPartial?: boolean
}

export interface SingleAddToCartBuyerCatalogEventParams extends AddToCartEventParams {
  catalogName: string
  catalogId: string
  productSku: string
}
