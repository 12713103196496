import { FC, useState } from 'react'
import { Container, Divider, Drawer } from 'rsuite'
import MenuIcon from '@rsuite/icons/Menu'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import omit from 'lodash/omit'

import categoryHeaderLinks from '../../../../utils/categoryHeaderLinks.json'
import MobileDrawerHeader from '../MobileUI/MobileDrawerHeader'
import MobileMenuItem from '../MobileUI/MobileMenuItem'
import MobileCategoryPicker from './MobileCategoryPicker'
import MobileMegaMenu from '../MegaMenu/MobileMegaMenu'
import SearchBar from '../Search/Search'
import { getCountryAndLocaleStrings } from '../../../../utils/locales'
import { CatalogType } from '../../../../utils/types/misc'
import { capitalizeFirstLetter } from '../../../../utils/util'
import { getFilterQueryIds } from '../../../Catalog/Pickers/util'
import useUrls from '../../../../services/useUrls'
import useDrawerState from '../hooks/useDrawerState'
import useCategoryDataStructure from '../../../hooks/useCategoryDataStructure'
import { useApi } from '../../../../services/useApi'
import { QueryParam, ResourceTag } from '../../../../utils/constants'
import { getProductsCount } from '../../../../lib/product'
import RenderCatalogNode from './RenderCatalogNode'
import useMegaMenuData from '../../../../services/strapi/useMegaMenuData'

import headerStyles from '../../../../styles/Header.module.less'

interface CategoryItemType {
  id: string
  attributes: {
    'catalog.code': string
    'catalog.label': string
  }
}
const MobileCategoriesMenu: FC = () => {
  const [isInSearchMode, setIsInSearchMode] = useState(false)
  const [selectedTopCategoryCode, setSelectedParentCategoryId] = useState('')

  const { t } = useTranslation('products')
  const { getResourceCount } = useApi()
  const { pushT } = useUrls()
  const { locale: countryAndLocale, query, asPath } = useRouter()
  const {
    subCategory,
    categoriesLoading,
    isSubCategoriesLoading,
  } = useCategoryDataStructure(selectedTopCategoryCode)
  const {
    isDrawerOpen: isMobileCategoriesDrawerOpen,
    toggleDrawerOpenState: toggleMobileCategoriesDrawer,
  } = useDrawerState()
  const {
    isDrawerOpen: isCategoryTreeDrawerOpen,
    toggleDrawerOpenState: toggleCategoryTreeDrawer,
  } = useDrawerState()

  const searchQuery = query[QueryParam.search]?.toString()
  const countQuery = searchQuery ? 'aggregate=index.catalog.id' : 'aggregate=index.catalog.id'
  const { data: productsAggregate } = getResourceCount(ResourceTag.product, countQuery)

  const { locale } = getCountryAndLocaleStrings(countryAndLocale)
  const columnsData = useMegaMenuData()

  const categories = categoryHeaderLinks[locale]
  const selectedSubCategory = subCategory?.[0] || {}

  const handleCatalogTabClick = (catalogType: CatalogType) => {
    const redirectUrl = `/${catalogType === 'products' ? '' : catalogType}`

    pushT(redirectUrl, undefined, { shallow: true, scroll: true })
    toggleMobileCategoriesDrawer()
  }

  const renderCatalogTab = (catalogType: CatalogType) => (
    <MobileMenuItem handleClick={() => handleCatalogTabClick(catalogType)}>
      {t(catalogType === 'products' ? 'common:Featured' : 'Suppliers')}
    </MobileMenuItem>
  )

  const getRelevantColumnsData = (categoryCode: string) => columnsData[categoryCode]

  const handleCategoryClick = (isTopLevelCategory: boolean, categoryCode: string) => {
    if (!isCategoryTreeDrawerOpen) toggleCategoryTreeDrawer()

    if (isTopLevelCategory) {
      setSelectedParentCategoryId(categoryCode)
      return
    }

    const currentQueries = omit(query, getFilterQueryIds(query), QueryParam.search)
    const urlWithCategory = `/products/category/${selectedTopCategoryCode}/${categoryCode}`

    pushT(
      {
        pathname: urlWithCategory,
        query: currentQueries,
      },
      undefined,
      { shallow: true, scroll: true },
    )
  }

  // If clicking on a sub-category, select and close all menus
  const handleSelectAndCloseMenus = (categoryCode?: string) => {
    if (categoryCode) handleCategoryClick(false, categoryCode)

    toggleCategoryTreeDrawer()
    toggleMobileCategoriesDrawer()
  }

  const handleSearchModeBackClick = () => setIsInSearchMode(false)

  const isParentCategoryActive = (categoryCode: string) => asPath.includes(categoryCode)

  const renderCatalogItem = (category: CategoryItemType) => {
    const productsCount = getProductsCount(productsAggregate, category)

    return (
      <MobileMenuItem
        key={category.id}
        handleClick={() => (productsCount ? handleCategoryClick(true, category.attributes['catalog.code']) : undefined)}
        additionalClassNames={
        isParentCategoryActive(category.attributes['catalog.code'])
          ? headerStyles['active-category-label']
          : ''
      }
      >
        <RenderCatalogNode
          productsCount={productsCount}
          catalogLabel={category.attributes['catalog.label']}
        />
      </MobileMenuItem>
    )
  }

  return (
    <>
      <MenuIcon
        className="icon-size-double"
        onClick={toggleMobileCategoriesDrawer}
      />
      <Drawer
        placement="left"
        open={isMobileCategoriesDrawerOpen}
        onClose={toggleMobileCategoriesDrawer}
        onExited={() => setIsInSearchMode(false)}
        className={headerStyles['mobile-categories-menu']}
        size="full"
      >
        <MobileDrawerHeader
          handleBackNavigation={isInSearchMode ? handleSearchModeBackClick : undefined}
        >
          {isInSearchMode ? capitalizeFirstLetter(t('url:search')) : t('Categories')}
        </MobileDrawerHeader>
        <Drawer.Body className="padding-spacer-double">
          <Container onClick={() => setIsInSearchMode(true)}>
            <SearchBar
              externalSearchCallback={toggleMobileCategoriesDrawer}
              graphicRenderCondition="focus"
              mobileIsInSearchMode={isInSearchMode}
            />
          </Container>
          {!isInSearchMode && (
            <div className={`margin-top-spacer-tripple ${headerStyles['mobile-categories-menu-items-container']}`}>
              {renderCatalogTab('products')}
              {categories.map((category) => renderCatalogItem(category))}
              {renderCatalogTab('suppliers')}
            </div>
          )}
        </Drawer.Body>
      </Drawer>
      {!categoriesLoading && !isSubCategoriesLoading && (
        <Drawer
          open={isCategoryTreeDrawerOpen}
          onClose={() => handleSelectAndCloseMenus()}
          size="full"
          placement="left"
          className={headerStyles['mobile-menu-content-styles']}
        >
          <MobileDrawerHeader handleBackNavigation={toggleCategoryTreeDrawer}>
            {selectedSubCategory['catalog.label']}
          </MobileDrawerHeader>
          <Drawer.Body className="padding-zero">
            <MobileCategoryPicker
              handleSelectAndCloseMenus={handleSelectAndCloseMenus}
              selectedSubCategory={selectedSubCategory}
            />
            <Divider />
            <MobileMegaMenu
              columnsData={getRelevantColumnsData(selectedTopCategoryCode)}
              handleMenuLinkClick={handleSelectAndCloseMenus}
            />
          </Drawer.Body>
        </Drawer>
      )}
    </>
  )
}

export default MobileCategoriesMenu
