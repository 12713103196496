import { ClipboardEvent, WheelEvent } from 'react'

import { isNumber } from './math'

const throttle = <T extends (...args: any[]) => any>(func: T, ms: number) => {
  let inThrottle = false
  return (...args: Parameters<T>): ReturnType<T> => {
    let result: any
    if (!inThrottle) {
      inThrottle = true
      result = func(...args)
      setTimeout(() => {
        inThrottle = false
      }, ms)
    }
    return result
  }
}

const debounce = <T extends (...args: any[]) => any>(func: T, ms: number) => {
  let timeout: ReturnType<typeof setTimeout>
  return (...args: Parameters<T>): ReturnType<T> => {
    let result: any
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      result = func(...args)
    }, ms)
    return result
  }
}

const preventTextOnPaste = (handleChange: Function) => (e: ClipboardEvent<HTMLInputElement>) => {
  const pastedText = e.clipboardData.getData('text/plain')

  if (!isNumber(pastedText)) {
    setTimeout(() => handleChange(0))
  }
}

const disableEditOnScroll = (
  e: WheelEvent<HTMLFormElement> | WheelEvent<HTMLInputElement>,
) => (e.target as HTMLInputElement).blur()

export {
  throttle,
  debounce,
  preventTextOnPaste,
  disableEditOnScroll,
}
