import { FC } from 'react'

export type LightningIconSize = keyof typeof sizeMap

interface LightningIconProps {
  size?: LightningIconSize
}

const sizeMap = {
  xs: { width: 8, height: 11 },
  sm: { width: 10, height: 13 },
  md: { width: 11, height: 15 },
  lg: { width: 13, height: 17 },
  xl: { width: 15, height: 20 },
}

const LightningIcon: FC<LightningIconProps> = ({ size = 'md' }) => {
  const { width, height } = sizeMap[size]

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 8.94444L6.53846 1V6.05556H10L4.46154 14V8.94444H1Z"
        stroke="#86DA07"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LightningIcon
