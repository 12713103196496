import { Locale } from '../external'
import { SHIPMENT_DAYS_DEFAULT, SHIPPING_COST_DEFAULT, SHIPPING_LIMIT_DEFAULT } from '../utils/constants'
import { OrderServiceInclude } from '../utils/types/Order'
import { DeliveryService, Product, Supplier } from '../utils/types/Product'

const getSupplierDeliveryCost = (
  supplier: Supplier | undefined,
  countryLocale: Locale | null,
): number => {
  if (countryLocale) {
    const locale = countryLocale.toUpperCase() as Uppercase<Locale>
    const cost = supplier?.['service.price']?.[locale]?.costs ?? SHIPPING_COST_DEFAULT
    return Number(cost)
  }
  return SHIPPING_COST_DEFAULT
}

export const getShippingCost = (
  service: DeliveryService | undefined,
  countryLocale: Locale | null,
) => {
  // Gracefully handling case, if service.price is not an array
  if (service?.price && !service?.price?.[0]) {
    return 0
  }
  const cost = service?.price?.find((ele) => ele['price.label'] === countryLocale?.toUpperCase())?.[
    'price.costs'
  ] ?? SHIPPING_COST_DEFAULT
  return Number(cost)
}

export const getSupplierDeliveryDetails = (
  supplier: Supplier | DeliveryService | undefined,
  countryLocale: Locale | null,
): {
  deliveryCost: number
  freeShippingLimit: number
  deliveryTime: number
  minimumOrderValue: number
  isAvailable: boolean
} => ({
  deliveryCost: (supplier && 'price' in supplier ? getShippingCost(supplier, countryLocale) : null)
    ?? (supplier && 'supplier.id' in supplier ? getSupplierDeliveryCost(supplier, countryLocale) : null)
    ?? SHIPPING_COST_DEFAULT,
  freeShippingLimit: supplier?.['service.config']?.[countryLocale?.toUpperCase() ?? 'FI']?.limit ?? SHIPPING_LIMIT_DEFAULT,
  deliveryTime: supplier?.['service.config']?.[countryLocale?.toUpperCase() ?? 'FI']?.days ?? SHIPMENT_DAYS_DEFAULT,
  minimumOrderValue: supplier?.['service.config']?.[countryLocale?.toUpperCase() ?? 'FI']?.mov ?? 0,
  isAvailable: countryLocale === null || supplier?.['service.config']?.[countryLocale?.toUpperCase()] !== undefined,
})

export const getDeliveryServices = (data: Product[] | Supplier[]) => {
  const isProduct = (p: Product[] | Supplier[]): p is Product[] => (p[0] as Product)?.['product.id'] !== undefined

  if (isProduct(data)) {
    return data
      .flatMap((product) => (
        product.supplier?.flatMap((supp) => (
          supp?.service || []
        )) || []
      ))
  }

  return data.flatMap((supp) => (
    supp?.service || []
  )) || []
}

export const getOrderServiceAttriutes = (data: OrderServiceInclude) => {
  const invoiceType = data?.attributes?.attribute
    ?.find((attr) => (
      attr['order.service.attribute.code'] === 'invoiceType'))
    ?.['order.service.attribute.value'] || ''

  const businessId = data?.attributes?.attribute
    ?.find((attr) => (
      attr['order.service.attribute.code'] === 'taxNumber')) // taxNumber key used in backend for business id
    ?.['order.service.attribute.value'] || ''

  const paymentNotes = data?.attributes?.attribute
    ?.find((attr) => (
      attr['order.service.attribute.code'] === 'paymentNotes'))
    ?.['order.service.attribute.value'] || ''

  const sendOrderConfirmation = data?.attributes?.attribute
    ?.find((attr) => (
      attr['order.service.attribute.code'] === 'sendOrderConfirmation'))
    ?.['order.service.attribute.value'] || ''

  return {
    invoiceType,
    businessId,
    paymentNotes,
    sendOrderConfirmation: sendOrderConfirmation === 'true',
  }
}
