import TagManager from 'react-gtm-module'

import { formatCategoriesForAnalytics } from '../../lib/category'
import { roundToTwoDecimals } from '../math'
import { Product } from '../types/Product'
import { emptyEcomDataLayer } from './utils'
import { CartResponse, OrderProductInclude, WithSuppliers } from '../types/Order'
import { getBrandName } from '../../lib/text'
import { AnalyticsProduct } from '../types/analytics'

interface RemoveFromCartParams {
  basketProducts?: Array<OrderProductInclude | undefined>
  cartData?: WithSuppliers<CartResponse>
  getLocalizedProductUrl: (product?: Product) => string
}

const removeFromCart = ({
  basketProducts,
  cartData,
  getLocalizedProductUrl,
}: RemoveFromCartParams) => {
  if (!cartData) {
    return
  }

  const { data } = cartData

  const items = basketProducts?.filter((prod) => prod !== undefined)
    .map((prod): AnalyticsProduct | undefined => {
      if (!prod) return undefined
      const { attributes, product } = prod
      const variantObj = attributes?.attribute?.find((attr) => attr['order.product.attribute.code'] === 'variant')
      const brand = product ? getBrandName(product) : ''
      const variant = variantObj ? variantObj['order.product.attribute.name'] : attributes['order.product.name']
      const orderedCategories = product ? formatCategoriesForAnalytics(product) : []

      const productUrl = getLocalizedProductUrl(product)

      return {
        item_id: (product && product['product.code']) || '',
        item_name: attributes['order.product.name'],
        price: roundToTwoDecimals(attributes['order.product.price']),
        currency: data?.attributes['order.currencyid'],
        item_brand: brand ?? attributes['order.product.suppliername'],
        supplier: attributes['order.product.suppliername'],
        item_variant: variant,
        quantity: attributes['order.product.quantity'],
        product_url: productUrl,
        ...orderedCategories,
      }
    })

  // Sum of all item variant prices
  const value = items?.reduce(
    (acc, item) => acc + (Number(item?.price) * Number(item?.quantity)), 0,
  ) || 0

  if (data) {
    emptyEcomDataLayer() // First empty datalayer
    TagManager.dataLayer({ dataLayer: {
      event: 'remove_from_cart',
      ecommerce: {
        affiliation: 'Droppe Marketplace',
        value,
        currency: data.attributes['order.currencyid'],
        items,
      },
    } })
  }
}

export default removeFromCart
