import TagManager from 'react-gtm-module'

import { Locale } from '../../external'
import { CountryId } from '../constants'
import { getCurrencyCode } from '../locales'

// Push event to datalayer every time language is changed
const changeLanguageEvent = (locale: Locale, countryName: CountryId, countryTaxRate?: number) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'change_language',
      active_currency: getCurrencyCode(countryName),
      language: locale.toLowerCase(),
      country: countryName.toLowerCase(),
      country_tax_rate: countryTaxRate,
    },
  })
}

export default changeLanguageEvent
