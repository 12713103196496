import { FC } from 'react'
import { TransProps } from 'next-translate'
import Trans from 'next-translate/Trans'

// Wrapper for <Trans /> component to avoid crashing when translation key is missing
const SafeTranslate: FC<TransProps> = (props) => {
  const { i18nKey } = props

  return (
    <Trans
      {...props}
      defaultTrans={i18nKey}
    />
  )
}

export default SafeTranslate
