import TagManager from 'react-gtm-module'

const viewUnsupportedBrowserModalEvent = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'view_unsupported_browser_modal',
    },
  })
}

export default viewUnsupportedBrowserModalEvent
