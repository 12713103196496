import { FC, useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Button, Stack } from 'rsuite'
import { useRouter } from 'next/router'

import { debounce } from '../utils/event-utils'
import DroppeLogo from './Icons/DroppeLogo'
import useUrls from '../services/useUrls'
import useRFQModal from './Modals/hooks/useRFQModal'
import { ScreenSize } from '../utils/constants'

import buttonStyles from '../styles/CustomButtons.module.less'
import styles from '../styles/MobileBlocker.module.less'

const IGNORE_BLOCKER_URLS_LIST = [
  '/products',
  '/suppliers',
  '/supplier',
  '/tos',
  '/product',
  '/my-products',
  '/my-account',
  '/my-orders',
  '/password',
  '/authorize',
  '/my-catalogs',
]

const MobileBlocker: FC = () => {
  const [blockMobile, setBlockMobile] = useState(false)
  const { pushT } = useUrls()

  const { t } = useTranslation('blocker')
  const { push, asPath, pathname, route } = useRouter()

  // Button click handlers
  const openRfqModal = useRFQModal({ analyticsLocation: 'mobile_blocker' })
  const openContactModal = () => push('#contact', undefined, { shallow: true })
  const redirectToHomepage = () => pushT('/')

  const isInPageIgnoreList = IGNORE_BLOCKER_URLS_LIST.some((url) => pathname.startsWith(url))
  const isMobileBlockerDisabled = (
    asPath === '/'
    || asPath.startsWith('/#') // Exclude modal hashes
    || route === '/404'
    || isInPageIgnoreList
  )

  // Set mobile blocker on pages not included in isMobilerBlockerDisabled conditions
  const handleResize = () => {
    if (!isMobileBlockerDisabled) {
      setBlockMobile(window.innerWidth <= ScreenSize.MOBILE_BLOCKER_BREAKPOINT)
    }
  }

  const debouncedHandleResize = debounce(handleResize, 100)

  useEffect(() => {
    if (!isMobileBlockerDisabled) handleResize() // Set mobile blocker on inital load
    window.addEventListener('resize', debouncedHandleResize)
    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
      setBlockMobile(false)
    }
  }, [asPath])

  return (
    blockMobile ? (
      <div className={styles['mobile-blocker']}>
        <div className={styles['max-width']}>
          <div className={styles['logo-positioning']}>
            <DroppeLogo
              alignment="left"
              size="md"
            />
          </div>
          <h1 className="h2">{t('More on desktop')}</h1>
          <p>
            {t("You can browse, search and sort Droppe's entire catalogue of high quality industrial products and top brands directly on your phone")}
          </p>
          <Stack
            direction="column"
            alignItems="flex-start"
          >
            <Stack.Item>
              <Button
                appearance="primary"
                className={`
              margin-top-spacer-tripple
              ${buttonStyles['custom-button']}
              ${buttonStyles['button-primary']}
              ${buttonStyles['button-caret']}
            `}
                onClick={() => openRfqModal()}
              >
                {t('products:Request a quote')}
              </Button>
            </Stack.Item>
            <Stack.Item>
              <Button
                appearance="primary"
                className={`
              margin-top-spacer-double
              ${buttonStyles['custom-button']}
              ${buttonStyles['button-primary']}
              ${buttonStyles['button-caret']}
            `}
                onClick={openContactModal}
              >
                {t('common:Contact us')}
              </Button>
            </Stack.Item>
            <Stack.Item>
              <Button
                appearance="ghost"
                className={`
              margin-top-spacer-double
              ${styles['browse-products-button']}
              ${buttonStyles['custom-button']}
              ${buttonStyles['button-caret']}
              ${buttonStyles['button-tertiary']}
            `}
                onClick={redirectToHomepage}
              >
                {t('common:Browse products')}
              </Button>
            </Stack.Item>
          </Stack>
        </div>
      </div>
    ) : null
  )
}

export default MobileBlocker
