const PUBLIC_API_URL = process.env.NEXT_PUBLIC_API_URL || ''

export const isStaging = PUBLIC_API_URL.includes('staging.droppe')

export const isDev = PUBLIC_API_URL.includes('local.droppe')

export const isProduction = !isStaging && !isDev

export const getEnvironmentName = () => {
  if (isProduction) return 'production'
  if (isStaging) return 'staging'
  return 'dev'
}

export const getGtmEnv = () => {
  if (isProduction) return process.env.NEXT_PUBLIC_PRODUCTION_GTM_ENV
  if (isStaging) return process.env.NEXT_PUBLIC_STAGING_GTM_ENV
  if (isDev) return process.env.NEXT_PUBLIC_DEV_GTM_ENV
  console.error('getGtmEnv', 'not matching env, is empty')
  return ''
}

export const getGtmAuth = () => {
  if (isProduction) return process.env.NEXT_PUBLIC_PRODUCTION_GTM_AUTH
  if (isStaging) return process.env.NEXT_PUBLIC_STAGING_GTM_AUTH
  if (isDev) return process.env.NEXT_PUBLIC_DEV_GTM_AUTH
  console.error('getGtmAuth', 'not matching env, is empty')
  return ''
}

export const getMainDomain = () => {
  const hostname = window.location.hostname.replace(/^www\./, '')
  if ((hostname.match(/\\./g) || []).length === 1) {
    return hostname // No subdomain present
  }
  const parts = window.location.hostname.split('.')
  parts.shift()
  const upperleveldomain = parts.join('.')
  return upperleveldomain
}
