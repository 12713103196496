import { isDefined, NonUndefined, toTypedEntries } from '../utils/types/misc'
import { ResourceName } from '../utils/types/Resource'

type FilterKeyOrOperator = '==' | '~=' | '=~' | '>' | '<' | '>=' | '<=' | '!='
type FilterCombineOperator = '||' | '&&' | '!'
type FilterOperator = FilterCombineOperator | FilterKeyOrOperator
type FilterQuery = { field: string, value: string | string[] }
type FilterOperatorQuery = {
  [k in FilterKeyOrOperator]?: FilterQuery
}
type FilterCombinedQuery = {
  [c in FilterCombineOperator]?: (FilterOperatorQuery | FilterCombinedQuery | Filter)[]
}

interface Filter {
  [field: Exclude<string, FilterCombineOperator | FilterKeyOrOperator>]: string | string[] |
  Record<string, string | string[]>
}

type FilterValue = NonNullable<
FilterOperatorQuery[FilterKeyOrOperator] |
FilterCombinedQuery[FilterCombineOperator] |
Filter[string]>

export interface ApiParams {
  id?: string
  include?: ResourceName | ResourceName[] | readonly ResourceName[]
  filter?: Filter | FilterOperatorQuery | FilterCombinedQuery
  sort?: string
  page?: {
    limit?: string
    offset?: string
  }
  other?: { [key: string]: string | boolean | null }
  search?: string | string[]
}

const isKeyOperator = (s: string): s is FilterKeyOrOperator => (
  ['==', '~=', '=~', '>', '<', '>=', '<=', '!='].includes(s)
)

const isCombineOperator = (s: string): s is FilterCombineOperator => (
  ['||', '&&', '!'].includes(s)
)

const isDoubleKeyQuery = (
  filter: Record<string, string | string[]> | FilterOperatorQuery | FilterCombinedQuery,
): filter is Record<string, string | string[]> => (
  !Object.keys(filter).some((f) => isKeyOperator(f) || isCombineOperator(f))
)

const isStringArray = (s: any): s is string[] => (
  Array.isArray(s) && Boolean(s.length) && typeof s[0] === 'string'
)

const encodeCombinators = (operator: FilterOperator | string): string => (
  /// encodeURIComponent does not change ~ and !, so need to handle them manually
  encodeURIComponent(operator).replace(
    /[!~]/g,
    (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`,
  )
)

export const createUrlParams = (params?: ApiParams): string => {
  if (!params) return ''
  return toTypedEntries(params).filter(isDefined).reduce((queryString, [param, value]) => {
    if (value) {
      switch (param) {
        case 'page': {
          const typedValue = value as NonUndefined<ApiParams['page']>
          const limit = typedValue.limit ? `&page[limit]=${typedValue.limit}` : ''
          const offset = typedValue.offset ? `&page[offset]=${typedValue.offset}` : ''
          return `${queryString}${limit}${offset}`
        }
        case 'filter': {
          const filters = Object.entries(value) as [string, FilterValue][]
          return filters.reduce<string>((constructed, [filterKey, filterValue]) => {
            const createFilterQuery = (
              key: string,
              filter: FilterValue,
              combinator: string = '',
            ): string => {
              if (typeof filter === 'string') {
                // filter is string
                return `filter[${key}]=${filter}`
              }
              if (isStringArray(filter)) {
                // filter is string[]
                return filter.map((filterArrayValue) => `filter${combinator}[${key}][]=${filterArrayValue}`).join('&')
              }
              if ('value' in filter) {
                // filter is FilterQuery
                if (Array.isArray(filter.value)) {
                  return filter.value.map((filterArrayValue) => (
                    `filter${combinator}[${encodeCombinators(key)}][${filter.field}][]=${filterArrayValue}`)).join('&')
                }
                return `filter${combinator}[${encodeCombinators(key)}][${filter.field}]=${filter.value}` // encodeURI for eg. turn == into %3D%3D
              }

              if (!Array.isArray(filter) && isDoubleKeyQuery(filter)) {
                // Filter is a double key query (filter[f_uprop][per-box]=100
                return Object.entries<string | string[]>(filter).map(([subKey, subValue]) => {
                  if (Array.isArray(subValue)) {
                    return subValue.map((filterArrayValue) => `filter[${filterKey}][${subKey}][]=${filterArrayValue}`).join('&')
                  }
                  return `filter[${filterKey}][${subKey}]=${subValue}`
                }).join('&')
              }

              // Filter is a combined query
              return filter.map((subFilter, index) => (
                Object.entries(subFilter).flatMap(([subFilterKey, subFilterValue]) => {
                  const hasSubCombineQuery = filter.flatMap(Object.keys).some(isCombineOperator)
                  // For nested combine queries,
                  // the query needs to know which group each sub query belongs to
                  const combineGroupIndex = hasSubCombineQuery ? index : ''
                  // If multiple levels of sub queries,
                  // the param needs to be appended for all combines
                  const combinatorParam = combinator
                    ? `${combinator}[${encodeCombinators(key)}][${combineGroupIndex}]`
                    : `[${encodeCombinators(key)}][${combineGroupIndex}]`

                  return createFilterQuery(subFilterKey, subFilterValue, combinatorParam)
                }).join('&')
              )).join('&')
            }
            return `${constructed}&${createFilterQuery(filterKey, filterValue)}`
          }, queryString)
        }
        case 'include': {
          const typedValue = value as NonUndefined<ApiParams['include']>
          return `${queryString}&${param}=${typeof typedValue === 'string' ? typedValue : typedValue.join(',')}`
        }
        case 'search': {
          const typedValue = value as NonUndefined<ApiParams['search']>
          return `${queryString}&${param}=${typeof typedValue === 'string' ? typedValue : typedValue.join(',')}`
        }
        case 'other': {
          // All extra parameters will simply be added as key value pairs to the query
          const otherParams = Object.entries(value)
            .map(([otherKey, otherValue]) => {
              if (otherValue) {
                return `${otherKey}=${otherValue}`
              }
              // If empty value, don't add '='
              return otherKey
            }).join('&')
          return `${queryString}&${otherParams}`
        }
        default: {
          return `${queryString}&${param}=${value}`
        }
      }
    }
    return queryString
  }, '')
}
