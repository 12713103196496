import { FC } from 'react'
import Image, { ImageProps } from 'next/legacy/image'

import { imageLoader } from '../utils/images'

import styles from '../styles/Img.module.less'

const Img: FC<ImageProps & { wWidth?: string, wMaxWidth?: string }> = (
  { wWidth = '100%', wMaxWidth = '100%', className = '', loader, ...imgProps },
) => {
  const widths: { width: string, maxWidth: string } = {
    width: wWidth,
    maxWidth: wMaxWidth,
  }

  return (
    <div
      className={`${styles['img-container']} ${className}`}
      style={widths}
    >
      <Image
        loader={loader || imageLoader}
        className={`${styles.img} img`}
        {...imgProps}
      />
    </div>
  )
}

export default Img
