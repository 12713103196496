import { BLACK_COLOR } from '../../utils/constants'

const SearchIconBorder = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="20"
      cy="20"
      r="19.5"
      fill="white"
      stroke="#E5E5EA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 18.5C24 15.4624 21.5376 13 18.5 13C15.4624 13 13 15.4624 13 18.5C13 21.5376 15.4624 24 18.5 24C21.5376 24 24 21.5376 24 18.5ZM14 18.5C14 16.0147 16.0147 14 18.5 14C20.9853 14 23 16.0147 23 18.5C23 20.9853 20.9853 23 18.5 23C16.0147 23 14 20.9853 14 18.5Z"
      fill={BLACK_COLOR}
    />
    <path
      d="M18.5 13.15C21.4547 13.15 23.85 15.5453 23.85 18.5H24.15C24.15 15.3796 21.6204 12.85 18.5 12.85V13.15ZM13.15 18.5C13.15 15.5453 15.5453 13.15 18.5 13.15V12.85C15.3796 12.85 12.85 15.3796 12.85 18.5H13.15ZM18.5 23.85C15.5453 23.85 13.15 21.4547 13.15 18.5H12.85C12.85 21.6204 15.3796 24.15 18.5 24.15V23.85ZM23.85 18.5C23.85 21.4547 21.4547 23.85 18.5 23.85V24.15C21.6204 24.15 24.15 21.6204 24.15 18.5H23.85ZM18.5 13.85C15.9319 13.85 13.85 15.9319 13.85 18.5H14.15C14.15 16.0976 16.0976 14.15 18.5 14.15V13.85ZM23.15 18.5C23.15 15.9319 21.0681 13.85 18.5 13.85V14.15C20.9024 14.15 22.85 16.0976 22.85 18.5H23.15ZM18.5 23.15C21.0681 23.15 23.15 21.0681 23.15 18.5H22.85C22.85 20.9024 20.9024 22.85 18.5 22.85V23.15ZM13.85 18.5C13.85 21.0681 15.9319 23.15 18.5 23.15V22.85C16.0976 22.85 14.15 20.9024 14.15 18.5H13.85Z"
      fill="black"
    />
    <path
      d="M21.6464 21.6464C21.82 21.4729 22.0894 21.4536 22.2843 21.5886L22.3536 21.6464L26.8536 26.1464C27.0488 26.3417 27.0488 26.6583 26.8536 26.8536C26.68 27.0271 26.4106 27.0464 26.2157 26.9114L26.1464 26.8536L21.6464 22.3536C21.4512 22.1583 21.4512 21.8417 21.6464 21.6464Z"
      fill={BLACK_COLOR}
      stroke="black"
      strokeWidth="0.3"
    />
  </svg>

)

export default SearchIconBorder
