import { RefObject, createContext, useContext, useEffect, useState } from 'react'

import { getRefDimension } from '../../../../utils/util'
import useIsScrollingUp from '../../../hooks/useIsScrollingUp'

export const HeaderContext = createContext<RefObject<HTMLElement> | null>(null)

// Hide header while scrolling down and condition param is true
// Returns boolean value based on if header is hidden or not
const useHideHeaderOnScrollCondition = (
  condition: boolean,
  customHeaderHeight?: number,
): boolean => {
  const isScrollingUp = useIsScrollingUp()
  const headerRef = useContext(HeaderContext)

  const headerHeight = customHeaderHeight || getRefDimension('height', headerRef)

  // Hide header if under product navbar and scrolling down
  const [isHeaderHidden, setIsHeaderHidden] = useState<boolean>(false)

  useEffect(() => {
    if (!isScrollingUp && condition && headerRef?.current) {
      // Hide header
      headerRef.current.style.transform = `translate3d(0px, -${headerHeight}px, 0px)`
      setIsHeaderHidden(true)
    } else if (headerRef?.current) {
      // Show header
      headerRef.current.style.transform = ''
      setIsHeaderHidden(false)
    }

    return () => {
      if (headerRef?.current) headerRef.current.style.transform = ''
      setIsHeaderHidden(false)
    }
  }, [isScrollingUp, customHeaderHeight, condition])

  return isHeaderHidden
}

export default useHideHeaderOnScrollCondition
