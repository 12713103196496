import useTranslation from 'next-translate/useTranslation'
import router, { useRouter } from 'next/router'

import urls from '../lib/urls'

type Push = typeof router.push
type Replace = typeof router.replace
type Url = Parameters<Push>[0]

const useUrls = () => {
  const { t } = useTranslation('url')
  const { push, replace } = useRouter()
  const urlT = (path: Url) => urls(path, t)
  const pushT: Push = (url, as, options) => (
    push(urlT(url), as, options)
  )
  const replaceT: Replace = (url, as, options) => (
    replace(urlT(url), as, options)
  )

  return {
    urlT,
    pushT,
    replaceT,
  }
}

export default useUrls
