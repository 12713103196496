import { FC, memo, useRef } from 'react'
import { Divider } from 'rsuite'

import ProductInfo from './CartPopupProductInfo'
import CartPopupWrapper from './CartPopupWrapper'
import useHasScrolledToBottom from '../../../services/useHasScrolledToBottom'
import { selectCartIncluded } from '../../Cart/selectors'
import { CartResponse, OrderProductInclude } from '../../../utils/types/Order'

import styles from '../../../styles/CartPopup.module.less'

interface HeaderCartPopupProps {
  products: OrderProductInclude[]
  totalProductsInCart: number
  cart: CartResponse | null
}

const HeaderCartPopup: FC<HeaderCartPopupProps> = ({ totalProductsInCart, cart, products }) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const { handleScroll, hasScrolledToBottom } = useHasScrolledToBottom(scrollRef)

  if (!totalProductsInCart) return null

  const cartIncluded = cart ? selectCartIncluded(cart) : []

  return (
    <CartPopupWrapper isForHover>
      <div
        data-testid="cart-popup"
        className={styles['product-list-outer-container']}
      >
        <div
          onScroll={handleScroll}
          className={styles['product-list-container']}
          ref={scrollRef}
        >
          {products?.map((cartProduct) => (
            <ProductInfo
              cartProduct={cartProduct}
              included={cartIncluded}
              key={cartProduct.id}
            />
          ))}
        </div>
        {!hasScrolledToBottom && <div className={styles['list-gradient']} />}
      </div>
      {!hasScrolledToBottom && <Divider className={styles['cart-popup-divider']} />}
    </CartPopupWrapper>
  )
}

export default memo(
  HeaderCartPopup,
  (prevProps, nextProps) => prevProps.totalProductsInCart === nextProps.totalProductsInCart,
)
