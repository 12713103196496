import TagManager from 'react-gtm-module'

interface FormValues {
  deliveryAddress: string
  postalCode: string
  country: string
  addressDetails: string /** @deprecated data field, only show for old orders */
  deliveryNotes: string
  city: string
}

export interface UpdateDeliveryDetailsParams {
  prevValues?: FormValues
  newValues: FormValues
}

const updateDeliveryDetails = ({ prevValues, newValues }: UpdateDeliveryDetailsParams) => {
  const prevValuesFormatted = {
    delivery_address: prevValues?.deliveryAddress || '',
    postal_code: prevValues?.postalCode || '',
    country: prevValues?.country || '',
    address_details:
      prevValues?.addressDetails || '', /** @deprecated data field, only show for old orders */
    optional_delivery_notes: prevValues?.deliveryNotes || '',
    city: prevValues?.city || '',
  }

  const newValuesFormatted = {
    delivery_address: newValues.deliveryAddress,
    postal_code: newValues.postalCode,
    country: newValues.country,
    address_details:
      newValues.addressDetails, /** @deprecated data field, only show for old orders */
    optional_delivery_notes: newValues.deliveryNotes,
    city: newValues.city,
  }

  TagManager.dataLayer({ dataLayer: {
    event: 'update_delivery_details',
    previous_values: prevValuesFormatted,
    new_values: newValuesFormatted,
  } })
}

export default updateDeliveryDetails
