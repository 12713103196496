import React, { Dispatch, FC, SetStateAction, useRef, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import {
  toaster,
  Message,
  FlexboxGrid,
  Form,
  InputGroup,
  InputNumber,
  InputPicker,
  Tooltip,
  Whisper,
} from 'rsuite'
import LegacyCheckCircleIcon from '@rsuite/icons/legacy/CheckCircle'
import LegacyTrashIcon from '@rsuite/icons/legacy/Trash'
import { captureException } from '@sentry/nextjs'

import { useAdminApi } from '../../../services/useApi'
import { DeliveryService, ProductPrice, Rule, Service, ServiceList } from '../../../utils/types/Product'
import { ALERT_DURATION, DEFAULT_CURRENCY, Currency, ActiveCountryId } from '../../../utils/constants'
import { ShippingCountries, SupplierShippingData } from '../../../utils/types/Profile'
import { capitalizeFirstLetter } from '../../../utils/util'
import { findCountry } from '../../../utils/countries'
import { disableEditOnScroll } from '../../../utils/event-utils'

import styles from '../../../styles/Profile.module.less'

interface ShippingDetailsRowProps {
  rowData: SupplierShippingData
  servicePriceRelation: ServiceList[]
  setCurrentShippingData: Dispatch<SetStateAction<SupplierShippingData[]>>
  supplierDeliveryData: DeliveryService | undefined
  countriesList: { value: ShippingCountries, label: string }[]
  countriesToDisable: ShippingCountries[]
  shouldDisplayTrashIcon: boolean
  refresh: () => void
  handleSetDirty: () => void
  countryDiscount: Rule[]
}

enum InputFieldKeys {
  Country = 'country',
  Limit = 'limit',
  Days = 'days',
  Fee = 'fee',
  Mov = 'mov',
}

const ICON_DURATION = 4000

const ShippingDetailsRow: FC<ShippingDetailsRowProps> = (props: ShippingDetailsRowProps) => {
  const {
    rowData,
    servicePriceRelation,
    setCurrentShippingData,
    supplierDeliveryData,
    refresh,
    countriesList,
    countriesToDisable,
    shouldDisplayTrashIcon,
    handleSetDirty,
  } = props

  const { t } = useTranslation('profile')
  const { updateResource, deleteResource } = useAdminApi()
  const [updated, setUpdated] = useState<string[]>([])
  const updatedRef = useRef(updated) // Needed for access in setTimeout
  updatedRef.current = updated

  const rowDataCurrency = findCountry(rowData.country as ActiveCountryId)?.currency

  const getCurrency = () => rowDataCurrency || DEFAULT_CURRENCY

  const handleFieldChange = (key: InputFieldKeys, value: string) => {
    if (value === undefined || value === null) return
    handleSetDirty()
    setCurrentShippingData((prevShippingData) => prevShippingData.map(
      (data) => {
        if (data.country === rowData.country) {
          return { ...data, [key]: value }
        }
        return data
      },
    ))
  }

  const showIcon = (icon: string, ms: number = ICON_DURATION) => {
    setUpdated(updated.concat([icon]))
    setTimeout(() => {
      setUpdated(updatedRef.current.filter((shown: string) => shown !== icon))
    }, ms)
  }

  const successTooltip = (attr: string) => (
    <Tooltip>
      {`${t(attr)} ${t('was successfully updated')}`}
    </Tooltip>
  )

  // check that the value passed by the user is valid (not negative)
  // otherwise set a default 0 price
  const validateAndFormatPrice = (value: string | number) => (Number(value) >= 0 && Number(value).toFixed(2)) || '0.00'

  // a local util to handle any errors after update and the icon functionality
  const handleAfterUpdateResource = (
    errors: any,
    resourceKey: 'service' | 'price',
    fieldKey: InputFieldKeys,
  ) => {
    if (errors[resourceKey]) {
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={ALERT_DURATION}
        >
          {`${t(`There was a problem saving the shipping ${fieldKey === InputFieldKeys.Fee ? 'details' : fieldKey}`)} SERV_ERR`}
        </Message>,
      )
    } else {
      // skip the showIcon function for country field changes to avoid memory leaks due to changes
      // in the key field (which is the country)
      if (fieldKey !== InputFieldKeys.Country) {
        showIcon(fieldKey)
      }
      refresh()
    }
  }

  const updatePriceResource = async (
    data: Partial<ProductPrice>,
    id: string,
  ) => {
    const [, , errors] = await updateResource<ProductPrice, Partial<ProductPrice>>('price', data, `&id=${id}`)
    handleAfterUpdateResource(errors, 'price', InputFieldKeys.Fee)
  }

  const updateServiceConfigResource = async (
    data: Partial<Service>,
    fieldKey: InputFieldKeys,
  ) => {
    const [, , errors] = await updateResource<Service, Partial<Service>>('service', data, `&id=${supplierDeliveryData?.id}`)
    handleAfterUpdateResource(errors, 'service', fieldKey)
  }

  // a small local util to handle common updating logic for the 'limit' and 'days' fields
  const updateServiceConfigFields = async (
    key: InputFieldKeys.Limit | InputFieldKeys.Days | InputFieldKeys.Mov,
  ) => {
    if (!supplierDeliveryData?.['service.config'] || !rowData[key]) return
    const numericalValue = Number(rowData[key])

    const updatedData = {
      ...supplierDeliveryData?.['service.config'],
      [rowData.country]: {
        ...supplierDeliveryData['service.config'][rowData.country],
        [key]: numericalValue >= 0 ? numericalValue : 0,
      },
    }

    updateServiceConfigResource({ 'service.config': updatedData }, key)
  }

  const handleUpdateCountrySelect = async (newCountryVal: string) => {
    const prevCountryVal = rowData.country
    handleFieldChange(InputFieldKeys.Country, newCountryVal)

    if (!supplierDeliveryData?.['service.config'] || !supplierDeliveryData.price) return

    // for the service.config: pass the prevCountry data to the newCountry and remove the old data
    const { [prevCountryVal]: changedCountryData, ...restConfigData } = supplierDeliveryData['service.config']
    const configUpdatedData = {
      ...restConfigData,
      [newCountryVal]: changedCountryData,
    }

    // for the service.price, go over the array, find the previous country and apply the new
    // country label over it's data
    const priceUpdatedData = supplierDeliveryData.price.map((priceObj) => {
      if (priceObj['price.label'] === prevCountryVal) {
        return {
          ...priceObj,
          'price.label': newCountryVal,
        }
      }
      return priceObj
    })

    updateServiceConfigResource({ 'service.config': configUpdatedData, price: priceUpdatedData }, InputFieldKeys.Country)
  }

  const handleUpdateLimitField = async () => {
    handleFieldChange(InputFieldKeys.Limit, validateAndFormatPrice(rowData.limit))
    updateServiceConfigFields(InputFieldKeys.Limit)
  }

  const handleUpdateFeeField = async () => {
    handleFieldChange(InputFieldKeys.Fee, validateAndFormatPrice(rowData.fee))

    // id based on the country
    const id = supplierDeliveryData?.price?.find((priceObj) => priceObj['price.label'] === rowData.country)?.['price.id']
    const numericalCostsValue = Number(rowData.fee)
    try {
      const costsValue = numericalCostsValue >= 0 ? numericalCostsValue : 0
      const currencyId = rowDataCurrency || DEFAULT_CURRENCY

      // if id was found, an object already exists, so we can simply update the costs value
      if (id) {
        const updatedData = {
          id,
          'price.costs': costsValue,
          'price.currencyid': currencyId,
        }
        updatePriceResource(updatedData, id)
        return
      }

      // otherwise, we need to create a new object for a new country, we pass the old price array
      // and push a new object (without id) with the relevant costs data and label
      if (!supplierDeliveryData?.price) return

      const updatedData = {
        ...supplierDeliveryData,
        id: supplierDeliveryData?.id,
        price: [...supplierDeliveryData.price, {
          ...supplierDeliveryData?.price[0],
          id: '',
          'price.id': '',
          'price.costs': costsValue,
          'price.label': rowData.country,
          'price.currencyid': currencyId,
        }],
      }

      updateServiceConfigResource(updatedData, InputFieldKeys.Fee)
    } catch (error) {
      captureException(error)
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={ALERT_DURATION}
        >
          {`${t('There was a problem saving the shipping details')} NO_ID`}
        </Message>,
      )
    }
  }

  const handleUpdateMovField = async () => {
    if (rowData?.mov) {
      handleFieldChange(InputFieldKeys.Mov, validateAndFormatPrice(rowData.mov))
      updateServiceConfigFields(InputFieldKeys.Mov)
    }
  }

  const handleUpdateDeliveryDaysField = async () => {
    const validDaysValue = String((Number(rowData.days) > 0 && rowData.days) || 1)
    handleFieldChange(InputFieldKeys.Days, validDaysValue)
    updateServiceConfigFields(InputFieldKeys.Days)
  }

  const removeRow = () => {
    if (!supplierDeliveryData?.['service.config'] || !supplierDeliveryData.price) return

    handleSetDirty()

    // for the service.config: delete the whole property and data for the current country
    const serviceConfigClone = { ...supplierDeliveryData['service.config'] }
    delete serviceConfigClone[rowData.country]

    // for the service.price, find the relevant price object ID, and the price-service relation ID
    // and delete them both to remove all the relevant price data for that country
    const priceId = supplierDeliveryData?.price?.find((priceObj) => priceObj['price.label'] === rowData.country)?.id
    const priceRelationId = servicePriceRelation.find((relation) => relation['service.lists.refid'] === priceId)?.id

    deleteResource('price', `&id=${priceId}`)
    deleteResource('service/lists', `&id=${priceRelationId}`)
    updateServiceConfigResource({ 'service.config': serviceConfigClone }, InputFieldKeys.Country)

    setCurrentShippingData((prevShippingData) => prevShippingData.filter(
      (data) => data.country !== rowData.country,
    ))
  }

  const setInputAddon = (key: InputFieldKeys) => {
    if (!updated.includes(key)) {
      return key === InputFieldKeys.Days ? t('Days') : Currency[getCurrency()]
    }

    const correctTooltipTransKey = key === InputFieldKeys.Fee || key === InputFieldKeys.Limit
      ? `Shipping ${key}` : capitalizeFirstLetter(key)

    return (
      <Whisper
        placement="top"
        trigger="hover"
        speaker={successTooltip(correctTooltipTransKey)}
      >
        <LegacyCheckCircleIcon data-testid={`${key}-icon`} />
      </Whisper>
    )
  }

  return (
    <FlexboxGrid
      data-testid="shipping-details-row"
      align="middle"
      className={`${styles['inputs-flex-row']} margin-bottom-spacer-double`}
    >
      <FlexboxGrid.Item colspan={3}>
        <InputGroup inside>
          <Form.Control
            name="delivery-country"
            aria-labelledby="delivery-country"
            data-testid="delivery-country"
            placeholder={t('Choose delivery country')}
            accepter={InputPicker}
            data={countriesList}
            disabledItemValues={countriesToDisable}
            value={rowData.country}
            onChange={handleUpdateCountrySelect}
            cleanable={false}
            block
          />
        </InputGroup>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={4}>
        <InputGroup>
          <InputNumber
            data-testid="supplier-mov"
            value={rowData?.mov || 0}
            min={0}
            step={0.01}
            onChange={(value) => handleFieldChange(InputFieldKeys.Mov, String(value))}
            onBlur={handleUpdateMovField}
            disabled={!rowData.country}
            onWheelCapture={disableEditOnScroll}
          />
          <InputGroup.Addon>
            {setInputAddon(InputFieldKeys.Mov)}
          </InputGroup.Addon>
        </InputGroup>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={4}>
        <InputGroup>
          <InputNumber
            data-testid="shipping-limit"
            value={rowData.limit}
            min={0}
            step={0.01}
            onChange={(value) => handleFieldChange(InputFieldKeys.Limit, String(value))}
            onBlur={handleUpdateLimitField}
            disabled={!rowData.country}
            onWheelCapture={disableEditOnScroll}
          />
          <InputGroup.Addon>
            {setInputAddon(InputFieldKeys.Limit)}
          </InputGroup.Addon>
        </InputGroup>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={5}>
        <InputGroup>
          <InputNumber
            data-testid="shipping-fee"
            value={rowData.fee}
            min={0}
            step={0.01}
            onChange={(value) => handleFieldChange(InputFieldKeys.Fee, String(value))}
            onBlur={handleUpdateFeeField}
            disabled={!rowData.country}
            onWheelCapture={disableEditOnScroll}
          />
          <InputGroup.Addon>
            {setInputAddon(InputFieldKeys.Fee)}
          </InputGroup.Addon>
        </InputGroup>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={4}>
        <InputGroup>
          <InputNumber
            data-testid="delivery-days"
            value={rowData.days}
            min={1}
            step={1}
            onChange={(value) => handleFieldChange(InputFieldKeys.Days, String(value))}
            onBlur={handleUpdateDeliveryDaysField}
            disabled={!rowData.country}
            onWheelCapture={disableEditOnScroll}
          />
          <InputGroup.Addon>
            {setInputAddon(InputFieldKeys.Days)}
          </InputGroup.Addon>
        </InputGroup>
      </FlexboxGrid.Item>
      {shouldDisplayTrashIcon
      && (
      <FlexboxGrid.Item
        colspan={2}
        className={`${styles['trash-icon-container']} margin-left-spacer-half`}
      >
        <LegacyTrashIcon
          data-testid="trash-icon"
          onClick={removeRow}
        />
      </FlexboxGrid.Item>
      )}
    </FlexboxGrid>
  )
}

export default ShippingDetailsRow
