import { Dispatch, SetStateAction, createContext, useContext, useEffect, useMemo, useState, useRef } from 'react'
import { useRouter } from 'next/router'
import { captureException } from '@sentry/nextjs'
import { Subject } from 'rxjs'

import { RegisterData, UserI } from '../utils/types/auth'
import { IAimeosResponse, isValidationError, ValidationError } from '../utils/types/aimeosApi'
import fetcher from '../utils/fetcher'
import { getSupplierCode } from '../lib/supplier'
import useUserAnalyticsEvents from './analytics/useUserAnalyticsEvents'
import exception from '../utils/analytics/exception'
import {
  LOCAL_STORAGE_SUPER_USER_KEY,
  SELECTED_CUSTOMER_ID_STORAGE_KEY,
  AuthEventNames,
  LOCAL_STORAGE_USER_KEY,
  USER_TIER_DATA_LOCAL_STORAGE_KEY,
  CONSUMPTION_TAKE_RATE_TIER_SEGMENTS,
  USER_TIER_DATA,
} from '../utils/constants'
import { getLocalStorageUserData, setLocalStorageUserData } from '../utils/localStorage'
import { getTakeRatePercentage } from '../lib/resources/price'

interface ProvideAuthI {
  user: UserI | null
  isImpersonated: boolean
  skuPrefix: string | null
  consumptionTier: number | null
  isLoadingUser: boolean
  isLoadingResources: boolean
  checkUser: (email: string) => Promise<boolean>
  setLoadingResources: Dispatch<SetStateAction<boolean>>
  clearUserData: (key?: string) => void
  login: (email: string, password: string) => Promise<IAimeosResponse<AuthRespData>>
  impersonate: (userId: string) => Promise<IAimeosResponse<AuthRespData>>
  logout: () => {}
  impersonateLeave: () => Promise<IAimeosResponse<AuthRespData>>
  refresh: () => Promise<UserI | null>
  registerCall: (regData: RegisterData) => Promise<IAimeosResponse<AuthRespData>>
  sendPasswordResetEmail: (
    email: string,
    firstTime?: boolean,
  ) => Promise<IAimeosResponse>
  confirmPasswordReset: (
    email: string,
    token: string,
    password: string,
  ) => Promise<IAimeosResponse<AuthRespData>>
  isInvalidSessionModalOpen: boolean
  openInvalidSessionModal: () => void
  closeInvalidSessionModal: () => void
  useRefreshCallback: (name: string, f: (() => void) | null) => void
}

interface AuthRespData {
  token: string
}

interface ImpersonateRespData extends AuthRespData {
  user: UserI
  prefix: string
  isImpersonating: boolean
}

interface FetchUserRespData extends AuthRespData {
  user: UserI
  prefix: string
  tier: number
  isImpersonating: boolean
}
interface AuthProps {
  children: React.ReactNode
}

const defaultProvideAuth = (): ProvideAuthI => {
  const user = null
  const skuPrefix = null
  const consumptionTier = null
  const isLoadingResources = false
  const isImpersonated = false
  const isLoadingUser = false
  const clearUserData = () => {}
  const login = async () => (
    { status: 'Error' as const, message: 'Mock provider', data: undefined }
  )
  const impersonate = async () => (
    { status: 'Error' as const, message: 'Mock provider', data: undefined }
  )
  const checkUser = async () => false
  const logout = async () => {}
  const impersonateLeave = async () => (
    { status: 'Error' as const, message: 'Mock provider', data: undefined }
  )
  const registerCall = async () => (
    { status: 'Error' as const, message: 'Mock provider', data: undefined }
  )
  const refresh = async () => null
  const sendPasswordResetEmail = async () => (
    { status: 'Error' as const, message: 'Mock provider', data: undefined }
  )

  const confirmPasswordReset = async () => (
    { status: 'Error' as const, message: 'Mock provider', data: undefined }
  )

  const isInvalidSessionModalOpen = false
  const openInvalidSessionModal = () => {}
  const closeInvalidSessionModal = () => {}
  const useRefreshCallback = () => {}
  const setLoadingResources: Dispatch<SetStateAction<boolean>> = () => {}

  return {
    user,
    isImpersonated,
    skuPrefix,
    consumptionTier,
    isLoadingUser,
    isLoadingResources,
    checkUser,
    setLoadingResources,
    clearUserData,
    login,
    impersonate,
    registerCall,
    logout,
    impersonateLeave,
    refresh,
    sendPasswordResetEmail,
    confirmPasswordReset,
    isInvalidSessionModalOpen,
    openInvalidSessionModal,
    closeInvalidSessionModal,
    useRefreshCallback,
  }
}

const authContext = createContext<ProvideAuthI>(defaultProvideAuth())
const { Provider } = authContext

export const authSubject = new Subject<AuthEventNames | undefined>()

export const useAuth = () => useContext(authContext)

const apiUrl = process.env.NEXT_PUBLIC_API_URL

export const useProvideAuth = (): ProvideAuthI => {
  const router = useRouter()

  const [user, setUser] = useState<UserI | null>(null)
  const [isImpersonated, setIsImpersonated] = useState(false)
  const [skuPrefix, setSkuPrefix] = useState<string | null>(null)
  const [consumptionTier, setConsumptionTier] = useState<number | null>(null)
  const [refreshed, setRefreshed] = useState<number | null>(null)
  const [isLoadingUser, setIsLoadingUser] = useState(false)
  const [isLoadingResources, setLoadingResources] = useState(false)
  const [isInvalidSessionModalOpen, setIsInvalidSessionModalOpen] = useState(false)
  const { current: userRefreshCallbacks } = useRef(new Map())

  const { setDatalayerUserId } = useUserAnalyticsEvents()

  const openInvalidSessionModal = () => setIsInvalidSessionModalOpen(true)
  const closeInvalidSessionModal = () => setIsInvalidSessionModalOpen(false)

  const clearPersistedData = (...keys: string[]) => {
    keys.forEach((key) => localStorage.removeItem(key))
  }

  const clearUserData = () => {
    sessionStorage.removeItem(SELECTED_CUSTOMER_ID_STORAGE_KEY)
    setDatalayerUserId(null)
    setUser(null)
    setIsImpersonated(false)
    setSkuPrefix(null)
    clearPersistedData(LOCAL_STORAGE_USER_KEY, LOCAL_STORAGE_SUPER_USER_KEY, 'isImpersonated')
    localStorage.removeItem(USER_TIER_DATA_LOCAL_STORAGE_KEY)
  }

  const isUnder30MinAgo = (d: number) => {
    const halfHour = 60 * 60 * 1000
    return (Date.now() - d) < halfHour
  }

  const reportException = (error: Error) => {
    captureException(error)
    exception(`${error.name}: ${error.message}`)
  }

  const fetchUser = async (force = false): Promise<UserI | null> => {
    if (!force && refreshed && isUnder30MinAgo(refreshed)
      && getLocalStorageUserData(LOCAL_STORAGE_USER_KEY)) {
      // No need to fetch user if login was under 30 minutes ago
      return user
    }

    try {
      setIsLoadingUser(true)
      const userResp = await fetcher(`${apiUrl}/api/v1/user`)
      const data: IAimeosResponse<FetchUserRespData> = await userResp.json()

      if (data.status !== 'Success' || data.message === 'Unauthenticated') {
        clearUserData()
        return null
      }

      if (!data.data) {
        return null
      }

      const { user: userData, prefix, tier, isImpersonating } = data.data

      setDatalayerUserId(userData)
      setUser(userData)
      setIsImpersonated(Boolean(isImpersonating))
      setSkuPrefix(prefix)
      setConsumptionTier(tier)
      setRefreshed(Date.now())
      setLocalStorageUserData(LOCAL_STORAGE_USER_KEY, userData)
      localStorage.setItem('isImpersonated', JSON.stringify(isImpersonating))

      // Save user consumption tier data to local storage
      const tierData = {
        [USER_TIER_DATA.ORG_TOTAL_SPEND]: tier,
        [USER_TIER_DATA.CONSUMPTION_TIER_SEGMENT]: CONSUMPTION_TAKE_RATE_TIER_SEGMENTS
          .find((tierSegment) => tier >= tierSegment) || 0,
        [USER_TIER_DATA.CONSUMPTION_MARGIN_PERCENTAGE]: getTakeRatePercentage(tier),
      }
      localStorage.setItem(USER_TIER_DATA_LOCAL_STORAGE_KEY, JSON.stringify(tierData))

      return userData
    } catch (error) {
      reportException(error as Error)
      console.error(error)
      clearUserData()
      return null
    } finally {
      setIsLoadingUser(false)
    }
  }

  const checkUser = async (email: string): Promise<boolean> => {
    if (!email) {
      return false
    }

    const userResp = await fetcher(`${apiUrl}/api/v1/check/user?email=${email}`, 'GET')
    const data: IAimeosResponse<FetchUserRespData> = await userResp.json()

    return !!data.data
  }

  const refresh = () => fetchUser(true)

  const login = async (
    email: string,
    password:
    string,
  ): Promise<IAimeosResponse<AuthRespData>> => {
    try {
      const resp = await fetcher(`${apiUrl}/api/v1/login`, 'POST', JSON.stringify({ email: email.toLowerCase(), password }))
      const respData: IAimeosResponse<AuthRespData> = await resp.json()
      if (respData.status !== 'Success') {
        return respData
      }
      const { data } = respData
      if (!data) {
        return respData
      }
      await fetchUser(true)
      return respData
    } catch (error: any) {
      reportException(error as Error)
      return {
        status: 'Error',
        message: error[0],
        data: undefined,
      }
    }
  }

  const startSession = (data: ImpersonateRespData) => {
    const { user: userData, prefix, isImpersonating } = data

    setDatalayerUserId(userData)
    setUser(userData)
    setIsImpersonated(Boolean(isImpersonating))
    setSkuPrefix(prefix)
    setRefreshed(Date.now())
    setLocalStorageUserData(LOCAL_STORAGE_USER_KEY, userData)

    if (!isImpersonating && getLocalStorageUserData(LOCAL_STORAGE_SUPER_USER_KEY)?.email) {
      localStorage.removeItem(LOCAL_STORAGE_SUPER_USER_KEY)
    }

    localStorage.setItem('isImpersonated', JSON.stringify(isImpersonating))
  }

  /**
   * Impersonate a user by ID. This will log out the current superuser and
   * log in as the impersonated user to perform actions on their behalf.
   * @param userId User ID of user to impersonate
   * @returns IAimeosResponse<AuthRespData>
   */
  const impersonate = async (userId: string): Promise<IAimeosResponse<AuthRespData>> => {
    try {
      setIsLoadingUser(true)
      const resp = await fetcher(`${apiUrl}/api/v1/impersonate`, 'POST', JSON.stringify({ id: userId }))
      const data: IAimeosResponse<ImpersonateRespData> = await resp.json()

      if (data.status !== 'Success') {
        return data
      }

      const { data: userData } = data

      if (!userData) {
        return data
      }

      setLocalStorageUserData(LOCAL_STORAGE_SUPER_USER_KEY, user)

      startSession(userData)
      return data
    } catch (error: any) {
      reportException(error as Error)
      return {
        status: 'Error',
        message: error[0],
        data: undefined,
      }
    } finally {
      setIsLoadingUser(false)
    }
  }

  /**
   * Leave impersonation mode and return to the superuser account.
   * @returns IAimeosResponse<AuthRespData>
   */
  const impersonateLeave = async (): Promise<IAimeosResponse<AuthRespData>> => {
    try {
      setIsLoadingUser(true)
      const resp = await fetcher(`${apiUrl}/api/v1/impersonate/leave`, 'POST')
      const data: IAimeosResponse<ImpersonateRespData> = await resp.json()
      if (data.status !== 'Success') {
        return data
      }

      const { data: userData } = data
      if (!userData) {
        return data
      }

      startSession(userData)
      return data
    } catch (error: any) {
      reportException(error as Error)
      return {
        status: 'Error',
        message: error[0],
        data: undefined,
      }
    } finally {
      setIsLoadingUser(false)
    }
  }

  const registerCall = async (
    regData: RegisterData,
  ): Promise<IAimeosResponse<AuthRespData>> => {
    try {
      const newRegData = { ...regData, email: regData.email.toLowerCase() }

      const resp = await fetcher(`${apiUrl}/api/v1/register`, 'POST', JSON.stringify(newRegData))
      const data: IAimeosResponse<AuthRespData> | ValidationError = await resp.json()
      if (isValidationError(data)) {
        const errorKeys = Object.keys(data.errors)
        return {
          status: 'Error',
          message: data.errors[errorKeys[0]],
        }
      }

      if (data.data) {
        // Success
        await fetchUser(true)
      }
      return {
        status: 'Success',
        message: 'Successfully registered',
        data: data.data,
      }
    } catch (error) {
      reportException(error as Error)
      console.error(`Registration failed: ${error}`)
      return {
        status: 'Error',
        message: 'Something went wrong',
      }
    }
  }

  const logout = async () => {
    try {
      await fetcher(`${apiUrl}/api/v1/logout`, 'POST')
      clearUserData()
    } catch (error) {
      reportException(error as Error)
      console.error(`Error logging out: ${error}, clearing user token.`)
      clearUserData()
    } finally {
      authSubject.next('LOGGED_OUT')
    }
  }

  const sendPasswordResetEmail = async (
    email: string,
    firstTime = false,
  ): Promise<IAimeosResponse> => {
    try {
      const resp = await fetcher(
        `${apiUrl}/api/v1/password/email`,
        'POST',
        JSON.stringify({
          email: email.toLowerCase(),
          firstTime,
        }),
      )
      const data: IAimeosResponse = await resp.json()
      return data
    } catch (error) {
      reportException(error as Error)
      return {
        status: 'Error',
        message: 'Could not send password reset',
      }
    }
  }

  const confirmPasswordReset = async (
    email: string,
    token: string,
    password: string,
  ): Promise<IAimeosResponse<AuthRespData>> => {
    try {
      const resp = await fetcher(`${apiUrl}/api/v1/password/reset`, 'POST', JSON.stringify({
        email,
        password,
        password_confirmation: password,
        token,
      }))
      const data: IAimeosResponse<AuthRespData> | ValidationError = await resp.json()
      if (isValidationError(data)) {
        const errorKeys = Object.keys(data.errors)
        return {
          status: 'Error',
          message: data.errors[errorKeys[0]],
        }
      }

      if (data.status === 'Error') {
        return {
          status: 'Error',
          message: data.message,
          data: data?.data,
        }
      }

      return {
        status: 'Success',
        message: 'Successfully registered',
        data: data.data,
      }
    } catch (error) {
      reportException(error as Error)
      console.error(`Registration failed: ${error}`)
      return {
        status: 'Error',
        message: 'Something went wrong',
      }
    }
  }

  // Set active user from local storage when impersonating
  useEffect(() => {
    const persistedUser = getLocalStorageUserData(LOCAL_STORAGE_USER_KEY)

    // Check that persisted user contains data
    if (!user && persistedUser && persistedUser.id && persistedUser.email) {
      setUser(persistedUser)
      setIsImpersonated(localStorage.getItem('isImpersonated') === 'true')
    }
  }, [JSON.stringify(user)])

  useEffect(() => {
    const callback = async () => {
      if (['/login', '/logout', '/oauth/[action]/[provider]'].includes(router.pathname)) {
        return () => {}
      }

      await fetchUser()

      return () => fetchUser()
    }
    callback()
  }, [])

  const useRefreshCallback = (name: string, f: (() => void) | null) => {
    useEffect(() => {
      if (f && !userRefreshCallbacks.has(name)) {
        userRefreshCallbacks.set(name, f)
      }
      return () => {
        userRefreshCallbacks.delete(name)
      }
    }, [f])
  }

  useEffect(() => {
    // Trigger all refresh callbacks when the user changes
    [...userRefreshCallbacks.entries()].forEach(([, f]) => {
      f()
    })
  }, [user?.id])

  // for value memoization to stop unnecessary rerenders
  const value = useMemo(
    () => (
      {
        user,
        isImpersonated,
        skuPrefix,
        consumptionTier,
        isInvalidSessionModalOpen,
        isLoadingUser,
        isLoadingResources,
        checkUser,
        clearUserData,
        refresh,
        login,
        impersonate,
        registerCall,
        logout,
        impersonateLeave,
        sendPasswordResetEmail,
        confirmPasswordReset,
        setLoadingResources,
        openInvalidSessionModal,
        closeInvalidSessionModal,
        useRefreshCallback,
      }
    ),
    [
      user,
      isImpersonated,
      skuPrefix,
      consumptionTier,
      isInvalidSessionModalOpen,
      isLoadingResources,
      isLoadingUser,
    ],
  )

  return value
}

export const getSiteCode = (user: UserI | null) => {
  const name = user?.name || 'default'
  // Code derived from name the same way as during registration when code is created
  const code = user?.superuser ? 'default' : getSupplierCode(name)
  return code
}

export const ProvideAuth = ({ children }: AuthProps) => {
  const auth = useProvideAuth()
  return <Provider value={auth}>{children}</Provider>
}
