import TagManager from 'react-gtm-module'

import { AnalyticsContext } from '../types/analytics'

const logoutEvent = (context: AnalyticsContext = '') => {
  TagManager.dataLayer({ dataLayer: {
    event: 'logout',
    context,
  } })
}

export default logoutEvent
