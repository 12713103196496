import { Translate } from 'next-translate'

import { roundToTwoDecimals } from '../../utils/math'
import { ProductAttribute } from '../../utils/types/Product'
import { findByType, getValue, PropertyType } from '../property'
import { ProductProperty } from '../../utils/types/Resource'

export type SalesUnitType = 'carton' | 'package' | 'piece' | 'pair'

export const unitToPlural = (unit: SalesUnitType) => {
  switch (unit) {
    case 'piece':
      return 'Pieces'
    case 'package':
      return 'Packages'
    case 'carton':
      return 'Cartons'
    default:
      return 'Pieces'
  }
}

export const salesUnit = (attributes?: ProductAttribute[]): SalesUnitType => (
  attributes?.find((attr) => (
    attr['attribute.type'] === 'sales-unit'))
    ?.['attribute.code'] as SalesUnitType | undefined || 'piece'
)

export const isType = (attr: ProductAttribute, type: string | string[]): boolean => {
  if (typeof type === 'string') {
    return attr['attribute.type'] === type
  }
  return type.includes(attr['attribute.type'])
}

export const isFilterAttribute = (attr: ProductAttribute): boolean => (
  ['top-filter', 'advanced-filter'].includes(attr['attribute.code']))

export const isAvailable = (
  selected: string[],
  available: Map<string, number>,
  total: Map<string, number>,
  search?: string,
) => ({ id }: { id: string }): boolean => {
  // Don't show options that would return 0 results when searching
  if (search) {
    return available.has(id) || selected.includes(id)
  }
  // Otherwise show disabled options that exist in current category,
  // but not with current filters
  return total.has(id) || selected.includes(id)
}

export const convertFeatureNumericUnit = (value: string | undefined, unit: string) => {
  const floatValue = parseFloat(value || '')

  if (Number.isNaN(floatValue)) {
    return unit
  }

  const formatUnit = (number: number) => roundToTwoDecimals(number).toLocaleString('fi-FI')

  switch (floatValue > 0) {
    case floatValue < 0.0001:
      if (unit === 'kg') return `${formatUnit((floatValue * 1000000))} mg`
      if (unit === 'm') return `${formatUnit(floatValue * 1000000)} μm`
      break
    case floatValue < 0.001:
      if (unit === 'kg') return `${formatUnit(floatValue * 1000)} g`
      if (unit === 'm') return `${formatUnit(floatValue * 1000)} mm`
      break
    case floatValue < 1:
      if (unit === 'kg') return `${formatUnit(floatValue * 1000)} g`
      if (unit === 'm') return `${formatUnit(floatValue * 100)} cm`
      break
    default:
      return `${formatUnit(floatValue)} ${unit}`
  }

  return `${formatUnit(floatValue)} ${unit}`
}

export const formatHighlightedTag = (val?: string) => {
  if (!val) return val

  return val
    .replace(/ISO \d+/, '') // for single case of (ISO any number)
    .replace(/\s+/g, ' ') // replaces consecutive spaces
}

export const findFeatureValue = (attributes?: ProductAttribute[]): ProductAttribute | undefined => (
  attributes?.find((attr) => attr['attribute.type'] === 'feature-value'))

export const getHighlight = (
  attributes: ProductAttribute[],
  type: string | string[],
  productProperty?: ProductProperty[],
): string | undefined => {
  const highlightAttr = attributes.find((attr) => (
    isType(attr, type) && attr['product.lists.type']?.startsWith('highlight')))

  // Highlight is numeric if 'product/property' contains numeric values
  const numericValueProperty = findByType(productProperty || [], highlightAttr?.['attribute.code'] || '')
  const numericValue = numericValueProperty ? getValue(numericValueProperty) : ''

  if (highlightAttr && numericValue) {
    const scaleUnitProperty = findByType(highlightAttr, PropertyType.scaleUnit)
    if (scaleUnitProperty) {
      // Use scale unit if available
      return formatHighlightedTag(`${highlightAttr?.['attribute.label']} · ${numericValue}${getValue(scaleUnitProperty)}`)
    }
    const unit = findFeatureValue(highlightAttr?.attribute)?.['attribute.label'].trim().split(' ').pop() || ''
    const value = convertFeatureNumericUnit(numericValue, unit.includes('#') ? '' : unit)
    return formatHighlightedTag(`${highlightAttr?.['attribute.label']} · ${value}`)
  }

  if (highlightAttr && highlightAttr['product.lists.type']?.includes('|')) {
    const [, valueCode] = highlightAttr['product.lists.type'].split('|')
    const highlightedValue = attributes.find((attr) => attr['attribute.code'] === valueCode)
    return formatHighlightedTag(highlightedValue?.['attribute.label'])
  }
  return formatHighlightedTag(highlightAttr?.['attribute.label'])
}

export const sortColorDropdownData = (
  prodAttributes: ProductAttribute[],
  t: Translate,
  translate = true,
) => prodAttributes
  .map((color) => ({
    // not translating for edit product
    label: translate ? t(`color:${color['attribute.label']}`) : color['attribute.label'],
    value: color['attribute.code'],
  }))
  .sort((a, b) => (
    a.label.localeCompare(b.label)
  ))

export const isPropertyType = (type: string) => (prop: ProductProperty): boolean => (
  prop['product.property.type'] === type
)
