import React, { FC } from 'react'
import { IconProps } from '@rsuite/icons/lib/Icon'

import styles from '../../../styles/HeartIcon.module.less'

interface FavIconProps extends IconProps {
  variant?: 'dark' | 'primary' | 'primary-filled' | 'light' | 'primary-dark' | 'primary-dark-filled'
}

const HeartIcon: FC<FavIconProps> = (props) => {
  const { className, onClick, variant = 'light' } = props

  return (
    <svg
      onClick={onClick}
      viewBox="0 0 27 25"
      width={27}
      height={25}
      xmlns="http://www.w3.org/2000/svg"
      className={`${styles['heart-icon']}
                  ${styles[variant]}
                  ${className}`}
    >
      <path
        d="M13.3305 23.6764C12.4524 22.9055 10.7844 21.4097 8.95703 19.6086C6.29186 16.9818 3.3946 13.8078 2.04304 11.3096L2.02905 11.2838L2.01355 11.2587C1.37275 10.2246 1 8.99506 1 7.67118C1 3.96107 3.92033 1 7.46607 1C9.51599 1 11.35 1.98405 12.5399 3.5354L13.3333 4.56992L14.1268 3.5354C15.3167 1.98405 17.1507 1 19.2006 1C22.7463 1 25.6667 3.96107 25.6667 7.67118C25.6667 9.01031 25.2852 10.253 24.6307 11.2946L24.6144 11.3206L24.5997 11.3475C23.2352 13.8474 20.3419 17.0119 17.6859 19.6276C15.8651 21.4208 14.205 22.9089 13.3305 23.6764Z"
        stroke="#62BFAD"
        strokeWidth={2}
      />
    </svg>
  )
}

export default HeartIcon
