import { HasResource, Property } from '../utils/types/Resource'

export const PropertyType = {
  scaleUnit: 'scale-unit',
} as const
export const getValue = (property: Property): string => {
  if ('product.property.value' in property) {
    return property['product.property.value']
  }
  return property['attribute.property.value']
}

export const getType = (property: Property): string => {
  if ('product.property.type' in property) {
    return property['product.property.type']
  }
  return property['attribute.property.type']
}

const hasProductProperty = <P, T extends HasResource>(p: { [k in `${T}/property`]?: P[] }): p is { 'product/property': P[] } => (
  (p as { 'product/property': P[] })['product/property'] !== undefined
)

const hasAttributeProperty = <P, T extends HasResource>(p: { [k in `${T}/property`]?: P[] }): p is { 'attribute/property': P[] } => (
  (p as { 'attribute/property': P[] })['attribute/property'] !== undefined
)

export const findByType = <P extends Property, T extends HasResource>
  (list: { [k in `${T}/property`]?: P[] } | P[], match: string): P | undefined => {
  if (Array.isArray(list)) {
    return list.find((prop) => getType(prop) === match)
  }
  if (hasAttributeProperty(list)) {
    return list['attribute/property'].find((prop) => getType(prop) === match)
  }
  if (hasProductProperty(list)) {
    return list['product/property'].find((prop) => getType(prop) === match)
  }
  return undefined
}
