import TagManager from 'react-gtm-module'

interface FormValues {
  billingAddress: string
  postalCode: string
  country: string
  businessId: string
  paymentNotes: string
}

export interface UpdateBillingDetailsParams {
  prevValues?: FormValues
  newValues: FormValues
}

const updateBillingDetails = ({ prevValues, newValues }: UpdateBillingDetailsParams) => {
  const prevValuesFormatted = {
    billing_address: prevValues?.billingAddress || '',
    postal_code: prevValues?.postalCode || '',
    country: prevValues?.country || '',
    business_id: prevValues?.businessId || '',
    optional_payment_notes: prevValues?.paymentNotes || '',
  }

  const newValuesFormatted = {
    billing_address: newValues.billingAddress,
    postal_code: newValues.postalCode,
    country: newValues.country,
    business_id: newValues.businessId,
    optional_payment_notes: newValues.paymentNotes,
  }

  TagManager.dataLayer({ dataLayer: {
    event: 'update_billing_details',
    previous_values: prevValuesFormatted,
    new_values: newValuesFormatted,
  } })
}

export default updateBillingDetails
