import Image, { ImageProps } from 'next/legacy/image'
import { FC } from 'react'

import ProductIconContainer from '../ImportedProducts/ProductIconContainer'

interface ProductEmptyImageProps extends Omit<ImageProps, 'src'>{
  isImportedProduct?: boolean
}
const ProductEmptyImage: FC<ProductEmptyImageProps> = (props) => {
  const { isImportedProduct, ...rest } = props

  if (isImportedProduct) {
    return <ProductIconContainer className="margin-left-spacer" />
  }

  return (
    <Image
      src="/graphics/product-no-image.svg"
      alt="Product with no image"
      height={100}
      width={100}
      {...rest}
    />
  )
}

export default ProductEmptyImage
