import TagManager from 'react-gtm-module'

import { Product } from '../types/Product'
import { emptyEcomDataLayer, getAnalyticsProduct } from './utils'
import { CountryId } from '../constants'
import { getCurrencyCode } from '../locales'
import { getProductPrice } from '../../lib/resources/price'

interface ViewItemParams {
  product: Product
  country: CountryId
  getLocalizedProductUrl: (product?: Product) => string
}

const viewItem = ({ product, country, getLocalizedProductUrl }: ViewItemParams) => {
  const analyticsProduct = getAnalyticsProduct({ product, country, getLocalizedProductUrl })
  const currency = getCurrencyCode(country)
  const value = getProductPrice(product)

  emptyEcomDataLayer() // First empty datalayer
  TagManager.dataLayer({
    dataLayer: {
      event: 'view_item',
      ecommerce: {
        currency,
        value,
        items: [analyticsProduct],
      },
    },
  })
}

export default viewItem
