import React, { FC } from 'react'
import Link from 'next/link'

import { StrapiLink } from '../../../utils/types/strapi'
import useModalLinkHandler from '../../Modals/hooks/useModalLinkHandler'

interface FooterLinksColumnProps {
  title: string
  links: StrapiLink[]
}

const FooterLinksColumn: FC<FooterLinksColumnProps> = (props) => {
  const { links, title } = props

  const handleModalLinkClick = useModalLinkHandler({ analyticsLocation: 'main_footer' })

  return (
    <div>
      <div className="h6">{title}</div>
      <ul>
        {links
          .map((item) => (
            <li key={(item.link ?? '') + item.label}>
              <Link
                onClick={(e) => handleModalLinkClick(e, item.link ?? '')}
                href={item.link ?? '#'}
                target={item.target || undefined}
              >
                {item.label}
              </Link>
            </li>
          ))}
      </ul>
    </div>
  )
}

export default FooterLinksColumn
