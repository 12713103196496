import { useRouter } from 'next/router'

import useIsUrlHashIncluded from '../../../services/useIsUrlHashIncluded'
import useCloseHashModal from './useCloseHashModal'
import { ModalUrlHash, PREVIOUS_URL_STORAGE_KEY } from '../../../utils/constants'

const useModalTrigger = (urlHash: ModalUrlHash) => {
  const { push } = useRouter()
  const isUrlHashIncluded = useIsUrlHashIncluded(urlHash)
  const closeModal = useCloseHashModal(urlHash)

  const openModalTrigger = () => {
    if (!isUrlHashIncluded) {
      // Push url to session storage to handle proper history stack management when closing modal
      sessionStorage.setItem(PREVIOUS_URL_STORAGE_KEY, window.location.pathname)
      push(urlHash, undefined, { shallow: true })
    }
  }

  return { openModalTrigger, closeModal }
}

export default useModalTrigger
