import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { LOGIN_MODAL_TRIGGERED_PLACE_KEY, LoginModalTriggeredPlace, MODAL_HASHES } from '../utils/constants'
import { useAuth } from './useAuth'
import useIsUrlHashIncluded from './useIsUrlHashIncluded'
import useBuyer from '../components/hooks/useBuyer'
import useUrls from './useUrls'
import { isSuperUser, isSupplier } from '../lib/supplier'
import { AnalyticsContext, AnalyticsLocation, LoginModalEvents, TrackingActions } from '../utils/types/analytics'
import useTrackingContext from './trackingContext/useTrackingContext'
import { useCustomer } from '../components/BuyerProfile/Account/CustomerProvider'
import { PropertyInclude } from '../utils/types/customer'
import { selectCustomerIncluded } from '../components/BuyerProfile/Account/selectors'

const useLoginModal = () => {
  const [shouldShowModal, setShouldShowModal] = useState(false)

  const { LOGIN_URL_HASH, REGISTER_URL_HASH, INVITE_URL_HASH } = MODAL_HASHES

  const isLoginUrlHashIncluded = useIsUrlHashIncluded([
    LOGIN_URL_HASH,
    REGISTER_URL_HASH,
    INVITE_URL_HASH,
  ])
  const { isOnboardingDataLoading, isInvited } = useBuyer()
  const { customerResponse } = useCustomer()
  const { user, isImpersonated } = useAuth()
  const { replaceT, pushT } = useUrls()
  const { asPath, pathname, route } = useRouter()
  const isOnOAuthPage = pathname === '/oauth/[action]/[provider]'

  const { dispatchTrackingContext } = useTrackingContext()

  const redirectToMyAccount = () => pushT('/my-account')

  const closeLoginModal = () => {
    setShouldShowModal(false)
  }

  // Used to push analytics location to tracking context on modal open and close clicks
  // Also used to empty analytics context on modal close cleanup function
  const setAnalyticsLocation = ({
    analyticsLocation,
    remove = false,
    urlHash,
  }: {
    analyticsLocation?: AnalyticsLocation | ''
    remove?: boolean
    urlHash?: string
  }) => {
    const isLogin = urlHash?.includes(LOGIN_URL_HASH)
    const analyticsFunction = isLogin ? 'login_modal' : 'register_modal'

    const analyticsContext: AnalyticsContext = analyticsLocation
      ? `${analyticsLocation}_${analyticsFunction}`
      : `direct_${analyticsFunction}`

    Object.values(LoginModalEvents).forEach((event) => {
      dispatchTrackingContext({
        eventName: event,
        eventParameters: {
          context: analyticsContext || '',
        },
      },
      remove ? TrackingActions.Remove : TrackingActions.Add)
    })
  }

  const openModal = (urlHash: string, analyticsLocation?: AnalyticsLocation | '') => {
    setShouldShowModal(true)

    const urlWithoutHash = `${asPath.split('#')[0]}${urlHash}`

    replaceT(
      urlWithoutHash,
      undefined,
      { shallow: true, scroll: false },
    )

    if (analyticsLocation) {
      setAnalyticsLocation({ analyticsLocation, urlHash })
    }
  }

  const redirectAfterLogin = () => {
    const triggeredPlace = sessionStorage.getItem(LOGIN_MODAL_TRIGGERED_PLACE_KEY)
    switch (triggeredPlace) {
      case LoginModalTriggeredPlace.productHeart:
        break

      case LoginModalTriggeredPlace.headerHeart:
        pushT('/my-products')
        break

      default:
        pushT('/my-account')
        break
    }

    sessionStorage.removeItem(LOGIN_MODAL_TRIGGERED_PLACE_KEY)
  }

  const openLoginModal = (
    analyticsLocation?: AnalyticsLocation | '',
  ) => openModal(LOGIN_URL_HASH, analyticsLocation)

  const openRegisterModal = (
    analyticsLocation?: AnalyticsLocation,
  ) => openModal(REGISTER_URL_HASH, analyticsLocation)

  // If user haven't completed the onboarding then open the modal
  // No need to add url hash in that case
  useEffect(() => {
    if (user) {
      const { countryid } = user
      const hasIndustryFilled = customerResponse
        ? selectCustomerIncluded(customerResponse)?.find(
          (include): include is PropertyInclude => include.type === 'customer.property'
          && include.attributes['customer.property.type'] === 'industry',
        )
        // Fallback true to avoid reopening if customerResponse is null and onboarding is completed
        : true

      // Onboarding is already done. So close modal.
      // Note: countryid is filled in last onboarding step
      if (countryid && hasIndustryFilled && !isInvited) {
        closeLoginModal()
        return
      }

      if (route === '/checkout/[step]') {
        return
      }

      // Onboarding is still not finished. So open modal
      // Do not show the onboarding modal on logout and if no user
      setShouldShowModal(user !== null && route !== '/logout')
    }

    if (isSuperUser(user) || isSupplier(user) || isImpersonated) {
      closeLoginModal()
    }
  }, [user, isImpersonated, isLoginUrlHashIncluded, customerResponse, route])

  const isReadyToShowModal = !isOnboardingDataLoading && !isOnOAuthPage

  const showLoginModal = (isLoginUrlHashIncluded && isReadyToShowModal)
    // Used to show the onboarding modal after placing order, as url will have no hash
    || (shouldShowModal && isReadyToShowModal)

  return {
    shouldShowLoginModal: showLoginModal,
    closeLoginModal,
    openLoginModal,
    openRegisterModal,
    redirectToMyAccount,
    redirectAfterLogin,
    setAnalyticsLocation,
  }
}

export default useLoginModal
