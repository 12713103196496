import TagManager from 'react-gtm-module'

import { AnalyticsContext } from '../types/analytics'

type BuyerCatalogEvent = 'view_buyer_catalog'
| 'create_buyer_catalog'
| 'delete_buyer_catalog'
| 'rename_buyer_catalog'
| 'add_item_buyer_catalog'
| 'update_item_buyer_catalog'
| 'delete_item_buyer_catalog'
| 'add_to_cart_buyer_catalog'
| 'single_add_to_cart_buyer_catalog'
| 'partial_add_to_cart_buyer_catalog'

interface BuyerCatalogEventParams {
  catalogName: string // For rename_buyer_catalog the value is in format "Old name => New name"
  catalogId: string // Add this new parameter
  productSku?: string
  context: AnalyticsContext
}

const buyerCatalogEventFactory = (eventName: BuyerCatalogEvent) => (
  params: BuyerCatalogEventParams,
) => {
  const { catalogName, catalogId, productSku, context } = params
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      catalog_name: catalogName,
      catalog_id: catalogId, // Add this new field
      product_id: productSku,
      context,
    },
  })
}

// Using factory pattern instead of enum or hardcoded types as it's less error-prone to
// export clearly named functions
export const viewBuyerCatalog = buyerCatalogEventFactory('view_buyer_catalog')
export const createBuyerCatalog = buyerCatalogEventFactory('create_buyer_catalog')
export const deleteBuyerCatalog = buyerCatalogEventFactory('delete_buyer_catalog')
export const renameBuyerCatalog = buyerCatalogEventFactory('rename_buyer_catalog')
export const addItemBuyerCatalog = buyerCatalogEventFactory('add_item_buyer_catalog')
export const deleteItemBuyerCatalog = buyerCatalogEventFactory('delete_item_buyer_catalog')
export const updateItemBuyerCatalog = buyerCatalogEventFactory('update_item_buyer_catalog')
export const addToCartBuyerCatalog = buyerCatalogEventFactory('add_to_cart_buyer_catalog')
export const singleAddToCartBuyerCatalog = buyerCatalogEventFactory('single_add_to_cart_buyer_catalog')
export const partialAddToCartBuyerCatalog = buyerCatalogEventFactory('partial_add_to_cart_buyer_catalog')
