import React, { FC } from 'react'
import { Icon } from '@rsuite/icons'
import { IconProps } from '@rsuite/icons/lib/Icon'

const OrderListSvg = React.forwardRef<SVGSVGElement>((props, ref) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    ref={ref}
  >
    <path
      d="M20.2502 1.86875H20.25L6.25 1.86875L6.24975 1.86875C5.32022 1.86976 4.42904 2.23947 3.77176 2.89676C3.11447 3.55404 2.74476 4.44522 2.74375 5.37475V5.375L2.74375 24.625L2.74375 24.6252C2.74476 25.5548 3.11447 26.446 3.77176 27.1032C4.42904 27.7605 5.32022 28.1302 6.24975 28.1313H6.25H15.2625C15.4962 28.1313 15.7204 28.0384 15.8856 27.8731C16.0509 27.7079 16.1437 27.4837 16.1437 27.25C16.1437 27.0163 16.0509 26.7921 15.8856 26.6269C15.7204 26.4616 15.4962 26.3687 15.2625 26.3687H6.25025C5.78787 26.3682 5.34457 26.1843 5.01762 25.8574C4.69069 25.5305 4.50679 25.0872 4.50625 24.6249C4.50625 24.6248 4.50625 24.6248 4.50625 24.6248V5.37525C4.50625 5.37521 4.50625 5.37518 4.50625 5.37514C4.50679 4.9128 4.69069 4.46955 5.01762 4.14262C5.34455 3.81569 5.7878 3.63179 6.25014 3.63125C6.25018 3.63125 6.25021 3.63125 6.25025 3.63125H20.2498C20.2498 3.63125 20.2498 3.63125 20.2499 3.63125C20.7122 3.63179 21.1555 3.81569 21.4824 4.14262C21.8093 4.46957 21.9932 4.91287 21.9937 5.37525V14.3175C21.9937 14.5512 22.0866 14.7754 22.2519 14.9406C22.4171 15.1059 22.6413 15.1987 22.875 15.1987C23.1087 15.1987 23.3329 15.1059 23.4981 14.9406C23.6634 14.7754 23.7563 14.5512 23.7563 14.3175V5.375V5.37475C23.7552 4.44522 23.3855 3.55404 22.7282 2.89676C22.071 2.23947 21.1798 1.86976 20.2502 1.86875Z"
      fill="#0F2137"
      stroke="#0F2137"
      strokeWidth="0.45"
    />
    <path
      d="M8 9.63125H18.5C18.7006 9.63125 18.8929 9.55157 19.0347 9.40975C19.1766 9.26793 19.2562 9.07557 19.2562 8.875C19.2562 8.67443 19.1766 8.48207 19.0347 8.34025C18.8929 8.19843 18.7006 8.11875 18.5 8.11875H8C7.79943 8.11875 7.60708 8.19843 7.46525 8.34025C7.32343 8.48207 7.24375 8.67443 7.24375 8.875C7.24375 9.07557 7.32343 9.26793 7.46525 9.40975C7.60708 9.55157 7.79943 9.63125 8 9.63125Z"
      fill="#0F2137"
      stroke="#0F2137"
      strokeWidth="0.2"
    />
    <path
      d="M17.5063 15C17.5063 14.7994 17.4266 14.6071 17.2847 14.4652C17.1429 14.3234 16.9506 14.2437 16.75 14.2437H8C7.79943 14.2437 7.60708 14.3234 7.46525 14.4652C7.32343 14.6071 7.24375 14.7994 7.24375 15C7.24375 15.2006 7.32343 15.3929 7.46525 15.5347C7.60708 15.6766 7.79943 15.7563 8 15.7563H16.75C16.9506 15.7563 17.1429 15.6766 17.2847 15.5347C17.4266 15.3929 17.5063 15.2006 17.5063 15Z"
      fill="#0F2137"
      stroke="#0F2137"
      strokeWidth="0.2"
    />
    <path
      d="M8 20.3687C7.79943 20.3687 7.60708 20.4484 7.46525 20.5902C7.32343 20.7321 7.24375 20.9244 7.24375 21.125C7.24375 21.3256 7.32343 21.5179 7.46525 21.6597C7.60708 21.8016 7.79943 21.8813 8 21.8813H13.25C13.4506 21.8813 13.6429 21.8016 13.7847 21.6597C13.9266 21.5179 14.0062 21.3256 14.0062 21.125C14.0062 20.9244 13.9266 20.7321 13.7847 20.5903C13.6429 20.4484 13.4506 20.3687 13.25 20.3687H8Z"
      fill="#0F2137"
      stroke="#0F2137"
      strokeWidth="0.2"
    />
    <path
      d="M21.4495 28.5552C22.7251 28.5552 23.972 28.177 25.0326 27.4683C26.0932 26.7596 26.9199 25.7523 27.408 24.5738C27.8962 23.3954 28.0239 22.0986 27.775 20.8475C27.5262 19.5964 26.9119 18.4472 26.0099 17.5453C25.108 16.6433 23.9588 16.029 22.7077 15.7802C21.4566 15.5313 20.1599 15.659 18.9814 16.1472C17.8029 16.6353 16.7956 17.462 16.0869 18.5226C15.3783 19.5832 15 20.8301 15 22.1057C15.0018 23.8157 15.6819 25.455 16.891 26.6642C18.1002 27.8733 19.7395 28.5534 21.4495 28.5552ZM21.4495 17.0895C22.4416 17.0895 23.4114 17.3837 24.2364 17.9349C25.0613 18.4861 25.7042 19.2695 26.0839 20.1861C26.4636 21.1027 26.5629 22.1113 26.3694 23.0844C26.1758 24.0574 25.698 24.9512 24.9965 25.6528C24.295 26.3543 23.4012 26.8321 22.4281 27.0256C21.455 27.2192 20.4464 27.1198 19.5298 26.7402C18.6132 26.3605 17.8298 25.7175 17.2786 24.8926C16.7274 24.0677 16.4332 23.0979 16.4332 22.1057C16.4347 20.7758 16.9637 19.5007 17.9041 18.5603C18.8445 17.6199 20.1195 17.0909 21.4495 17.0895Z"
      fill="#0F2137"
      stroke="#0F2137"
      strokeWidth="0.2"
    />
    <path
      d="M19.5169 22.8309H20.7249V24.039C20.7249 24.2313 20.8013 24.4156 20.9373 24.5516C21.0732 24.6875 21.2576 24.7639 21.4498 24.7639C21.642 24.7639 21.8264 24.6875 21.9624 24.5516C22.0983 24.4156 22.1747 24.2313 22.1747 24.039V22.8309H23.3828C23.575 22.8309 23.7594 22.7545 23.8953 22.6186C24.0312 22.4827 24.1076 22.2983 24.1076 22.1061C24.1076 21.9138 24.0312 21.7294 23.8953 21.5935C23.7594 21.4576 23.575 21.3812 23.3828 21.3812H22.1747V20.1731C22.1747 19.9809 22.0983 19.7965 21.9624 19.6605C21.8264 19.5246 21.642 19.4482 21.4498 19.4482C21.2576 19.4482 21.0732 19.5246 20.9373 19.6605C20.8013 19.7965 20.7249 19.9809 20.7249 20.1731V21.3812H19.5169C19.3246 21.3812 19.1402 21.4576 19.0043 21.5935C18.8684 21.7294 18.792 21.9138 18.792 22.1061C18.792 22.2983 18.8684 22.4827 19.0043 22.6186C19.1402 22.7545 19.3246 22.8309 19.5169 22.8309Z"
      fill="#0F2137"
    />
  </svg>

))

const CatalogHeaderIcon: FC<IconProps> = (props) => (
  <Icon
    {...props}
    as={OrderListSvg}
  />
)

export default CatalogHeaderIcon
