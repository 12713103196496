import FinnishFlag from '../components/Icons/Flags/FinnishFlag'
import GermanFlag from '../components/Icons/Flags/GermanFlag'
import SwedishFlag from '../components/Icons/Flags/SwedishFlag'
import { Locale } from '../external'
import { ActiveCountryId, ContactCardData, CountryData } from './constants'

// Render flags when iterating through countries/languages
export const renderFlag = (countryId: Locale) => {
  switch (countryId) {
    case 'de':
      return <GermanFlag />
    case 'sv':
      return <SwedishFlag />
    // defaults to fi
    default:
      return <FinnishFlag />
  }
}

export const getCountryPhonePlaceholder = (country: Locale | null) => {
  switch (country) {
    case 'de':
      return '+49 000 00000000'
    case 'sv':
      return '+46 00 000 00 00'
    default:
      return '+358 00 0000000' // FI number set as default
  }
}

export const getCountryPhone = (country: ActiveCountryId) => {
  const getContactPersonPhone = (
    name: string,
    countryId = country,
  ) => (ContactCardData[countryId].find((person) => person.name === name)?.phone)

  switch (country) {
    case 'fi':
      return getContactPersonPhone('Henri')
    case 'sv':
      return getContactPersonPhone('Toni')
    case 'de':
      return getContactPersonPhone('Tahir')
    default:
      return getContactPersonPhone('Henri', 'fi')
  }
}

export const getCountryEmail = (country: ActiveCountryId) => {
  switch (country) {
    case 'fi':
      return 'kysy@droppe.fi'
    case 'sv':
      return 'fraga@droppe.fi'
    case 'de':
      return 'frage@droppe-group.de'
    default:
      return 'ask@droppe.fi'
  }
}

export const findCountry = (targetCountryId?: ActiveCountryId) => CountryData.find(
  (countryObj) => countryObj.locale === targetCountryId?.toLocaleLowerCase(),
)
