import { FC } from 'react'

import { BLACK_COLOR } from '../../utils/constants'

const CookieIcon: FC = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.9589 19.3995C5.82769 19.3776 5.76821 19.2753 5.77271 19.1956C5.91605 16.6598 6.73508 14.203 8.15156 12.0831C9.71737 9.73973 11.9429 7.91327 14.5468 6.83472C17.1506 5.75617 20.0158 5.47397 22.78 6.02381C25.5443 6.57365 28.0834 7.93083 30.0763 9.92373C32.0692 11.9166 33.4263 14.4557 33.9762 17.22C34.526 19.9842 34.2438 22.8494 33.1653 25.4532C32.0867 28.0571 30.2603 30.2826 27.9169 31.8484C25.8167 33.2517 23.809 34.0506 21.4485 34.2172C21.4219 34.2191 21.4008 34.2114 21.3803 34.1892C21.3575 34.1646 21.3411 34.126 21.346 34.0778C21.6121 31.4161 19.8467 28.9754 17.2353 28.3951L12.8379 27.4179C12.7363 27.3953 12.6594 27.3122 12.6446 27.2092L12.2601 24.5171C11.9056 22.0362 9.98517 20.0705 7.51314 19.6585L5.9589 19.3995Z"
      stroke={BLACK_COLOR}
      strokeWidth="1.5"
    />
    <circle
      cx="28"
      cy="24"
      r="2"
      transform="rotate(180 28 24)"
      fill={BLACK_COLOR}
    />
    <circle
      cx="19"
      cy="22"
      r="2"
      transform="rotate(180 19 22)"
      fill={BLACK_COLOR}
    />
    <circle
      cx="9.5"
      cy="30.5"
      r="1.5"
      transform="rotate(180 9.5 30.5)"
      fill={BLACK_COLOR}
    />
    <circle
      cx="7"
      cy="25"
      r="1"
      transform="rotate(180 7 25)"
      fill={BLACK_COLOR}
    />
    <circle
      cx="15"
      cy="34"
      r="2"
      transform="rotate(180 15 34)"
      fill={BLACK_COLOR}
    />
    <circle
      cx="25"
      cy="14"
      r="2"
      transform="rotate(180 25 14)"
      fill={BLACK_COLOR}
    />
    <circle
      cx="16"
      cy="12"
      r="2"
      transform="rotate(180 16 12)"
      fill={BLACK_COLOR}
    />
  </svg>
)

export default CookieIcon
