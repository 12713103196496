import React, { ReactNode } from 'react'
import { SWRConfig } from 'swr'
import { useRouter } from 'next/router'

import { SWRFetcher } from '../utils/fetcher'
import { useAuth } from '../services/useAuth'

interface SwrWrapperProps {
  children: ReactNode
}

const SwrWrapper = ({ children }: SwrWrapperProps) => {
  const { openInvalidSessionModal } = useAuth()
  const { pathname } = useRouter()

  const swrOptions = {
    refreshInterval: 0,
    fetcher: SWRFetcher(openInvalidSessionModal, pathname),
    // Dedupe requests with the same key in this time span in milliseconds, default is 2000
    dedupingInterval: 5000,
  }

  return (
    <SWRConfig
      value={swrOptions}
    >
      {children}
    </SWRConfig>
  )
}

export default SwrWrapper
