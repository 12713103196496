import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import debounce from 'lodash/debounce'

import searchEvent from '../../utils/analytics/searchEvent'
import { SearchResults } from '../../utils/types/search'
import { getCountryAndLocaleStrings } from '../../utils/locales'

interface UseSearchEventParams {
  searchTerm: string
  searchResults: SearchResults
  resultQuantity: number
  isLoading: boolean
}

// Pushes analytics search event to datalayer
const useSearchEvent = (useSearchEventParams: UseSearchEventParams) => {
  const {
    searchTerm,
    searchResults,
    resultQuantity,
    isLoading,
  } = useSearchEventParams
  // State to prevent multiple triggers of the same search term
  const [hasTriggered, setHasTriggered] = useState(false)

  const { locale: countryAndLocale } = useRouter()
  const { locale, country } = getCountryAndLocaleStrings(countryAndLocale)

  const triggerEventImmediately = useRef(() => {})

  // Debounced function to trigger search event after a delay
  const { current: debouncedTriggerEvent } = useRef(
    debounce((args) => {
      if (!hasTriggered) {
        searchEvent(args)
        setHasTriggered(true)
      }
    }, 3000),
  )

  useEffect(() => {
    // Only trigger event if search is complete and term is valid
    if (!isLoading && searchTerm && searchTerm !== '*') {
      setHasTriggered(false)
      const eventArgs = { searchTerm, locale, country, searchResults, resultQuantity }

      debouncedTriggerEvent(eventArgs)

      // Set up function for immediate triggering (e.g., on search result click)
      triggerEventImmediately.current = () => {
        debouncedTriggerEvent.flush()
      }
    }
  }, [searchTerm, isLoading])

  return triggerEventImmediately.current
}

export default useSearchEvent
