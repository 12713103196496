import { DeliveryService, getStatus } from '../../utils/types/Product'

//  TODO: Remove eslint ignore when adding more exports
// eslint-disable-next-line import/prefer-default-export
export const deliveryServiceStub: DeliveryService = {
  id: '',
  'service.type': 'delivery',
  'service.code': '',
  'service.label': '',
  'service.provider': 'Standard',
  'service.status': getStatus.public,
  price: [{
    id: '',
    'price.type': 'default',
    'price.label': 'FI',
    'price.currencyid': 'EUR',
    'price.domain': 'service',
    'price.quantity': 1,
    'price.value': '0',
    'price.costs': 0,
    'price.taxrate': 24,
  }],
}
