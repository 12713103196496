import TagManager from 'react-gtm-module'

const continueBuyerOnboarding = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'continue_buyer_onboarding',
    },
  })
}

export default continueBuyerOnboarding
