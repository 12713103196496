import { FC } from 'react'
import { FlexboxGrid, Popover, Whisper } from 'rsuite'
import InfoOutline from '@rsuite/icons/InfoOutline'
import Link from 'next/link'

import SafeTranslate from '../common/SafeTranslate'
import { formatPriceNumber } from '../../utils/math'
import { CurrencySymbol } from '../../utils/types/Product'
import { getSupplierShopPageUrl } from '../../lib/supplier'
import useUrls from '../../services/useUrls'

import styles from '../../styles/SupplierMovWarning.module.less'

interface SupplierMovWarningProps {
  mov: number
  remainingMov: number
  supplierName: string
  currency: CurrencySymbol
  supplierCode: string
}

const SupplierMovWarning: FC<SupplierMovWarningProps> = (props) => {
  const { mov, supplierName, remainingMov, currency, supplierCode } = props

  const { urlT } = useUrls()

  const movFormat = `${formatPriceNumber(mov)} ${currency}`
  const remainingMovText = `${formatPriceNumber(remainingMov)} ${currency}`
  const supplierPageRedirectUrl = getSupplierShopPageUrl(supplierCode, urlT)

  return (
    <Whisper
      trigger="hover"
      placement="bottom"
      preventOverflow
      speaker={(
        <Popover className={styles.popover}>
          <SafeTranslate
            i18nKey="products:To reach the minimum order value of {{value}} for the brand <b>{{supplier}}</b>, you can order this product or combine it with several other products from <b>{{supplier}}</b>"
            components={{ b: <b /> }}
            values={{ value: movFormat, supplier: supplierName }}
          />
        </Popover>
)}
    >
      <FlexboxGrid
        className={`${styles['warning-bar']} flex-flow-row`}
        justify="center"
        align="middle"
      >
        <InfoOutline className="flex-shrink-col-static" />
        <h6 className="padding-left-spacer">
          <SafeTranslate
            i18nKey="cart:Add {{value}} to checkout <u>{{supplier}}</u> products"
            components={{ u: <Link
              href={supplierPageRedirectUrl}
              className={styles['supplier-link']}
            /> }}
            values={{ value: remainingMovText, supplier: supplierName }}
          />
        </h6>
      </FlexboxGrid>
    </Whisper>
  )
}

export default SupplierMovWarning
