import { FC } from 'react'

import ImpersonationProvider from './ImpersonationProvider'

const withImpersonation = <Props extends {}>(Component: React.ComponentType<Props>) => {
  const WithImpersonation: FC<Props> = (props) => (
    <ImpersonationProvider>
      <Component {...props} />
    </ImpersonationProvider>
  )

  return WithImpersonation
}

export default withImpersonation
