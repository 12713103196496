const ShieldIcon = () => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0L2 1C1.33333 5.66667 1.33333 9 2 11C3.33333 13.6667 5.33333 15.3333 8 16C10.6667 15.3333 12.6667 13.6667 14 11C14.6667 9 14.6667 5.66667 14 1L8 0ZM8 14.9656C5.76641 14.3395 4.09022 12.9084 2.9271 10.6176C2.37566 8.89133 2.35228 5.95642 2.89055 1.86537L8 1.01379L13.1094 1.86537C13.6477 5.95642 13.6243 8.89133 13.0729 10.6176C11.9098 12.9084 10.2336 14.3395 8 14.9656Z"
      fill="#575757"
    />
    <path
      d="M10.6847 5.38105C10.7677 5.29322 10.9061 5.28926 10.994 5.37222C11.072 5.44595 11.0838 5.56354 11.0272 5.65044L11.0028 5.68145L7.28404 9.61895C7.20603 9.70154 7.08013 9.70931 6.99306 9.6433L6.96241 9.61509L4.99366 7.42759C4.91284 7.33779 4.92012 7.19947 5.00992 7.11865C5.08974 7.04682 5.20789 7.04459 5.28992 7.10805L5.31885 7.13491L7.1285 9.14588L10.6847 5.38105Z"
      fill="#575757"
      stroke="#575757"
      strokeWidth="0.6"
    />
  </svg>

)

export default ShieldIcon
