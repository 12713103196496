import TagManager from 'react-gtm-module'

interface FormValues {
  fullName: string
  email: string
  phoneNumber: string
  role: string
}

export interface UpdateAccountDetailsParams {
  prevValues: FormValues
  newValues: FormValues
}

const updateAccountDetails = ({ prevValues, newValues }: UpdateAccountDetailsParams) => {
  const prevValuesFormatted = {
    full_name: prevValues.fullName,
    email: prevValues.email,
    phone_number: prevValues.phoneNumber,
    role: prevValues.role,
  }

  const newValuesFormatted = {
    full_name: newValues.fullName,
    email: newValues.email,
    phone_number: newValues.phoneNumber,
    role: newValues.role,
  }

  TagManager.dataLayer({ dataLayer: {
    event: 'update_account_details',
    previous_values: prevValuesFormatted,
    new_values: newValuesFormatted,
  } })
}

export default updateAccountDetails
