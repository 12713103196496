import { useRouter } from 'next/router'

import { ModalUrlHash } from '../utils/constants'

const useIsUrlHashIncluded = (urlHash: ModalUrlHash | ModalUrlHash[]) => {
  // List of url hash without `#`
  const hashList = [urlHash].flat().map((hash) => hash.slice(1))

  const { asPath } = useRouter()

  const hash = asPath?.split('#')?.[1] || ''
  const isIncluded = hashList.includes(hash)

  return isIncluded
}

export default useIsUrlHashIncluded
