import { useRef, useState } from 'react'
import { FormInstance } from 'rsuite'

export type FormError<T extends object> = {
  [field in keyof T]: string | undefined
}

export default <FormFields extends object>() => {
  const [errors, setErrors] = useState<FormError<FormFields>>({} as FormError<FormFields>)
  const formRef = useRef<FormInstance<FormFields>>(null)

  const handleFormError = (e: FormError<FormFields>) => {
    setErrors(e)
  }

  return { formRef, errors, handleFormError }
}
