import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { Product } from '../../../utils/types/Product'
import { getBasePathWithLocale } from '../../../utils/urls'
import { getPathName } from '../../../lib/product'
import { CountryAndLocale } from '../../../external'
import useUrls from '../../../services/useUrls'

/**
 * Hook for a Util function that returns product url for currently active country and language
 */
const useGetLocalizedProductUrl = () => {
  const { locale: countryAndLocaleString } = useRouter()
  const { urlT } = useUrls()
  const { t } = useTranslation()

  return (product?: Product) => (product
    ? `${getBasePathWithLocale(countryAndLocaleString as CountryAndLocale)}${urlT(getPathName(product, t))}`
    : '')
}

export default useGetLocalizedProductUrl
