/* eslint-disable func-names */
/* eslint-disable no-extend-native */

// Check if the browser supports the Array.prototype.at method
if (!Array.prototype.at) {
  Array.prototype.at = function (index) {
    let arrayIndex = index
    // Convert negative index to positive if out of bounds
    if (index < 0) {
      arrayIndex = this.length + arrayIndex
    }

    // Check if arrayIndex is within bounds
    if (arrayIndex >= 0 && arrayIndex < this.length) {
      return this[arrayIndex]
    }

    return undefined
  }
}

export {}
