import getT from 'next-translate/getT'
import { ParsedUrlQuery } from 'querystring'
import { UrlObject } from 'url'

import { getCountryAndLocaleStrings } from './locales'
import { CountryAndLocale, Locale, LocaleHreflangMap } from '../external'
import i18nConfig from '../i18n'
import { Catalog } from './types/Product'

interface I18NConfig {
  defaultLocale: CountryAndLocale
  locales: CountryAndLocale[]
}

const {
  defaultLocale: defaultCountryAndLocale,
  locales: countryAndLocaleListUnfiltered,
}: I18NConfig = i18nConfig as any

// Remove old locales
export const countryAndLocaleList = countryAndLocaleListUnfiltered
  .filter((countryAndLocale) => !(countryAndLocale.length === 2))

export const getBasePathWithLocale = (countryAndLocale: CountryAndLocale) => {
  // Default locale doesn't have locale in slug
  const localeSlug = countryAndLocale !== defaultCountryAndLocale ? `/${countryAndLocale}` : ''
  return `${process.env.NEXT_PUBLIC_APP_URL || 'https://local.droppe.com'}${localeSlug}`
}

export const getCurrentUrl = (
  path: string = '',
  countryAndLocale: CountryAndLocale = defaultCountryAndLocale,
) => `${getBasePathWithLocale(countryAndLocale)}${path}` // Don't need locale in url for default

export const getLocalizedUrls = async (key: string, secondKey?: string, appendUrl?: string) => (
  Object.fromEntries(await Promise.all(
    countryAndLocaleList.map(async (countryAndLocale) => {
      const { locale } = getCountryAndLocaleStrings(countryAndLocale)
      const baseUrl = getBasePathWithLocale(countryAndLocale)
      const tUrl = await getT(locale, 'url')
      const firstPath = tUrl(key)
      const secondPath = secondKey ? `/${tUrl(secondKey)}` : ''

      return [countryAndLocale, `${baseUrl}/${firstPath}${secondPath}${appendUrl || ''}`]
    }),
  )) as LocaleHreflangMap
)

export const getCategoryUrls = async (
  basePath: string,
  catalog: 'products' | 'suppliers',
  categories: Catalog[],
  countryAndLang?: string,
) => {
  const { locale } = getCountryAndLocaleStrings(countryAndLang)
  const urlT = await getT(locale, 'url')
  const currentPath = `${basePath}/${countryAndLang}/${urlT(catalog)}/${urlT('category')}`

  return categories.map((category) => `${currentPath}/${category['catalog.code']}`)
}

export const getLocalizedCatalogUrls = async (
  catalog: 'products' | 'suppliers',
  params?: ParsedUrlQuery,
) => {
  const isCategory = Array.isArray(params?.filters)
  const categoryPath = isCategory
    ? (params?.filters as string[]).slice(1).join('/')
    : ''

  if (categoryPath === '' || categoryPath.includes('undefined')) {
    return getLocalizedUrls(catalog)
  }

  const localizedUrls = isCategory
    ? await getLocalizedUrls(catalog, 'category', `/${categoryPath}`)
    : await getLocalizedUrls(catalog)

  return localizedUrls
}

export const getLocalizedSupplierUrls = async (
  supplierCode: string,
  paramPaths: string[],
) => {
  const localizedUrls = (
    Object.fromEntries(await Promise.all(
      countryAndLocaleList.map<Promise<[string, string]>>(async (nameCountryAndLocale) => {
        const { locale } = getCountryAndLocaleStrings(nameCountryAndLocale)
        const tUrl = await getT(locale, 'url')
        const baseUrl = getBasePathWithLocale(nameCountryAndLocale)
        const categories = paramPaths.filter((cat) => cat.includes('c_')).join('/')

        return [nameCountryAndLocale, `${baseUrl}/${tUrl('supplier')}/${supplierCode}${categories ? `/${categories}` : ''}`]
      }),
    )) as LocaleHreflangMap
  )

  return localizedUrls
}

export const generateRouterUrlObject = (
  urlPath: string,
  hostType: string,
  params?: Record<string, string>,
): UrlObject => ({
  pathname: urlPath,
  host: hostType,
  ...(params && { query: params }),
})

export const getIsReferrerOrderHistory = (query: ParsedUrlQuery) => {
  const { referrerPage } = query
  return referrerPage === 'order-history'
}

export const getQueryValueFromAsPath = (asPath: string, queryKey: string) => {
  const queryParams = new URLSearchParams(asPath.split('?')[1])
  const queryParamValue = queryParams.get(queryKey)
  return queryParamValue
}

export const getBlogUrl = (locale: Locale = 'en') => {
  const baseUrl = process.env.NEXT_PUBLIC_APP_URL

  if (locale === 'en') {
    return `${baseUrl}/blog`
  }

  return `${baseUrl}/${locale}/blog`
}
