import TagManager from 'react-gtm-module'

const beginBuyerOnboarding = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'begin_buyer_onboarding',
    },
  })
}

export default beginBuyerOnboarding
