import TagManager from 'react-gtm-module'

export interface AddCompanyDetailsBuyerOnboardingParams {
  industryList: string[]
  otherIndustryName: string
  companyName: string
  businessId: string
  country: string
}

const addCompanyDetailsBuyerOnboarding = ({
  industryList,
  otherIndustryName,
  companyName,
  businessId,
  country,
}: AddCompanyDetailsBuyerOnboardingParams) => {
  const industries = industryList.map((industry) => (
    industry === 'Other' ? `Other-${otherIndustryName}` : industry
  ))

  TagManager.dataLayer({
    dataLayer: {
      event: 'add_company_info_buyer_onboarding',
      other_industry: otherIndustryName,
      industry_list_array: industries,
      industry_list_string: industries.join('|'),
      company_name: companyName,
      business_id: businessId,
      country,
    },
  })
}

export default addCompanyDetailsBuyerOnboarding
