import { ParsedUrlQuery } from 'querystring'
import router from 'next/router'
import omit from 'lodash/omit'

import { ProductAttribute } from '../../../utils/types/Product'
import { QueryParam } from '../../../utils/constants'

export const handlePickerChange = (
  pushT: typeof router.push,
  query: ParsedUrlQuery,
  asPath: string,
  filterCode: string,
) => (newValue: string[] | string | number | null) => {
  const value = Array.isArray(newValue) ? newValue?.join('-') : newValue
  const currentQueries = omit(query, [filterCode, QueryParam.filters, QueryParam.supplierCode])

  pushT({
    pathname: asPath.split('?')?.[0],
    query: (Array.isArray(newValue) && newValue?.length === 0) || !newValue
      ? currentQueries
      : { ...currentQueries, [filterCode]: value },
  }, undefined, { shallow: true })
}

export const getPickerValue = (
  query: ParsedUrlQuery,
  filterCode: string,
) => (typeof query[filterCode] === 'string'
  ? String(query[filterCode]).split('-')
  : [])

export const getPickerCode = (filter: ProductAttribute) => `f_${filter['attribute.code'].split('/').at(-1)}` ?? ''

export const getQueryValue = (query: ParsedUrlQuery, queryKey: string) => (typeof query[queryKey] === 'string'
  ? String(query[queryKey]) : '')

// to extract all the filter query params
export const getFilterQueryIds = (query: ParsedUrlQuery) => [
  ...Object.keys(query).filter((q) => q.slice(0, 2) === 'f_'),
  'standards-base',
  'label',
  'use-case',
  'filters',
  'supplierCode',
]
