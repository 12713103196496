import useLoginModal from '../../../services/useLoginModal'
import { MODAL_HASHES } from '../../../utils/constants'
import { AnalyticsLocation } from '../../../utils/types/analytics'
import useContactModal from './useContactModal'
import useRFQModal from './useRFQModal'
import useSampleRequestModal from './useSampleRequestModal'

/**
 * Checks if links contain modal hash, and if they do, open modal with correct analytics context.
 * Benefit: Adds analytics location for static modal links, for instance
 * links added via CMS. Example: Footer links
 *
 * @param analyticsLocation analytics location
 * @returns onClick handler that checks wether link contains modal hash and opens modal.
 */

interface UseModalLinkHandler {
  analyticsLocation?: AnalyticsLocation
}

const useModalLinkHandler = ({ analyticsLocation }: UseModalLinkHandler) => {
  const openContactModal = useContactModal(undefined, analyticsLocation)
  const openRFQModal = useRFQModal({ analyticsLocation })
  const openSampleRequestModal = useSampleRequestModal({ analyticsLocation })
  const { openLoginModal, openRegisterModal } = useLoginModal()

  const {
    CONTACT_URL_HASH,
    RFQ_URL_HASH,
    SAMPLE_REQUEST_URL_HASH,
    LOGIN_URL_HASH,
    REGISTER_URL_HASH,
  } = MODAL_HASHES

  const handlerMap: { [key in string]: () => void } = {
    [CONTACT_URL_HASH]: () => openContactModal(),
    [RFQ_URL_HASH]: () => openRFQModal(),
    [SAMPLE_REQUEST_URL_HASH]: () => openSampleRequestModal(),
    [LOGIN_URL_HASH]: () => openLoginModal(analyticsLocation),
    [REGISTER_URL_HASH]: () => openRegisterModal(analyticsLocation),
  }

  const handleModalLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    link: string,
  ) => {
    Object.keys(handlerMap).forEach((modalHash) => {
      if (link.includes(modalHash)) {
        e.preventDefault()
        handlerMap[modalHash]()
      }
    })
  }

  return handleModalLinkClick
}

export default useModalLinkHandler
