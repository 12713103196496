import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import { getCatalog } from '../../lib/category'
import { parseCatalogRoute } from '../../lib/route'
import { getParentNodes } from '../../lib/catalogFilter'
import { useApi } from '../../services/useApi'
import { CatalogBaseIncludes, QueryParam, TOP_LEVEL_CATEGORIES_QUERY } from '../../utils/constants'
import { Catalog } from '../../utils/types/Product'
import { ParentPointer } from '../../utils/types/misc'

interface CategoryDataStructureReturnType{
  parentCategory: string
  categories: Catalog[]
  categoriesLoading: boolean
  selectedCategory: string
  isSubCategoriesLoading: boolean
  subCategory: Catalog[]
  parentCategoryId: string | undefined
  selectedCategoryId: string | undefined
  parentNodes: ParentPointer[]
  categoryWithFilters: Catalog[]
}

const useCategoryDataStructure = (
  selectedParentCatId?: string,
): CategoryDataStructureReturnType => {
  const { query } = useRouter()
  const { t } = useTranslation('catalog')
  const { getResource } = useApi()

  const [,parentCategory] = query[QueryParam.filters] || []
  const topCategoryId = selectedParentCatId || parentCategory

  const { data: categories, isLoading: categoriesLoading } = getResource<Catalog>('catalog', TOP_LEVEL_CATEGORIES_QUERY)
  const { category: selectedCategory } = parseCatalogRoute(query[QueryParam.filters])

  const parentCategoryId = getCatalog(topCategoryId, categories, t)?.id

  const { data: subCategory, isLoading: isSubCategoriesLoading } = getResource<Catalog>(
    parentCategoryId ? 'catalog' : null,
    `include=${CatalogBaseIncludes.join(',')}&id=${parentCategoryId}&fields[catalog.lists]`,
  )
  const selectedCategoryId = getCatalog(selectedCategory, subCategory, t)?.id

  const parentNodes = getParentNodes(subCategory, selectedCategory)
  const selectedLevelTwoCategory = parentNodes[1]?.id
  const { data: categoryWithFilters } = getResource<Catalog>(
    selectedLevelTwoCategory ? 'catalog' : null,
    `include=attribute,attribute/property&id=${selectedLevelTwoCategory}&fields[catalog.lists]`,
  )

  return {
    parentCategory,
    parentCategoryId,
    categories,
    categoriesLoading,
    selectedCategory,
    isSubCategoriesLoading,
    subCategory,
    selectedCategoryId,
    parentNodes,
    categoryWithFilters,
  }
}

export default useCategoryDataStructure
