import { ComponentType, PropsWithoutRef, forwardRef, useEffect } from 'react'

import { useAuth } from './useAuth'
import { getLocalStorageUserData } from '../utils/localStorage'
import { LOCAL_STORAGE_USER_KEY } from '../utils/constants'

const withRequireAuth = <T extends JSX.IntrinsicAttributes>(WrappedComponent: ComponentType<T>) => {
  const ForwardedRefComponent = forwardRef<unknown, T>((props: T, ref) => (
    <WrappedComponent
      {...props}
      ref={ref}
    />
  ))

  return (props: PropsWithoutRef<T>) => {
    const auth = useAuth()

    useEffect(() => {
      const checkUserData = () => {
        const user = getLocalStorageUserData(LOCAL_STORAGE_USER_KEY)
        if (!user) {
          auth.clearUserData()
        }
      }

      window.addEventListener('storage', checkUserData)

      return () => {
        window.removeEventListener('storage', checkUserData)
      }
    }, [])

    if (typeof window === 'undefined') {
      return null
    }

    if ((auth == null || auth.user === null) && !getLocalStorageUserData(LOCAL_STORAGE_USER_KEY)) {
      return null
    }

    return (
      <ForwardedRefComponent {...props} />
    )
  }
}

export default withRequireAuth
