import { Translate } from 'next-translate'
import { ReactElement, ReactNode, SyntheticEvent } from 'react'
import { ValueType } from 'rsuite/esm/Radio'

import { Locale } from '../../external'
import { ContactContext } from './analytics'

export type NonUndefined<T> = T extends undefined ? never : T

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][]

export const toTypedEntries = <T extends {}>(o: T) => Object.entries(o) as Entries<T>

export const isDefined = <T>(o: T | undefined | null): o is T => !!o

export const hasProps = (obj: string | ReactNode): obj is ReactElement => (
  (obj as ReactElement)?.props !== undefined
)

/**
 * Renmove empty object from union type
 * @param obj any object
 */
export const isNotEmptyObject = <T extends {}>(obj: T | {}): obj is T => (
  Object.keys(obj).length !== 0
)

export interface LocalisationNames {
  [name: string]: {
    [locale: string]: string
  }
}

export type Filter = 'mov' | 'catalog' | 'delivery' | 'industry' | 'color' | 'country'

export interface FiltersType {
  filter: Filter
  activeFilters: ValueType[]
}

export type ColumnProperties = {
  startColumn: number | 'right-most'
  startRow: number
} & ({
  spanX: 'full'
  spanY: number
} | {
  spanX: number
  spanY: 'full'
} | {
  spanX: number
  spanY: number
})

export type Banner = ColumnProperties & {
  type: 'banner-tile-left' | 'banner-tile-center' | 'banner'
  title: string
  minimumCards: number
  content: (t: Translate, node?: ReactNode) => React.ReactElement
  image?: string
  graphics?: string
  className?: string
  bordered?: boolean
}

export interface Link {
  href?: string
  label: string
  text?: boolean
}
export interface HeaderLinks extends Link{
  rightDropDown: boolean
  loggedOut?: boolean
  className?: string
  topDivider?: boolean
  loggedIn?: boolean
  iconName?: string
  translateURL?: boolean
  country?: Locale[]
  contactContext?: ContactContext
}

export type WithFallback<T> = T & {
  fallback: Record<string, any>
}

export interface Photo {
  src: string
  srcSet?: string | string[]
  sizes?: string | string[]
  alt?: string
  key?: string
  isUploader?: boolean
}

export interface ParentPointer {
  id: string
  code: string
  label: string
}

export interface StepActions {
  nextAction: () => void
  backAction?: () => void
}

export type CatalogType = 'products' | 'suppliers'

export type SyntheticEventHandler = (event: SyntheticEvent) => void
