import { IconProps } from '@rsuite/icons/lib/Icon'
import { FC } from 'react'

const ShieldCheckIcon: FC<IconProps> = ({ className }) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 0L1.875 0.9375C1.25 5.3125 1.25 8.4375 1.875 10.3125C3.125 12.8125 5 14.375 7.5 15C10 14.375 11.875 12.8125 13.125 10.3125C13.75 8.4375 13.75 5.3125 13.125 0.9375L7.5 0ZM7.5 14.0302C5.40601 13.4433 3.83458 12.1016 2.74416 9.95403C2.22718 8.33562 2.20526 5.58414 2.70989 1.74878L7.5 0.950432L12.2901 1.74878C12.7947 5.58414 12.7728 8.33562 12.2558 9.95403C11.1654 12.1016 9.59399 13.4433 7.5 14.0302Z"
      fill="#62BFAD"
    />
    <path
      d="M10.0169 5.04474C10.0947 4.96239 10.2245 4.95868 10.3068 5.03645C10.38 5.10558 10.3911 5.21582 10.338 5.29729L10.3151 5.32636L6.82878 9.01777C6.75566 9.09519 6.63762 9.10248 6.556 9.04059L6.52726 9.01414L4.68155 6.96336C4.60578 6.87918 4.61261 6.74951 4.6968 6.67374C4.77163 6.60639 4.8824 6.6043 4.9593 6.66379L4.98642 6.68898L6.68297 8.57426L10.0169 5.04474Z"
      fill="#575757"
      stroke="#62BFAD"
      strokeWidth="0.8"
    />
  </svg>
)

export default ShieldCheckIcon
