import Image from 'next/legacy/image'
import useTranslation from 'next-translate/useTranslation'
import {
  Col,
  Form,
  Input,
  InputGroup,
  InputPicker,
  Loader,
  Notification,
  Row,
  toaster,
  Uploader,
  UploaderProps,
} from 'rsuite'
import LegacyImageIcon from '@rsuite/icons/legacy/Image'
import { Dispatch, MutableRefObject, SetStateAction, useEffect } from 'react'
import { FileType } from 'rsuite/esm/Uploader'
import { UploaderInstance } from 'rsuite/esm/Uploader/Uploader'
import { useRouter } from 'next/router'

import { getSiteCode, useAuth } from '../../../services/useAuth'
import { ALERT_DURATION } from '../../../utils/constants'
import { imageLoader } from '../../../utils/images'
import { cookies } from '../../../utils/fetcher'
import { InputFieldChangeFunction } from '../../../utils/types/Profile'
import {
  PartialSupplier,
  ProductText,
  Supplier,
  SupplierAddress,
} from '../../../utils/types/Product'
import SupplierTextField from './SupplierTextField'
import { COUNTRY_DROPDOWN_DATA } from '../../../utils/forms'
import { getCountryAndLocaleStrings } from '../../../utils/locales'

import styles from '../../../styles/Profile.module.less'

const apiUrl = process.env.NEXT_PUBLIC_API_URL

interface SupplierProfileProps {
  handleFormInputChange: InputFieldChangeFunction
  supplierProfilesData: PartialSupplier
  supplierInfo: Supplier[]
  fetchedSupId: number
  isSupplierInfoLoading: boolean
  fileInfo: string | null
  uploaderRef: MutableRefObject<UploaderInstance | null>
  setImageData: Dispatch<SetStateAction<FileType | null>>
  setFileInfo: Dispatch<SetStateAction<string | null>>
  setDirty: Dispatch<SetStateAction<boolean>>
  handleUploadError?: UploaderProps['onError']
  isUploadingImage: boolean
  fromOnboardingFlow?: boolean
}

const SupplierProfile: React.FC<SupplierProfileProps> = (props) => {
  const {
    handleFormInputChange,
    supplierProfilesData,
    supplierInfo,
    fetchedSupId,
    isSupplierInfoLoading,
    fileInfo,
    uploaderRef,
    setImageData,
    setFileInfo,
    isUploadingImage,
    setDirty,
    handleUploadError,
    fromOnboardingFlow = false,
  } = props

  const { locale: countryAndLocale } = useRouter()
  const { t } = useTranslation('profile')
  const { user, skuPrefix } = useAuth()

  const { country } = getCountryAndLocaleStrings(countryAndLocale)

  const code = getSiteCode(user)
  const supplierId = supplierInfo.length > 0 ? Number(supplierInfo[0].id) : fetchedSupId ?? -1

  // Clean previous tab form changes indicator on component mount
  useEffect(() => {
    setDirty(false)
  }, [])

  const handleInputChange = (
    value: string | ProductText[] | SupplierAddress[] | Partial<SupplierAddress>[],
    fieldKey: string,
  ) => handleFormInputChange(value, fieldKey, 'supplierProfileData')

  const previewFile = (file: File, callback: (s?: string) => void) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      callback(reader.result?.toString())
    }
    if (file) reader.readAsDataURL(file)
  }

  const handleImageChange = (fileList: FileType[]) => {
    const imageFile = fileList.at(-1) // Last file is the latest image selected

    if (!imageFile?.blobFile) return

    setDirty(true)
    setImageData(imageFile)
    previewFile(imageFile.blobFile, (value?: string) => setFileInfo(value || ''))
  }

  const handleCountryChange = (value: string) => {
    const updatedValue: SupplierAddress[] | Partial<SupplierAddress>[] = [{
      ...supplierProfilesData['supplier.address']?.[0],
      'supplier.address.countryid': value,
    }]
    handleInputChange(updatedValue, 'supplier.address')
  }

  const handleUploaderErrorDefault = async () => {
    toaster.push((
      <Notification
        type="error"
        closable
        header={<b>Error</b>}
        duration={ALERT_DURATION}
      >
        {t('There was a problem saving the image')}
      </Notification>
    ), { placement: 'bottomEnd' })
  }

  const mediaData = [{
    type: 'media',
    attributes: {
      'media.siteid': user?.siteid,
      'media.type': 'default',
      'media.label': 'label',
      'media.domain': 'supplier',
      'media.languageid': null, // TODO: Fix hardcoded value
    },
  }]

  const translatedCountryData = COUNTRY_DROPDOWN_DATA.map((countryItem) => ({
    ...countryItem,
    label: t(`countries:${countryItem.label}`),
    value: countryItem.value.toLocaleLowerCase(),
  }))

  const supplierCountryValue = supplierProfilesData['supplier.address']?.[0]?.['supplier.address.countryid']?.toLocaleLowerCase()
    || country.toLowerCase()

  if (isSupplierInfoLoading) {
    return (
      <Loader
        backdrop
        center
      />
    )
  }

  const widthForMdScreen = fromOnboardingFlow ? undefined : 16

  return (
    <div>
      <Row>
        <Col
          xs={24}
          md={widthForMdScreen}
        >
          <Row className={styles['uploader-row-flex']}>
            <Uploader
              fileListVisible={false}
              listType="picture"
              action={`${apiUrl}/api/v1/${code}/upload/supplier/${supplierId}`}
              headers={{
                'X-XSRF-TOKEN': cookies('XSRF-TOKEN'),
              }}
              autoUpload={false}
              onChange={handleImageChange}
              onError={handleUploadError || handleUploaderErrorDefault}
              name="0"
              data={{
                data: JSON.stringify(mediaData),
              }}
              ref={uploaderRef}
              withCredentials
              draggable
            >
              <button type="button">
                {isUploadingImage && (
                <Loader
                  backdrop
                  center
                />
                )}
                {fileInfo ? (
                  <Image
                    loader={imageLoader}
                    src={fileInfo ?? ''}
                    alt="file"
                    className="logo-img"
                    layout="responsive"
                    width={63}
                    height={63}
                  />
                ) : (
                  <LegacyImageIcon className="icon-size-double" />
                )}
              </button>
            </Uploader>
            <Col
              xs={24}
              sm={24}
              md={8}
            >
              <Form.Group key="supplier.label">
                <Form.ControlLabel htmlFor="supplier.label">
                  {t('Supplier name')}
                </Form.ControlLabel>
                <InputGroup inside>
                  <Form.Control
                    name="supplier.label"
                    id="supplier.label"
                    type="text"
                    value={supplierProfilesData['supplier.label']}
                    onChange={(value: string) => handleInputChange(value, 'supplier.label')}
                    readOnly
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={8}
            >
              <Form.Group key="supplier.address">
                <Form.ControlLabel id="supplier.address">
                  {t('Supplier Country')}
                </Form.ControlLabel>
                <InputGroup inside>
                  <Form.Control
                    name="supplier.address"
                    accepter={InputPicker}
                    cleanable={false}
                    aria-labelledby="supplier.address"
                    data={translatedCountryData}
                    value={supplierCountryValue || ''}
                    onChange={handleCountryChange}
                    block
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={8}
            >
              <Form.Group key="sku-prefix">
                <Form.ControlLabel id="sku-prefix">
                  {t('auth:Supplier SKU abbreviation')}
                </Form.ControlLabel>
                <InputGroup inside>
                  <Form.Control
                    name="sku-prefix"
                    accepter={Input}
                    aria-labelledby="sku-prefix"
                    value={skuPrefix}
                    readOnly
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col
          xs={24}
          md={widthForMdScreen}
        >
          <SupplierTextField
            labelText="Company story"
            required={false}
            value={supplierProfilesData.text}
            onChange={(value: ProductText[]) => handleInputChange(value, 'text')}
            placeholder="Tell us about your company story"
          />
        </Col>
      </Row>
    </div>
  )
}

export default SupplierProfile
