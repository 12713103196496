import useMounted from '../../services/useMounted'
import { INTERNAL_TRAFFIC_COOKIE_NAME } from '../../utils/constants'
import { getParsedInternalTrafficCookie, hasCookie } from '../../utils/cookies'

const useInternalTraffic = () => {
  const isMounted = useMounted()
  const isInternalTraffic = isMounted ? hasCookie(INTERNAL_TRAFFIC_COOKIE_NAME) : false
  const { email, encryptedEmail } = isMounted
    ? getParsedInternalTrafficCookie()
    : {
      email: '',
      encryptedEmail: '',
    }

  return { isInternalTraffic, email, encryptedEmail }
}

export default useInternalTraffic
