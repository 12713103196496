import { addItemBuyerCatalog, addToCartBuyerCatalog, createBuyerCatalog, deleteBuyerCatalog, deleteItemBuyerCatalog, partialAddToCartBuyerCatalog, renameBuyerCatalog, singleAddToCartBuyerCatalog, updateItemBuyerCatalog, viewBuyerCatalog } from '../../utils/analytics/buyerCatalog'
import { AddToCartBuyerCatalogEventParams, SingleAddToCartBuyerCatalogEventParams } from '../../utils/types/analytics'
import useEcomEvents from './useEcomEvents'
import useGetAnalyticsPageLocation from './useGetAnalyticsPageLocation'

const useBuyerCatalogEvents = () => {
  // Automatically populate context based on current page location
  const context = useGetAnalyticsPageLocation()
  const { addToCartEvent } = useEcomEvents()

  const viewBuyerCatalogEvent = (catalogName: string, catalogId: string) => {
    viewBuyerCatalog({ catalogName, catalogId, context })
  }

  // Product SKU included if triggered from SPP
  const createBuyerCatalogEvent = (catalogName: string, catalogId: string, productSku?: string) => {
    createBuyerCatalog({ catalogName, catalogId, productSku, context })
  }

  const deleteBuyerCatalogEvent = (catalogName: string, catalogId: string) => {
    deleteBuyerCatalog({ catalogName, catalogId, context })
  }

  const renameBuyerCatalogEvent = (catalogName: string, catalogId: string) => {
    renameBuyerCatalog({ catalogName, catalogId, context })
  }

  const addToCartBuyerCatalogEvent = (params: AddToCartBuyerCatalogEventParams) => {
    const {
      catalogName,
      catalogId,
      productsData,
      isPartial,
      modifiedCart,
    } = params

    // 1. (partial_)add_to_cart_buyer_catalog - Track buyer catalog event hit (no ecommerce data)
    // 2. add_to_cart – Track ecommerce data
    if (isPartial) {
      partialAddToCartBuyerCatalog({ catalogName, catalogId, context })
    } else {
      addToCartBuyerCatalog({ catalogName, catalogId, context })
    }
    addToCartEvent({ productsData, catalogName, context, modifiedCart })
  }

  const singleAddToCartBuyerCatalogEvent = (params: SingleAddToCartBuyerCatalogEventParams) => {
    const {
      productsData,
      catalogName,
      catalogId,
      productSku,
      modifiedCart,
    } = params

    // 1. single_add_to_cart_buyer_catalog - Track buyer catalog event hit (no ecommerce data)
    // 2. add_to_cart – Track ecommerce data
    singleAddToCartBuyerCatalog({ catalogName, catalogId, productSku, context })
    addToCartEvent({ productsData, catalogName, context, modifiedCart })
  }

  const addItemBuyerCatalogEvent = (catalogName: string, catalogId: string, productSku: string) => {
    addItemBuyerCatalog({ catalogName, catalogId, productSku, context })
  }

  const deleteItemBuyerCatalogEvent = (
    catalogName: string, catalogId: string, productSku: string,
  ) => {
    deleteItemBuyerCatalog({ catalogName, catalogId, productSku, context })
  }

  const updateItemBuyerCatalogEvent = (
    catalogName: string, catalogId: string, productSku: string,
  ) => {
    updateItemBuyerCatalog({ catalogName, catalogId, productSku, context })
  }

  return {
    viewBuyerCatalogEvent,
    createBuyerCatalogEvent,
    deleteBuyerCatalogEvent,
    renameBuyerCatalogEvent,
    addItemBuyerCatalogEvent,
    deleteItemBuyerCatalogEvent,
    updateItemBuyerCatalogEvent,
    addToCartBuyerCatalogEvent,
    singleAddToCartBuyerCatalogEvent,
  }
}

export default useBuyerCatalogEvents
