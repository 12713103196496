import { FC } from 'react'
import GlobalIcon from '@rsuite/icons/Global'

import styles from '../../styles/BuyerPortal/ProductIconContainer.module.less'

const ProductIconContainer: FC<{ className?: string }> = ({ className = '' }) => (
  <div className={`${styles['img-cell']} ${className}`}>
    <GlobalIcon
      height={34}
      width={34}
    />
  </div>
)

export default ProductIconContainer
