import { Translate } from 'next-translate'

import { Country } from './constants'

export const getCountryOptions = (t: Translate) => Object.entries(Country)
  .map(([value, label]) => ({
    value,
    label: t(`countries:${label}`),
  }))

export const getCountryRelatedText = (postal?: string, country?: string, city?: string) => {
  const data = [postal, city, country]
  return data.filter((item) => !!item).join(', ')
}

export const extractFirstAndLastName = (fullName: string) => {
  const nameChunks = fullName.trim().split(' ')
  const lastName = nameChunks.length > 1 ? nameChunks.pop() : ''
  const firstName = nameChunks.join(' ')

  return { firstName, lastName }
}
