import { FC, Fragment, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Container } from 'rsuite'

import { generateRouterUrlObject } from '../../../../utils/urls'
import { MegaMenuFormattedData, MegaMenuLinkObjectWithParams } from '../../../../utils/types/strapi'
import MegaMenuCtaColumn from './MegaMenuCtaColumn'
import MobileMenuItem from '../MobileUI/MobileMenuItem'
import MegaMenuLink from './MegaMenuLink'

interface MobileMegaMenuProps {
  columnsData: MegaMenuFormattedData
  handleMenuLinkClick: () => void
}

const MobileMegaMenu: FC<MobileMegaMenuProps> = (props) => {
  const { columnsData, handleMenuLinkClick } = props

  const [selectedColumnContent, setSelectedColumnContent] = useState<number | null>(null)

  const { t } = useTranslation('url')

  const { ctaColumn, ...linksColumnsContent } = columnsData || {}

  const columnKeyToHostUrlMap = {
    promotionalCollectionsColumn: `/${t('products')}`,
    popularBrandsColumn: `/${t('supplier')}`,
  }

  const toggleColumnContentOpenState = (
    columnId: number,
  ) => setSelectedColumnContent((prevId) => (prevId !== columnId ? columnId : null))

  return (
    <Container className="padding-spacer-double">
      {Object.entries(linksColumnsContent).map(([columnKey, columnContent]) => (
        <Fragment key={columnKey}>
          <MobileMenuItem
            isExpandable
            isActive={selectedColumnContent === columnContent.id}
            handleClick={() => toggleColumnContentOpenState(columnContent.id)}
          >
            <b>{columnContent.header}</b>
          </MobileMenuItem>
          {selectedColumnContent === columnContent.id
           && (
           <div className="margin-bottom-spacer-tripple">
             {columnContent.linkData.map(
               // Explicitly typing the  object due to tsc failing to infer the type implicitly
               ({ id, label, link, params }: MegaMenuLinkObjectWithParams) => (
                 <MegaMenuLink
                   key={id}
                   label={label}
                   additionalClassNames="margin-left-spacer-double margin-bottom-spacer-double"
                   externalCallback={handleMenuLinkClick}
                   linkRedirectObjectData={
                    generateRouterUrlObject(
                      link,
                      columnKeyToHostUrlMap[columnKey as keyof typeof linksColumnsContent],
                      params,
                    )
                }
                 />
               ),
             )}
           </div>
           )}
        </Fragment>
      ))}
      <MegaMenuCtaColumn ctaColumnData={ctaColumn} />
    </Container>
  )
}

export default MobileMegaMenu
