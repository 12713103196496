import { isSupplier } from '../../lib/supplier'
import { useApi } from '../../services/useApi'
import { useAuth } from '../../services/useAuth'
import { SESSION_STORAGE_INVITE_COMPANY_KEY } from '../../utils/constants'
import { Customer, CustomerPropertyList, CustomerRelationList } from '../../utils/types/Product'

export interface AddBuyerPropertyPayload {
  'customer.property.value': string
  'customer.property.type': string
}

export interface AddBuyerRelationPayload {
  'customer.lists.domain': string
  'customer.lists.type': string
  'customer.lists.refid': string
}

const useBuyer = () => {
  const { user } = useAuth()
  const { getResource, updateResource, createResource, deleteResource } = useApi()

  const {
    data: catalogRelationList,
    isLoading: isCatalogRelationDataLoading,
    refresh: refreshCatalogRelations,
  } = getResource<CustomerRelationList>(user ? 'customer' : null, '&related=relationships&include=catalog', { isInfinite: false })

  const {
    data: propertyList,
    isLoading: isPropertyDataLoading,
    refresh: refreshProperties,
  } = getResource<CustomerPropertyList>(user ? 'customer' : null, '&related=property', { isInfinite: false })

  const addBuyerRelations = (
    payload: AddBuyerRelationPayload[],
  ) => createResource<CustomerRelationList, Partial<CustomerRelationList>>('customer', payload, '&related=relationships')

  const deleteBuyerCatalogRelation = (
    relationId: string,
  ) => deleteResource('customer', `&id=${user?.id}&related=relationships&include=catalog&relatedid=${relationId}`)

  const addBuyerProperty = async (
    buyerPropertyList: AddBuyerPropertyPayload[],
  ) => createResource<CustomerPropertyList, Partial<CustomerPropertyList>>('customer', buyerPropertyList, '&related=property')

  const deleteBuyerPropertyRelation = async (
    relationId: string,
  ) => deleteResource('customer', `&id=${user?.id}&related=property&relatedid=${relationId}`)

  const updateBuyerProfileData = async (buyerProfileData: Partial<Customer>) => updateResource('customer', buyerProfileData, '')

  const isOnboardingDataLoading = isCatalogRelationDataLoading || isPropertyDataLoading

  const isInvited = typeof window !== 'undefined' && !!sessionStorage.getItem(SESSION_STORAGE_INVITE_COMPANY_KEY)

  const removeInviteSessionStorage = () => {
    sessionStorage.removeItem(SESSION_STORAGE_INVITE_COMPANY_KEY)
  }

  const getBuyerOnboardingCompletedSteps = (): number => {
    if (!user || isSupplier(user)) return -1

    const { firstname, company } = user

    if (!firstname) {
      return 0 // No onboarding step is complete
    }

    const isCatalogSelected = !!catalogRelationList
      .find((relation) => relation['customer.lists.type'] === 'relevant')
    const isCustomCatalogAdded = !!propertyList
      .find((property) => property['customer.property.type'] === 'other-catalog')

    if (!isCatalogSelected && !isCustomCatalogAdded) {
      return 1 // Only Buyer profile(first) step is completed
    }

    const isAnyIndustrySelected = !!propertyList.find((property) => property['customer.property.type'] === 'industry')

    if (!isAnyIndustrySelected || !company || isInvited) {
      return 2 // Third step isn't completed
    }

    return 3
  }

  return {
    updateBuyerProfileData,
    addBuyerProperty,
    addBuyerRelations,
    deleteBuyerCatalogRelation,
    deleteBuyerPropertyRelation,
    getBuyerOnboardingCompletedSteps,
    user,
    isInvited,
    removeInviteSessionStorage,
    isOnboardingDataLoading,
    refreshCatalogRelations,
    refreshProperties,
    catalogRelationList,
    propertyList,
  }
}

export default useBuyer
