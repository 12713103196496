import { forwardRef } from 'react'
import { Input } from 'rsuite'

// This is used on the rsuite V5 Form.Control accepter for a textarea input as the textarea element
const Textarea = forwardRef((InputProps: any, ref) => (
  <Input
    {...InputProps}
    as="textarea"
    ref={ref}
  />
))

export default Textarea
