import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { translateBaseUrl, translateSingleProductUrl } from '../../../../../lib/urls'
import { LOCAL_STORAGE_COUNTRY_KEY } from '../../../../../utils/constants'
import { generateCountryAndLocaleString, getCountryAndLocaleStrings } from '../../../../../utils/locales'
import { CountryAndLocale, Locale } from '../../../../../external'
import useMounted from '../../../../../services/useMounted'

const useReplaceUrlWithCorrectLocale = () => {
  const fullRouter = useRouter() // Full router passed as param elsewhere
  const isMounted = useMounted()
  const { t } = useTranslation('common')

  const {
    asPath,
    replace,
    locale: countryAndLocale,
    defaultLocale: defaultCountryAndLocale,
  } = fullRouter

  const {
    country,
  } = getCountryAndLocaleStrings(countryAndLocale || defaultCountryAndLocale)

  // Needs isMounted to ensure localStorage is defined
  const storedFullLocale = isMounted
    ? localStorage.getItem(LOCAL_STORAGE_COUNTRY_KEY) || countryAndLocale
    : countryAndLocale

  const replaceUrlWithCorrectLocale = async (targetLanguage: Locale, targetCountry = country) => {
    if (!isMounted) {
      return
    }

    const targetCountryAndLocale = generateCountryAndLocaleString(targetCountry, targetLanguage)
    const translatedUrl = await translateBaseUrl(fullRouter, targetCountryAndLocale)

    const urlHash = asPath.split('#').at(1) || ''
    const urlWithoutHashes = translatedUrl.split('#')[0] || ''
    const urlWithoutQueryParams = urlWithoutHashes.split('?')[0] || ''

    // If single product page, get translated url
    const isSingleProductPage = asPath.includes(`/${t('url:product')}/`)
    const translatedPathname = isSingleProductPage
      ? await translateSingleProductUrl(asPath, storedFullLocale as CountryAndLocale)
      : urlWithoutQueryParams

    // Don't use Next.js router query object as a replace() parameter,
    // because it also contains Next.js dynamic routes.
    // Use URL interface instead:
    const urlObject = new URL(window.location.href)
    const queryParams = Object.fromEntries(urlObject.searchParams)

    replace(
      { pathname: translatedPathname, hash: urlHash, query: queryParams },
      undefined,
      { locale: generateCountryAndLocaleString(targetCountry, targetLanguage) },
    )
  }

  return replaceUrlWithCorrectLocale
}

export default useReplaceUrlWithCorrectLocale
