import { IconProps } from '@rsuite/icons/lib/Icon'
import React, { FC } from 'react'

const DiscountIcon: FC<IconProps> = ({ className }) => (
  <svg
    width={15}
    height={16}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle
      cx="15"
      cy="15"
      r="14.6"
      fill="#78C406"
      stroke="#78C406"
      strokeWidth="0.8"
    />
    <rect
      width="15.4901"
      height="1.3418"
      rx="0.6709"
      transform="matrix(0.702646 -0.71154 0.702646 0.71154 9.16797 20.3357)"
      fill="white"
    />
    <path
      d="M20.6816 18.8532C20.6816 19.9812 19.7803 20.8848 18.6816 20.8848C17.583 20.8848 16.6816 19.9812 16.6816 18.8532C16.6816 17.7252 17.583 16.8215 18.6816 16.8215C19.7803 16.8215 20.6816 17.7252 20.6816 18.8532Z"
      stroke="white"
    />
    <path
      d="M13.668 11.2613C13.668 12.3893 12.7666 13.2929 11.668 13.2929C10.5693 13.2929 9.66797 12.3893 9.66797 11.2613C9.66797 10.1333 10.5693 9.22961 11.668 9.22961C12.7666 9.22961 13.668 10.1333 13.668 11.2613Z"
      stroke="white"
    />
  </svg>
)

export default DiscountIcon
