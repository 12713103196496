import { isProductInclude } from '../../../utils/types/guards/Order'
import { OrderInclude } from '../../../utils/types/Order'

export const getProductIdsInCart = (cartInclude: OrderInclude[]) => [...new Set(
  cartInclude
    ?.filter(isProductInclude)
    ?.map((item) => (item.attributes['order.product.type'] === 'select'
      ? item.attributes['order.product.parentproductid']
      : item.attributes['order.product.productid'])) || [],
)]

export const filterByImportedProduct = (cartInclude: OrderInclude[]) => cartInclude
  ?.filter(isProductInclude)
  .filter((include) => include.attributes['order.product.type'] === 'external')

export const getImportedProductIdsInCart = (cartInclude: OrderInclude[]) => [...new Set(
  filterByImportedProduct(cartInclude)
    ?.map((item) => (item.attributes['order.product.type'] === 'select'
      ? item.attributes['order.product.parentproductid']
      : item.attributes['order.product.productid'])) || [],
)]
