import { FC, ReactNode } from 'react'
import { Tooltip, Whisper } from 'rsuite'

import LightningIcon, { LightningIconSize } from './Icons/LightningIcon'
import { Placement } from '../utils/types/rsuite'

interface LightningTooltipProps {
  content: ReactNode
  placement?: Placement
  className?: string
  size?: LightningIconSize
}

const LightningTooltip: FC<LightningTooltipProps> = (props) => {
  const { content, size = 'xs', placement = 'top', className } = props

  return (
    <Whisper
      placement={placement}
      speaker={(
        <Tooltip>
          {content}
        </Tooltip>
      )}
    >
      <span className={className}>
        <LightningIcon size={size} />
      </span>
    </Whisper>
  )
}

export default LightningTooltip
