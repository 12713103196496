export const removeTrailingDecimalZeros = (s: string) => {
  const num = s.split(',')[1] ?? '' // should be used after formatPriceNumber as we split by ,
  const decimals = Number(num.search(/[1-9]/)) + 1 // finding index of first non zero number
  if (decimals === 0) { // only has zeros trailing comma, only give integer part back
    return s.split(',')[0]
  }
  if (decimals > 0) {
    return s.replace(/\.?0+$/, '') // remove trailing 0s
  }
  return s
}

export const isNumber = (n: number | string) => !(!n || Number.isNaN(+n))
export const getSafeNumber = (n: number | string): number => (+(isNumber(n) ? n : 0))

export const formatGenericIntegerNumber = (n: number | string) => getSafeNumber(n)
  .toString()
  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1\xA0') // space k separator, '$1.' as point

export const formatPriceNumber = (n: number | string) => {
  if (Number.isNaN(n)) {
    return '--,--'
  }
  let decimals = 2
  const numberValue = typeof n === 'string' ? Number(parseFloat(n).toFixed(5)) : Number(n.toFixed(5))
  // if the number (or number after rounding with toFixed, e.g 0.995 -> 0.1, to catch edge cases)
  // will have a trailing zero, then update the decimals value for later formatting
  if (numberValue < 0.10 && Number(numberValue.toFixed(decimals)) < 0.10) {
    const num = numberValue.toString().split('.')[1] ?? ''
    if (num) decimals = Number(num.search(/[1-9]/)) + 2 // finding index of first non zero number
  }
  let formatted = getSafeNumber(numberValue)
    .toFixed(decimals)
    .replace('.', ',') // replace decimal point character with ,
    .replace(/(\d)(?=(\d{3})+(?!\d)+(?=,))/g, '$1\xA0') // space k separator, '$1.' as point
    .toString()
  if (decimals > 2) {
    formatted = formatted.replace(/\.?0+$/, '') // remove trailing 0s
  }
  return formatted
}

export const formatToPrecision = (n: number | string, precision = 2): string => (
  formatPriceNumber(Number(n).toPrecision(precision))
)
export const roundToTwoDecimals = (n: number | string) => Math.round(Number(n) * 1e2) / 1e2

export const parseCommaDecimalsToFloat = (numberToParse: string) => parseFloat(numberToParse.replace(',', '.'))

// This function will convert to value like 1.6666 to 1.6
export const convertToOneDecimal = (n: number) => Math.floor(n * 10) / 10
