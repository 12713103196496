import { NextSeoProps } from 'next-seo'
import { Translate } from 'next-translate'
import useTranslation from 'next-translate/useTranslation'

import { CountryAndLocale, Locale } from './external'
import useUrls from './services/useUrls'
import { getCurrentUrl } from './utils/urls'
import { getCountryAndLocaleStrings } from './utils/locales'
import { LOCALE_HREFLANG_MAP } from './utils/constants'

interface SEOParams {
  title?: string
  path?: string
  description?: string
  imageUrl?: string
  imageAlt?: string
  hreflangUrls?: Record<string, string>
  canonicalUrl?: string
}

const getMetaImageUrl = (locale: Locale) => `${process.env.NEXT_PUBLIC_STATIC_URL}/droppe-meta-${locale}.jpg`

const defaultImage: Record<Locale, string> = {
  en: getMetaImageUrl('en'),
  fi: getMetaImageUrl('fi'),
  sv: getMetaImageUrl('sv'),
  de: getMetaImageUrl('de'),
}

const imageWidth: number = 800
const imageHeight: number = 600

export const getSEOProps = () => {
  const { t, lang: countryAndLang } = useTranslation('meta')
  const { urlT } = useUrls()

  const { locale: lang } = getCountryAndLocaleStrings(countryAndLang)

  return (params: SEOParams): NextSeoProps => {
    const {
      title,
      path = '',
      description = t('meta:description'),
      imageUrl = defaultImage[lang],
      imageAlt = t('meta:imageAlt'),
      hreflangUrls = {},
      canonicalUrl,
    } = params

    return ({
      title,
      description,
      openGraph: {
        locale: LOCALE_HREFLANG_MAP[countryAndLang as CountryAndLocale],
        url: getCurrentUrl(urlT(path), countryAndLang as CountryAndLocale),
        site_name: 'Droppe',
        title: `${title ? `${title} - ` : ''}${t('titlePostfix')}`,
        description,
        images: [
          {
            url: imageUrl,
            width: imageWidth,
            height: imageHeight,
            alt: imageAlt,
          },
        ],
      },
      canonical: canonicalUrl,
      languageAlternates: (() => {
        const hreflangKeys = Object.keys(hreflangUrls)

        const isXDefaultUndefined = !hreflangKeys.includes('x-default')

        if (isXDefaultUndefined) {
          return [
            ...hreflangKeys.map((hreflangKey) => ({
              hrefLang: LOCALE_HREFLANG_MAP[hreflangKey as CountryAndLocale],
              href: hreflangUrls[hreflangKey],
            })),
            {
              hrefLang: 'x-default',
              href: hreflangUrls['fi-en'],
            },
          ]
        }

        return hreflangKeys.map((hreflangKey) => {
          if (hreflangKey === 'x-default') {
            return ({
              hrefLang: 'x-default',
              href: hreflangUrls[hreflangKey],
            })
          }

          return ({
            hrefLang: LOCALE_HREFLANG_MAP[hreflangKey as CountryAndLocale] as string,
            href: hreflangUrls[hreflangKey],
          })
        })
      })(),
    })
  }
}

const createDefaultSEO = (t: Translate, lang: Locale) => ({
  titleTemplate: '%s - Droppe',
  defaultTitle: `${t('meta:brand')} | ${t('meta:oneliner')}`,
  description: t('meta:description'),
  openGraph: {
    type: 'website',
    locale: lang,
    url: getCurrentUrl(),
    site_name: 'Droppe',
    title: `${t('meta:brand')} | ${t('meta:oneliner')}`,
    description: t('meta:description'),
    images: [
      {
        url: defaultImage[lang],
        width: imageWidth,
        height: imageHeight,
        alt: t('meta:imageAlt'),
      },
    ],
  },
  twitter: {
    handle: '@droppehq',
    site: '@droppehq',
    cardType: 'summary_large_image',
  },
  additionalLinkTags: [
    {
      rel: 'icon',
      href: '/meta/favicon.ico',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/meta/favicon-16x16.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/meta/favicon-32x32.png',
    },
    {
      rel: 'apple-touch-icon',
      href: '/meta/apple-touch-icon.png',
      sizes: '180x180',
    },
    {
      rel: 'manifest',
      href: '/meta/site.webmanifest',
    },
  ],
  additionalMetaTags: [
    {
      name: 'X-UA-Compatible',
      content: 'IE=edge',
    },
    {
      name: 'viewport',
      content: 'width=device-width,initial-scale=1,maximum-scale=1,minimum-scale=1',
    },
    {
      name: 'msapplication-TileColor',
      content: '#da532c',
    },
    {
      name: 'theme-color',
      content: '#ffffff',
    },
  ],
})

export default createDefaultSEO
