import { forwardRef, useState } from 'react'
import { Animation, FlexboxGrid } from 'rsuite'
import Link from 'next/link'
import { useRouter } from 'next/router'

import SafeTranslate from '../../common/SafeTranslate'
import useIsOnMobile from '../../../services/useIsOnMobile'
import useUrls from '../../../services/useUrls'
import topBannerContent from '../../../utils/topBannerContent.json'
import { Country, ScreenSize, TOTAL_SATISFIED_CUSTOMERS } from '../../../utils/constants'
import { getCountryAndLocaleStrings } from '../../../utils/locales'

import styles from '../../../styles/NotificationHeader.module.less'
import buttonStyles from '../../../styles/CustomButtons.module.less'

const ANIMATION_DURATION = 1000
const ENTER_TIMEOUT_DURATION = 4000
const EXIT_TIMEOUT_DURATION = 1000

const NotificationHeader = forwardRef<HTMLDivElement>((_, ref) => {
  const [showAnimation, setShowAnimation] = useState(true)
  const [contentIndex, setContentIndex] = useState(0)

  const { urlT } = useUrls()
  const isOnMobile = useIsOnMobile()
  const isOnTablet = useIsOnMobile(ScreenSize.md)
  const { locale: countryLandString } = useRouter()
  const { country } = getCountryAndLocaleStrings(countryLandString)

  const currentContent = topBannerContent[contentIndex]

  const getLinkComponent = (type: string | undefined) => (
    <Link
      href={urlT(type === 'catalogButton' ? '/my-catalogs' : '/products')}
      className={`${buttonStyles['link-button']} highlight-text font-semibold`}
    />
  )

  const handleAnimationEnter = () => setTimeout(() => {
    // After a brief timeout, set the show state to false to reset the animation trigger
    setShowAnimation(false)
  }, ENTER_TIMEOUT_DURATION)

  const handleAnimationExit = () => setTimeout(() => {
    // To trigger the animation again, set the show to true, after a small delay
    setShowAnimation(true)
    setContentIndex((prevIndex) => {
      // If we reached the last index, reset back to 0
      if (prevIndex === topBannerContent.length - 1) {
        return 0
      }
      // Increment the index to present the next content
      return prevIndex + 1
    })
  }, EXIT_TIMEOUT_DURATION)

  return (
    <div
      data-testid="notification-header"
      ref={ref}
      className={`${styles['notification-header-container']} hide-on-print`}
    >
      <FlexboxGrid
        justify="space-between"
        className={`${styles['inner-container']} max-width-lg`}
      >
        <Animation.Slide
          transitionAppear
          placement={!showAnimation ? 'top' : 'bottom'}
          timeout={ANIMATION_DURATION}
          in={showAnimation}
          onEntered={handleAnimationEnter}
          onExiting={handleAnimationExit}
        >
          {(slideProps, slideRef) => (
            <div
              {...slideProps}
              ref={slideRef}
            >
              <SafeTranslate
                i18nKey={`common:${currentContent.key}`}
                components={{
                  p: <p />,
                  b: <b />,
                  link: getLinkComponent(currentContent.linkType),
                }}
                values={{
                  country: Country[country],
                }}
              />
            </div>
          )}
        </Animation.Slide>
        {!isOnMobile && !isOnTablet && (
          <div>
            <img
              src="/icons/shield-icon.svg"
              alt="shield-icon"
              className={`${styles['icon-vertical-alignment']} margin-right-spacer-half`}
            />
            <SafeTranslate
              i18nKey="common:Over {{count}} satisfied customers"
              components={{ b: <b /> }}
              values={{ count: TOTAL_SATISFIED_CUSTOMERS }}
            />
          </div>
        )}
      </FlexboxGrid>
    </div>
  )
})

export default NotificationHeader
