import { Translate } from 'next-translate'

export const hasSearchTermSuffix = (searchedValue: string, suffixes: string[]) => (
  suffixes.some((suffix) => searchedValue.endsWith(suffix))
)

export const formatSearchTerm = (val: string, t: Translate) => {
  let searchedValue = val
  searchedValue = searchedValue.replace(/[@&<>*~"'`,.-]/g, ' ').trim()
  const inputStringArray = searchedValue.split(' ')
  /* The below statement is checking if end term is one of the mentioned
    * than remove that as well as the before term if it's strictly a number
    */
  const suffixArray = [
    t('products:pair_one'),
    t('products:pair_other'),
    t('products:pc_one'),
    t('products:pc_other'),
    t('products:carton_one'),
    t('products:carton_other'),
    'Stk', // inside local de there is '.' which will not match as . is stripped of, so manually added Stk without '.'
  ]

  if (hasSearchTermSuffix(searchedValue, suffixArray)) {
    if (inputStringArray.length > 2) {
      const isStringBeforeUnit = Number.isNaN(parseInt(
        inputStringArray[inputStringArray.length - 2], 10,
      ))
      const replacementString = `${!isStringBeforeUnit ? inputStringArray[inputStringArray.length - 2] : ''} ${inputStringArray[inputStringArray.length - 1]}`
      searchedValue = searchedValue.replace(replacementString, '')
    }
  }

  return searchedValue.trim()
}
