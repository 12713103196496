import { FC, KeyboardEvent, useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { SearchBoxProps, useConfigure, useSearchBox } from 'react-instantsearch'
import useTranslation from 'next-translate/useTranslation'
import { Input, InputGroup } from 'rsuite'
import LegacySearchIcon from '@rsuite/icons/legacy/Search'

import { QueryParam } from '../../utils/constants'
import { getCountryAndLocaleStrings } from '../../utils/locales'

import styles from '../../styles/Search.module.less'

const SearchBoxRsuite: FC<
SearchBoxProps & { gotoSearchPage: (query: string) => void }
> = (props) => {
  const { gotoSearchPage, ...defaultProps } = props
  const [input, setInputs] = useState('')
  const { query: { [QueryParam.search]: searchQuery }, locale: countryAndLocale } = useRouter()
  const { country } = getCountryAndLocaleStrings(countryAndLocale)
  const { t } = useTranslation('common')

  const {
    query,
    refine,
    clear,
  } = useSearchBox(defaultProps)
  useConfigure({
    facetsRefinements: {
      'country-availability': [country.toUpperCase()],
    },
  })

  useEffect(() => {
    // Fill input if url contains search query
    if (searchQuery) {
      const searchQueryText = [searchQuery].flat().join()
      refine(searchQueryText)
      setInputs(searchQueryText)
    }
  }, [searchQuery])

  const handleEnterKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key !== 'Enter') return

    gotoSearchPage(query)
  }, [gotoSearchPage, query])

  return (
    <InputGroup
      inside
      className={`${styles.input} margin-bottom-spacer-double`}
    >
      <Input
        autoFocus
        value={input}
        onChange={(value) => {
          setInputs(value)
          refine(value)
        }}
        placeholder={t('Search for product, category or supplier')}
        onKeyDown={handleEnterKeyPress}
      />
      { query && (
        // Copied markup from rs-picker, inherits styles from global scope
        <InputGroup.Addon>
          <button
            className="rs-picker-toggle-clean"
            type="button"
            tabIndex={-1}
            onClick={() => {
              setInputs('')
              clear()
            }}
            data-testid="clear-btn"
          >
            ✕
          </button>
        </InputGroup.Addon>
      ) }
      <InputGroup.Addon className={styles['search-icon']}>
        <LegacySearchIcon
          role="button"
          data-testid="search-icon"
          onClick={() => gotoSearchPage(query)}
        />
      </InputGroup.Addon>
    </InputGroup>
  )
}

export default SearchBoxRsuite
