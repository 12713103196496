import useSWR from 'swr'
import { useRouter } from 'next/router'

import { StrapiFooterResp } from '../../utils/types/strapi'
import { strapiFetcher } from '../../utils/fetcher'
import { buildStrapiUrl } from '../../utils/strapi'
import { getCountryAndLocaleStrings } from '../../utils/locales'

const useFooterData = () => {
  const { locale: countryAndLocaleString } = useRouter()
  const { locale, country } = getCountryAndLocaleStrings(countryAndLocaleString)

  const apiUrl = buildStrapiUrl({
    endpoint: 'footers',
    locale,
    queryParams: `&filters[country][countryCode][$eqi]=${country}&populate[footerColumns][populate][0]=footerColumnLinks&populate[footerBottom][populate][0]=footerBottomLinks&populate[footerImages][populate]=*`,
  })

  const { data: strapiResp, error } = useSWR<StrapiFooterResp>(apiUrl, strapiFetcher)

  if (error) {
    console.error(error)
  }

  if (!strapiResp?.data) {
    return {}
  }

  const footerDataAttributes = strapiResp?.data?.[0]?.attributes || {}

  const {
    footerBottom: footerBottomData,
    footerColumns: footerColumnData,
    footerImages: footerImageData,
  } = footerDataAttributes

  return { footerBottomData, footerColumnData, footerImageData }
}

export default useFooterData
