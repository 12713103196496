import { FC } from 'react'

import FinnishFlag from '../components/Icons/Flags/FinnishFlag'
import GermanFlag from '../components/Icons/Flags/GermanFlag'
import SwedishFlag from '../components/Icons/Flags/SwedishFlag'
import countryData from './countryData'

export interface ContactFormValues {
  phoneNumber: string
  workEmail: string
  estimatedAnnualVolume: string
  companyName: string
}

export interface DropdownCountryItem {
  label: string
  value: string
  flag?: FC
  phonePrefix?: string
}

export const COUNTRY_DROPDOWN_DATA: DropdownCountryItem[] = [
  {
    label: 'Finland',
    flag: FinnishFlag,
    value: 'fi',
    phonePrefix: '+358',
  },
  {
    label: 'Sweden',
    flag: SwedishFlag,
    value: 'sv',
    phonePrefix: '+46',
  },
  {
    label: 'Germany',
    flag: GermanFlag,
    value: 'de',
    phonePrefix: '+49',
  },
  ...countryData,
]
