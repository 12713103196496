// All country name excepet Finland, Sweden , Germany
// The data is generated from https://restcountries.com/ using
// https://gist.github.com/thatanjan/c50e7ab526d6b9c1a6a59b16d20c0765

const data = [
  {
    label: 'Afghanistan',
    value: 'AF',
    phonePrefix: '+93',
  },
  {
    label: 'Albania',
    value: 'AL',
    phonePrefix: '+355',
  },
  {
    label: 'Algeria',
    value: 'DZ',
    phonePrefix: '+213',
  },
  {
    label: 'Andorra',
    value: 'AD',
    phonePrefix: '+376',
  },
  {
    label: 'Angola',
    value: 'AO',
    phonePrefix: '+244',
  },
  {
    label: 'Antigua and Barbuda',
    value: 'AG',
    phonePrefix: '+1268',
  },
  {
    label: 'Argentina',
    value: 'AR',
    phonePrefix: '+54',
  },
  {
    label: 'Armenia',
    value: 'AM',
    phonePrefix: '+374',
  },
  {
    label: 'Australia',
    value: 'AU',
    phonePrefix: '+61',
  },
  {
    label: 'Austria',
    value: 'AT',
    phonePrefix: '+43',
  },
  {
    label: 'Azerbaijan',
    value: 'AZ',
    phonePrefix: '+994',
  },
  {
    label: 'Bahamas',
    value: 'BS',
    phonePrefix: '+1242',
  },
  {
    label: 'Bahrain',
    value: 'BH',
    phonePrefix: '+973',
  },
  {
    label: 'Bangladesh',
    value: 'BD',
    phonePrefix: '+880',
  },
  {
    label: 'Barbados',
    value: 'BB',
    phonePrefix: '+1246',
  },
  {
    label: 'Belarus',
    value: 'BY',
    phonePrefix: '+375',
  },
  {
    label: 'Belgium',
    value: 'BE',
    phonePrefix: '+32',
  },
  {
    label: 'Belize',
    value: 'BZ',
    phonePrefix: '+501',
  },
  {
    label: 'Benin',
    value: 'BJ',
    phonePrefix: '+229',
  },
  {
    label: 'Bhutan',
    value: 'BT',
    phonePrefix: '+975',
  },
  {
    label: 'Bolivia',
    value: 'BO',
    phonePrefix: '+591',
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'BA',
    phonePrefix: '+387',
  },
  {
    label: 'Botswana',
    value: 'BW',
    phonePrefix: '+267',
  },
  {
    label: 'Brazil',
    value: 'BR',
    phonePrefix: '+55',
  },
  {
    label: 'Brunei',
    value: 'BN',
    phonePrefix: '+673',
  },
  {
    label: 'Bulgaria',
    value: 'BG',
    phonePrefix: '+359',
  },
  {
    label: 'Burkina Faso',
    value: 'BF',
    phonePrefix: '+226',
  },
  {
    label: 'Burundi',
    value: 'BI',
    phonePrefix: '+257',
  },
  {
    label: 'Cambodia',
    value: 'KH',
    phonePrefix: '+855',
  },
  {
    label: 'Cameroon',
    value: 'CM',
    phonePrefix: '+237',
  },
  {
    label: 'Canada',
    value: 'CA',
    phonePrefix: '+1',
  },
  {
    label: 'Cape Verde',
    value: 'CV',
    phonePrefix: '+238',
  },
  {
    label: 'Central African Republic',
    value: 'CF',
    phonePrefix: '+236',
  },
  {
    label: 'Chad',
    value: 'TD',
    phonePrefix: '+235',
  },
  {
    label: 'Chile',
    value: 'CL',
    phonePrefix: '+56',
  },
  {
    label: 'China',
    value: 'CN',
    phonePrefix: '+86',
  },
  {
    label: 'Colombia',
    value: 'CO',
    phonePrefix: '+57',
  },
  {
    label: 'Comoros',
    value: 'KM',
    phonePrefix: '+269',
  },
  {
    label: 'Costa Rica',
    value: 'CR',
    phonePrefix: '+506',
  },
  {
    label: 'Croatia',
    value: 'HR',
    phonePrefix: '+385',
  },
  {
    label: 'Cuba',
    value: 'CU',
    phonePrefix: '+53',
  },
  {
    label: 'Cyprus',
    value: 'CY',
    phonePrefix: '+357',
  },
  {
    label: 'Czechia',
    value: 'CZ',
    phonePrefix: '+420',
  },
  {
    label: 'Denmark',
    value: 'DK',
    phonePrefix: '+45',
  },
  {
    label: 'Djibouti',
    value: 'DJ',
    phonePrefix: '+253',
  },
  {
    label: 'Dominica',
    value: 'DM',
    phonePrefix: '+1767',
  },
  {
    label: 'Dominican Republic',
    value: 'DO',
    phonePrefix: '+1809,829,849',
  },
  {
    label: 'DR Congo',
    value: 'CD',
    phonePrefix: '+243',
  },
  {
    label: 'Ecuador',
    value: 'EC',
    phonePrefix: '+593',
  },
  {
    label: 'Egypt',
    value: 'EG',
    phonePrefix: '+20',
  },
  {
    label: 'Equatorial Guinea',
    value: 'GQ',
    phonePrefix: '+240',
  },
  {
    label: 'Eritrea',
    value: 'ER',
    phonePrefix: '+291',
  },
  {
    label: 'Estonia',
    value: 'EE',
    phonePrefix: '+372',
  },
  {
    label: 'Eswatini',
    value: 'SZ',
    phonePrefix: '+268',
  },
  {
    label: 'Ethiopia',
    value: 'ET',
    phonePrefix: '+251',
  },
  {
    label: 'Fiji',
    value: 'FJ',
    phonePrefix: '+679',
  },
  {
    label: 'France',
    value: 'FR',
    phonePrefix: '+33',
  },
  {
    label: 'Gabon',
    value: 'GA',
    phonePrefix: '+241',
  },
  {
    label: 'Gambia',
    value: 'GM',
    phonePrefix: '+220',
  },
  {
    label: 'Georgia',
    value: 'GE',
    phonePrefix: '+995',
  },
  {
    label: 'Ghana',
    value: 'GH',
    phonePrefix: '+233',
  },
  {
    label: 'Greece',
    value: 'GR',
    phonePrefix: '+30',
  },
  {
    label: 'Grenada',
    value: 'GD',
    phonePrefix: '+1473',
  },
  {
    label: 'Guatemala',
    value: 'GT',
    phonePrefix: '+502',
  },
  {
    label: 'Guinea',
    value: 'GN',
    phonePrefix: '+224',
  },
  {
    label: 'Guinea-Bissau',
    value: 'GW',
    phonePrefix: '+245',
  },
  {
    label: 'Guyana',
    value: 'GY',
    phonePrefix: '+592',
  },
  {
    label: 'Haiti',
    value: 'HT',
    phonePrefix: '+509',
  },
  {
    label: 'Honduras',
    value: 'HN',
    phonePrefix: '+504',
  },
  {
    label: 'Hungary',
    value: 'HU',
    phonePrefix: '+36',
  },
  {
    label: 'Iceland',
    value: 'IS',
    phonePrefix: '+354',
  },
  {
    label: 'India',
    value: 'IN',
    phonePrefix: '+91',
  },
  {
    label: 'Indonesia',
    value: 'ID',
    phonePrefix: '+62',
  },
  {
    label: 'Iran',
    value: 'IR',
    phonePrefix: '+98',
  },
  {
    label: 'Iraq',
    value: 'IQ',
    phonePrefix: '+964',
  },
  {
    label: 'Ireland',
    value: 'IE',
    phonePrefix: '+353',
  },
  {
    label: 'Israel',
    value: 'IL',
    phonePrefix: '+972',
  },
  {
    label: 'Italy',
    value: 'IT',
    phonePrefix: '+39',
  },
  {
    label: 'Ivory Coast',
    value: 'CI',
    phonePrefix: '+225',
  },
  {
    label: 'Jamaica',
    value: 'JM',
    phonePrefix: '+1876',
  },
  {
    label: 'Japan',
    value: 'JP',
    phonePrefix: '+81',
  },
  {
    label: 'Jordan',
    value: 'JO',
    phonePrefix: '+962',
  },
  {
    label: 'Kazakhstan',
    value: 'KZ',
    phonePrefix: '+76,7',
  },
  {
    label: 'Kenya',
    value: 'KE',
    phonePrefix: '+254',
  },
  {
    label: 'Kiribati',
    value: 'KI',
    phonePrefix: '+686',
  },
  {
    label: 'Kuwait',
    value: 'KW',
    phonePrefix: '+965',
  },
  {
    label: 'Kyrgyzstan',
    value: 'KG',
    phonePrefix: '+996',
  },
  {
    label: 'Laos',
    value: 'LA',
    phonePrefix: '+856',
  },
  {
    label: 'Latvia',
    value: 'LV',
    phonePrefix: '+371',
  },
  {
    label: 'Lebanon',
    value: 'LB',
    phonePrefix: '+961',
  },
  {
    label: 'Lesotho',
    value: 'LS',
    phonePrefix: '+266',
  },
  {
    label: 'Liberia',
    value: 'LR',
    phonePrefix: '+231',
  },
  {
    label: 'Libya',
    value: 'LY',
    phonePrefix: '+218',
  },
  {
    label: 'Liechtenstein',
    value: 'LI',
    phonePrefix: '+423',
  },
  {
    label: 'Lithuania',
    value: 'LT',
    phonePrefix: '+370',
  },
  {
    label: 'Luxembourg',
    value: 'LU',
    phonePrefix: '+352',
  },
  {
    label: 'Madagascar',
    value: 'MG',
    phonePrefix: '+261',
  },
  {
    label: 'Malawi',
    value: 'MW',
    phonePrefix: '+265',
  },
  {
    label: 'Malaysia',
    value: 'MY',
    phonePrefix: '+60',
  },
  {
    label: 'Maldives',
    value: 'MV',
    phonePrefix: '+960',
  },
  {
    label: 'Mali',
    value: 'ML',
    phonePrefix: '+223',
  },
  {
    label: 'Malta',
    value: 'MT',
    phonePrefix: '+356',
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
    phonePrefix: '+692',
  },
  {
    label: 'Mauritania',
    value: 'MR',
    phonePrefix: '+222',
  },
  {
    label: 'Mauritius',
    value: 'MU',
    phonePrefix: '+230',
  },
  {
    label: 'Mexico',
    value: 'MX',
    phonePrefix: '+52',
  },
  {
    label: 'Micronesia',
    value: 'FM',
    phonePrefix: '+691',
  },
  {
    label: 'Moldova',
    value: 'MD',
    phonePrefix: '+373',
  },
  {
    label: 'Monaco',
    value: 'MC',
    phonePrefix: '+377',
  },
  {
    label: 'Mongolia',
    value: 'MN',
    phonePrefix: '+976',
  },
  {
    label: 'Montenegro',
    value: 'ME',
    phonePrefix: '+382',
  },
  {
    label: 'Morocco',
    value: 'MA',
    phonePrefix: '+212',
  },
  {
    label: 'Mozambique',
    value: 'MZ',
    phonePrefix: '+258',
  },
  {
    label: 'Myanmar',
    value: 'MM',
    phonePrefix: '+95',
  },
  {
    label: 'Namibia',
    value: 'NA',
    phonePrefix: '+264',
  },
  {
    label: 'Nauru',
    value: 'NR',
    phonePrefix: '+674',
  },
  {
    label: 'Nepal',
    value: 'NP',
    phonePrefix: '+977',
  },
  {
    label: 'Netherlands',
    value: 'NL',
    phonePrefix: '+31',
  },
  {
    label: 'New Zealand',
    value: 'NZ',
    phonePrefix: '+64',
  },
  {
    label: 'Nicaragua',
    value: 'NI',
    phonePrefix: '+505',
  },
  {
    label: 'Niger',
    value: 'NE',
    phonePrefix: '+227',
  },
  {
    label: 'Nigeria',
    value: 'NG',
    phonePrefix: '+234',
  },
  {
    label: 'North Korea',
    value: 'KP',
    phonePrefix: '+850',
  },
  {
    label: 'North Macedonia',
    value: 'MK',
    phonePrefix: '+389',
  },
  {
    label: 'Norway',
    value: 'NO',
    phonePrefix: '+47',
  },
  {
    label: 'Oman',
    value: 'OM',
    phonePrefix: '+968',
  },
  {
    label: 'Pakistan',
    value: 'PK',
    phonePrefix: '+92',
  },
  {
    label: 'Palau',
    value: 'PW',
    phonePrefix: '+680',
  },
  {
    label: 'Panama',
    value: 'PA',
    phonePrefix: '+507',
  },
  {
    label: 'Papua New Guinea',
    value: 'PG',
    phonePrefix: '+675',
  },
  {
    label: 'Paraguay',
    value: 'PY',
    phonePrefix: '+595',
  },
  {
    label: 'Peru',
    value: 'PE',
    phonePrefix: '+51',
  },
  {
    label: 'Philippines',
    value: 'PH',
    phonePrefix: '+63',
  },
  {
    label: 'Poland',
    value: 'PL',
    phonePrefix: '+48',
  },
  {
    label: 'Portugal',
    value: 'PT',
    phonePrefix: '+351',
  },
  {
    label: 'Qatar',
    value: 'QA',
    phonePrefix: '+974',
  },
  {
    label: 'Republic of the Congo',
    value: 'CG',
    phonePrefix: '+242',
  },
  {
    label: 'Romania',
    value: 'RO',
    phonePrefix: '+40',
  },
  {
    label: 'Russia',
    value: 'RU',
    phonePrefix: '+73,4,5,8,9',
  },
  {
    label: 'Rwanda',
    value: 'RW',
    phonePrefix: '+250',
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'KN',
    phonePrefix: '+1869',
  },
  {
    label: 'Saint Lucia',
    value: 'LC',
    phonePrefix: '+1758',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'VC',
    phonePrefix: '+1784',
  },
  {
    label: 'Samoa',
    value: 'WS',
    phonePrefix: '+685',
  },
  {
    label: 'San Marino',
    value: 'SM',
    phonePrefix: '+378',
  },
  {
    label: 'São Tomé and Príncipe',
    value: 'ST',
    phonePrefix: '+239',
  },
  {
    label: 'Saudi Arabia',
    value: 'SA',
    phonePrefix: '+966',
  },
  {
    label: 'Senegal',
    value: 'SN',
    phonePrefix: '+221',
  },
  {
    label: 'Serbia',
    value: 'RS',
    phonePrefix: '+381',
  },
  {
    label: 'Seychelles',
    value: 'SC',
    phonePrefix: '+248',
  },
  {
    label: 'Sierra Leone',
    value: 'SL',
    phonePrefix: '+232',
  },
  {
    label: 'Singapore',
    value: 'SG',
    phonePrefix: '+65',
  },
  {
    label: 'Slovakia',
    value: 'SK',
    phonePrefix: '+421',
  },
  {
    label: 'Slovenia',
    value: 'SI',
    phonePrefix: '+386',
  },
  {
    label: 'Solomon Islands',
    value: 'SB',
    phonePrefix: '+677',
  },
  {
    label: 'Somalia',
    value: 'SO',
    phonePrefix: '+252',
  },
  {
    label: 'South Africa',
    value: 'ZA',
    phonePrefix: '+27',
  },
  {
    label: 'South Korea',
    value: 'KR',
    phonePrefix: '+82',
  },
  {
    label: 'South Sudan',
    value: 'SS',
    phonePrefix: '+211',
  },
  {
    label: 'Spain',
    value: 'ES',
    phonePrefix: '+34',
  },
  {
    label: 'Sri Lanka',
    value: 'LK',
    phonePrefix: '+94',
  },
  {
    label: 'Sudan',
    value: 'SD',
    phonePrefix: '+249',
  },
  {
    label: 'Suriname',
    value: 'SR',
    phonePrefix: '+597',
  },
  {
    label: 'Switzerland',
    value: 'CH',
    phonePrefix: '+41',
  },
  {
    label: 'Syria',
    value: 'SY',
    phonePrefix: '+963',
  },
  {
    label: 'Tajikistan',
    value: 'TJ',
    phonePrefix: '+992',
  },
  {
    label: 'Tanzania',
    value: 'TZ',
    phonePrefix: '+255',
  },
  {
    label: 'Thailand',
    value: 'TH',
    phonePrefix: '+66',
  },
  {
    label: 'Timor-Leste',
    value: 'TL',
    phonePrefix: '+670',
  },
  {
    label: 'Togo',
    value: 'TG',
    phonePrefix: '+228',
  },
  {
    label: 'Tonga',
    value: 'TO',
    phonePrefix: '+676',
  },
  {
    label: 'Trinidad and Tobago',
    value: 'TT',
    phonePrefix: '+1868',
  },
  {
    label: 'Tunisia',
    value: 'TN',
    phonePrefix: '+216',
  },
  {
    label: 'Turkey',
    value: 'TR',
    phonePrefix: '+90',
  },
  {
    label: 'Turkmenistan',
    value: 'TM',
    phonePrefix: '+993',
  },
  {
    label: 'Tuvalu',
    value: 'TV',
    phonePrefix: '+688',
  },
  {
    label: 'Uganda',
    value: 'UG',
    phonePrefix: '+256',
  },
  {
    label: 'Ukraine',
    value: 'UA',
    phonePrefix: '+380',
  },
  {
    label: 'United Arab Emirates',
    value: 'AE',
    phonePrefix: '+971',
  },
  {
    label: 'United Kingdom',
    value: 'GB',
    phonePrefix: '+44',
  },
  {
    label: 'United States',
    value: 'US',
    phonePrefix: '+1',
  },
  {
    label: 'Uruguay',
    value: 'UY',
    phonePrefix: '+598',
  },
  {
    label: 'Uzbekistan',
    value: 'UZ',
    phonePrefix: '+998',
  },
  {
    label: 'Vanuatu',
    value: 'VU',
    phonePrefix: '+678',
  },
  {
    label: 'Vatican City',
    value: 'VA',
    phonePrefix: '+3906698,79',
  },
  {
    label: 'Venezuela',
    value: 'VE',
    phonePrefix: '+58',
  },
  {
    label: 'Vietnam',
    value: 'VN',
    phonePrefix: '+84',
  },
  {
    label: 'Yemen',
    value: 'YE',
    phonePrefix: '+967',
  },
  {
    label: 'Zambia',
    value: 'ZM',
    phonePrefix: '+260',
  },
  {
    label: 'Zimbabwe',
    value: 'ZW',
    phonePrefix: '+263',
  },
]

export default data
