import useTrackingContext, { TrackingDispatchParams } from '../../../services/trackingContext/useTrackingContext'
import { ModalUrlHash } from '../../../utils/constants'
import { RFQModalEvents, AnalyticsContext, AnalyticsLocation, DocumentRequestModalEvents, SampleRequestModalEvents, AnalyticsModalFunction, AnalyticsModalFunctionValue } from '../../../utils/types/analytics'
import { Product } from '../../../utils/types/Product'
import useModalTrigger from './useModalTrigger'

/**
 * Use modal universal hook with analytics events.
 *
 * Event parameters can be set at hook level, which will populate the
 * respective global state events with those parameters.
 *
 * Hook level event parameters can be overriden for individual openModal events.
 *
 */
interface UseModal {
  analyticsLocation: AnalyticsLocation | undefined
  analyticsFunction: AnalyticsModalFunctionValue
  urlHash: ModalUrlHash
  product?: Product
}

const useModal = (params: UseModal) => {
  const { analyticsLocation, analyticsFunction, urlHash, product } = params

  const { openModalTrigger } = useModalTrigger(urlHash)
  const { state, dispatchTrackingContext } = useTrackingContext()

  const analyticsContextValue: AnalyticsContext = analyticsLocation
    ? `${analyticsLocation}_${analyticsFunction}`
    : ''

  const openModal = (analyticsLocationTrigger?: AnalyticsLocation) => {
    const analyticsContextTrigger: AnalyticsContext | undefined = analyticsLocationTrigger
      ? `${analyticsLocationTrigger}_${analyticsFunction}`
      : undefined

    const modalEvents = {
      [AnalyticsModalFunction.DocumentRequest]: DocumentRequestModalEvents,
      [AnalyticsModalFunction.RFQ]: RFQModalEvents,
      [AnalyticsModalFunction.SampleRequest]: SampleRequestModalEvents,
    }

    const modalEventsKey = analyticsFunction as keyof typeof modalEvents

    if (!modalEvents[modalEventsKey]) {
      return
    }

    Object.values(modalEvents[modalEventsKey])
      .forEach((eventName) => {
        const eventObject: TrackingDispatchParams = {
          eventName,
          eventParameters: {
            context: analyticsContextTrigger || analyticsContextValue,
            product_id: product?.['product.code'] || '',
          },
          util: {
            ...state.util,
            product,
          },
        }

        dispatchTrackingContext(eventObject)
      })

    openModalTrigger()
  }

  return openModal
}

export default useModal
