import { getHashedUserId } from './analytics/utils'
import getUserType from './analytics/getUserType'
import { LOCAL_STORAGE_SUPER_USER_KEY, LOCAL_STORAGE_USER_KEY } from './constants'
import { UserI } from './types/auth'

interface LocalStorageUserData extends UserI {
  user_id: string
  userType: ReturnType<typeof getUserType>
}

export const getLocalStorageUserData = (
  userType: typeof LOCAL_STORAGE_USER_KEY | typeof LOCAL_STORAGE_SUPER_USER_KEY,
): LocalStorageUserData | null => {
  const userData = localStorage.getItem(userType)
  return userData
    ? JSON.parse(userData) as LocalStorageUserData
    : null
}

export const setLocalStorageUserData = async (
  userType: typeof LOCAL_STORAGE_USER_KEY | typeof LOCAL_STORAGE_SUPER_USER_KEY,
  userData: UserI | null,
) => {
  // Generate analytics user_id
  const userId = userData ? await getHashedUserId(userData.email) : null
  localStorage.setItem(userType, JSON.stringify({
    ...userData,
    userId: userId?.hashedId || null,
    userType: getUserType(userData),
  }))
}
