import { FC, RefObject } from 'react'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { FlexboxGrid } from 'rsuite'
import { useRouter } from 'next/router'

import MegaMenu from './MegaMenu/MegaMenu'
import useUrls from '../../../services/useUrls'
import useCategoryDataStructure from '../../hooks/useCategoryDataStructure'
import categoryHeaderLinks from '../../../utils/categoryHeaderLinks.json'
import { getCountryAndLocaleStrings } from '../../../utils/locales'
import { CatalogType } from '../../../utils/types/misc'
import useMegaMenuData from '../../../services/strapi/useMegaMenuData'
import { getBlogUrl } from '../../../utils/urls'

import styles from '../../../styles/CategoryHeader.module.less'

interface CategoryData {
  id: string
  attributes: {
    'catalog.code': string
    'catalog.label': string
  }
}

interface CategoryHeaderProps {
  headerRef: RefObject<HTMLDivElement>
}

const CategoryHeader: FC<CategoryHeaderProps> = (props) => {
  const { headerRef } = props

  const { t } = useTranslation('industry')
  const { asPath, locale: countryAndLang } = useRouter()
  const { urlT } = useUrls()
  const {
    parentCategory,
    selectedCategory,
  } = useCategoryDataStructure()

  const { locale } = getCountryAndLocaleStrings(countryAndLang)

  const blogLink = getBlogUrl(locale)

  const columnsData = useMegaMenuData()

  const isProductsCatalog = asPath.includes(urlT('/products'))
  const isSuppliersCatalog = asPath.includes(urlT('/suppliers'))

  const categories = categoryHeaderLinks[locale]

  const isSelectedCategoryMatching = (category: CategoryData) => parentCategory === category.attributes['catalog.code']
  const getRelevantColumnsData = (categoryCode: string) => columnsData[categoryCode]

  const renderCatalogTab = (catalogType: CatalogType, isSelectedCondition: boolean) => {
    const isProductsCatalogType = catalogType === 'products'

    return (
      <FlexboxGrid.Item>
        <Link
          scroll
          shallow
          href={urlT(`/${isProductsCatalogType ? '' : catalogType}`)}
          legacyBehavior
        >
          <a
            className={!selectedCategory && isSelectedCondition ? styles['selected-category'] : ''}
          >
            {t(isProductsCatalogType ? 'common:Featured' : 'products:Suppliers')}
          </a>
        </Link>
      </FlexboxGrid.Item>
    )
  }

  return (
    <div
      data-testid="category-header"
      className={styles['category-header']}
    >
      <FlexboxGrid className={`flex-flow-row ${styles['nav-links']}`}>
        {renderCatalogTab('products', isProductsCatalog)}
        {categories?.map((cat) => (
          <MegaMenu
            key={cat.attributes['catalog.code']}
            topCatData={cat}
            columnData={getRelevantColumnsData(cat.attributes['catalog.code'])}
            headerRef={headerRef}
          >
            <FlexboxGrid.Item>
              <Link
                scroll
                shallow
                href={urlT(`/products/category/${cat.attributes['catalog.code']}`)}
                legacyBehavior
              >
                <a className={isSelectedCategoryMatching(cat) ? styles['selected-category'] : ''}>
                  <span>{t(cat.attributes['catalog.label'])}</span>
                </a>
              </Link>
            </FlexboxGrid.Item>
          </MegaMenu>
        ))}
        {renderCatalogTab('suppliers', isSuppliersCatalog)}
        <FlexboxGrid.Item>
          <Link
            scroll
            href={blogLink}
            target="_blank"
          >
            {t('common:Guides')}
          </Link>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </div>
  )
}

export default CategoryHeader
