import dayjs from 'dayjs'
import { UrlObject } from 'url'

import { Supplier } from '../utils/types/Product'
import { UserI } from '../utils/types/auth'
import { capitalizeFirstLetter } from '../utils/util'

const getSupplierCode = (supplierName: string): string => (
  // Create code from name the same way as during signup, see 'apply.tsx'
  supplierName
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[åä]/, 'a')
    .replace(/ö/, 'o')
    .replace(/'/, '-') // replace a single quote with a dash (e.g test's supplies -> test-s-supplies)
    .replace(/([-])\1/, '-') // handle a case with consecutive dashes as a result of a ' and a whitespace (e.g test' supplies -> test--supplies -> test-supplies)
)

const isNewSupplier = (supplier: Supplier) => dayjs(supplier['supplier.ctime']).isAfter(
  dayjs().subtract(Number(process.env.NEXT_PUBLIC_NEW_SUPPLIER_LABEL || 31), 'day'),
)

const getSupplierShopPageUrl = (supplierCode: string, urlT: (path: string | UrlObject) => string) => urlT(`/supplier/${supplierCode}`)

type User = UserI | null

const isSupplier = (user: User) => Boolean(user?.siteid)
const isSuperUser = (user: User) => user?.superuser === 1

const hasSignedTerms = (user: User) => {
  const signedAt = user?.terms_signed_at
  const hasSigned = Boolean(signedAt && new Date(signedAt))

  return hasSigned
}

const getSupplierName = (supplier?: Supplier) => capitalizeFirstLetter(supplier?.['supplier.label'] || '')

const extractBrandRows = (brands: Supplier[]) => {
  let firstArray = []
  let secondArray = []

  // Ensure the array has enough elements for the initial split
  if (brands.length < 11) {
    return [
      brands.slice(
        0,
        brands.length / 2,
      ),
      brands.slice(
        brands.length / 2,
        brands.length,
      ),
    ]
  }

  // Extract the first 5 elements for the first array
  firstArray = brands.slice(0, 5)

  // Extract the next 6 elements for the second array
  secondArray = brands.slice(5, 11)

  // Add remaining elements alternately to the two arrays
  brands.slice(11).forEach((item, index) => {
    if (index % 2 === 0) {
      firstArray.push(item)
    } else {
      secondArray.push(item)
    }
  })

  return [firstArray, secondArray]
}

export {
  isSupplier,
  isSuperUser,
  hasSignedTerms,
  getSupplierCode,
  isNewSupplier,
  getSupplierShopPageUrl,
  getSupplierName,
  extractBrandRows,
}
