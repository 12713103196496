import TagManager from 'react-gtm-module'

interface UpdateCartParams {
  isUnderMov: boolean
  productId: string // Carts are updated one product per time, refactor if logic changes
}

const updateCart = ({ isUnderMov, productId }: UpdateCartParams) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'update_cart',
      is_under_mov: isUnderMov,
      product_id: productId,
    },
  })
}

export default updateCart
