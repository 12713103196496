import { Locale } from '../external'
import { ActiveCountryId, CMS_BASE_URL } from './constants'
import {
  FormattedMegaMenuLinksColumn,
  InitialMegaMenuLinksColumn,
  StrapiCountry,
  StrapiMediaAttributeDataSingle,
  StrapiProductSpecialist,
  StrapiTermsOfServiceResp,
} from './types/strapi'

export const filterProductSpecialistsByCountry = (
  data: StrapiProductSpecialist[],
  country: ActiveCountryId,
) => data?.filter(
  (specialist: StrapiProductSpecialist) => specialist.attributes.countries.data
    .some(
      (specialistCountry: StrapiCountry) => specialistCountry
        .attributes.countryCode.toLowerCase() === country?.toLowerCase(),
    ),
)

export const findProductSpecialistByName = (
  data: StrapiProductSpecialist[],
  name: string,
) => data?.find((spe: StrapiProductSpecialist) => spe.attributes.name === name)

export const getCountryDefaultSpecialist = (
  data: StrapiCountry[], country: ActiveCountryId,
) => data?.find(
  (specialistCountry) => specialistCountry
    .attributes.countryCode.toLowerCase() === country?.toLowerCase(),
)?.attributes.defaultProductSpecialist?.data

export const getStrapiMediaUrl = (media: StrapiMediaAttributeDataSingle | undefined) => {
  if (!media?.data?.attributes?.url) {
    return undefined
  }

  if (CMS_BASE_URL?.includes('localhost')) {
    return `${CMS_BASE_URL}${media.data.attributes.url}`
  }

  return media.data.attributes.url
}

// For each link object, strip the params and set them as a record into a params object
export const formatColumnLinks = (
  columnData: InitialMegaMenuLinksColumn,
): FormattedMegaMenuLinksColumn => {
  const { linkData } = columnData
  const formattedLinksData = linkData.map((linkObj) => {
    const { link } = linkObj
    const [linkPath, params] = link.split('?')

    const columnsLinksData = {
      ...linkObj,
      link: linkPath,
    }

    if (params) {
      const paramsRecord = params.split('&').reduce<Record<string, string>>(
        (queryObj, currQueryStr) => {
          const [queryKey, queryValue] = currQueryStr.split('=')
          const decodedQueryValue = decodeURIComponent(queryValue) // decode encoded query strings
          const queryValueWithoutSeparators = decodedQueryValue.replaceAll('+', ' ') // remove '+' chars from search queries

          return { ...queryObj, [queryKey]: queryValueWithoutSeparators }
        }, {},
      )

      return {
        ...columnsLinksData,
        params: paramsRecord,
      }
    }

    return columnsLinksData
  })

  return {
    ...columnData,
    linkData: formattedLinksData,
  }
}

export const getTosContent = (
  data?: StrapiTermsOfServiceResp,
) => data?.data?.[0]?.attributes?.content

export const getTosSeoContent = (data: StrapiTermsOfServiceResp) => data?.data?.[0]?.attributes?.seo

interface BuildStrapiUrl {
  endpoint: string
  locale: Locale
  queryParams?: string
}

export const buildStrapiUrl = ({ endpoint, locale, queryParams = '' }: BuildStrapiUrl) => `${CMS_BASE_URL}/api/${endpoint}?locale=${locale}${queryParams}`

export const getStrapiCategoryUrl = (categoryCode: string, locale: Locale) => {
  const queryParams = `&filters[code][$eq]=${categoryCode}`
  return buildStrapiUrl({ endpoint: 'product-categories', locale, queryParams })
}

export const getStrapiSupplierUrl = (supplierCode: string, locale: Locale) => {
  const queryParams = `&filters[code][$eq]=${supplierCode}`
  return buildStrapiUrl({ endpoint: 'suppliers', locale, queryParams })
}
