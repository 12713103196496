import React, { FC } from 'react'
import { InputPicker, InputProps } from 'rsuite'
import { useRouter } from 'next/router'

import Textarea from '../UI/Textarea'
import { getCountryAndLocalesLists } from '../../utils/locales'
import { Locale } from '../../external'

import styles from '../../styles/ProductTextField.module.less'

interface Props extends InputProps {
  onChangeLanguage: (newValue: Locale) => void
  lang?: Locale
}

const TextareaWithLangPicker: FC<Props> = ({ onChangeLanguage, lang = 'en', ...props }) => {
  const { locales: countryAndLocaleList } = useRouter()
  const { locales } = (
    countryAndLocaleList && getCountryAndLocalesLists(countryAndLocaleList)
  ) || {}

  return (
    <div className={styles['textarea-wrapper']}>
      <InputPicker
        className={`
          ${styles['lang-dropdown']} 
          ${styles['lang-dropdown-textarea']} 
          ${styles['arrow-alignment-country-picker']}
          ${styles['long-input-picker-alignment']}
        `}
        data={locales?.map((l) => ({ label: l.toUpperCase(), value: l })) || []}
        title={lang.toUpperCase()}
        size="sm"
        cleanable={false}
        value={lang}
        onSelect={onChangeLanguage}
      />
      <Textarea
        rows={5}
        {...props}
      />
    </div>
  )
}

export default TextareaWithLangPicker
