import { useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import useSWR, { KeyedMutator } from 'swr'
import useSWRInfinite from 'swr/infinite'
import capitalize from 'lodash/capitalize'

import {
  Catalog,
  getStatus,
  Product,
  ProductAttribute,
  ProductMedia,
  ProductPrice,
  ProductStock,
  ProductText,
  ResourceGql,
  ProductStockGql,
  ResponseError,
  ProductGql,
} from '../utils/types/Product'
import { getSiteCode, useAuth } from './useAuth'
import {
  getGraphQLQuery,
  getRelationships,
  parseResponse,
  recordToQueryString,
  ResourceList,
  restructureGqlResourceResponse,
  resultDataCollector,
  UseApiConfig,
} from './useApiHelpers'
import {
  hasLinks, ICartLinks, IJSONObject,
  IOptionsDocument,
  IRelationshipObject,
  IAggregateObject,
  IResourceIdentifierObject,
  IAggregateIdentifierObject,
  IResourceLinkage,
  IResponseDocument,
  SaveProductResponse,
  SaveProductSuccess,
  SearchResourceResponse,
} from '../utils/types/aimeosApi'
import fetcher, { SWRFetcher } from '../utils/fetcher'
import { CountryData, ResourceTag } from '../utils/constants'
import { getCountryAndLocaleStrings, getCurrencyFromCountryAndLocale } from '../utils/locales'
import { CountryAndLocale } from '../external'
import {
  deleteRuleMutationQuery,
  deleteProductMutationQuery,
  getFetchResourceQuery,
  getSaveRulesMutationQuery,
  getSaveStockMutationQuery,
} from '../utils/graphqlConstants'
import { OrderResponse } from '../utils/types/Order'
import { ProductProperty, Resource, ResourceName, ResourceNameMap } from '../utils/types/Resource'
import getUserType from '../utils/analytics/getUserType'

const savedAsRelationship = ['price', 'text', 'supplier', 'attribute', 'product', 'service']
const savedWithRef = ['stock', 'catalog', 'media', 'product/property']
const apiUrl = process.env.NEXT_PUBLIC_API_URL

interface Combined<ResourceT> {
  data: ResourceT[]
  isLoading: boolean
  error: any
  refresh: () => any
  loadMore: (pages?: number) => void
  links: ICartLinks []
  total: number
}

interface GetResourceOptions {
  initialSize?: number
  isInfinite?: boolean
  shouldUseCache?: boolean
  countrySpecific?: boolean
}

export type GetResource = <T extends Resource>(
    resource: ResourceName | null,
    params?: string,
    getResourceOptions?: GetResourceOptions,
  ) => {
    data: T[]
    isLoading: boolean
    error: ResponseError
    refresh: () => Promise<void>
    loadMore: (pages?: number) => void
    links: ICartLinks []
    total: number
  }

export type GetResourceGql = <
  ResourceT extends Resource,
  ResourceGqlT extends ResourceGql>(
    resource: ResourceName | null,
    params: string,
    prodCode?: string
  ) => {
    data: ResourceT[]
    isLoading: boolean
    error: ResponseError
    refresh: KeyedMutator<SearchResourceResponse<ResourceGqlT>>
  }

export type GetResourceCount = (
  resource: typeof ResourceTag.product,
  params: string,
) => {
  data: IAggregateIdentifierObject[]
  isLoading: boolean
  error: ResponseError
}

export type GetResourceOrderHistory = () => {
  data: OrderResponse | undefined
  isLoading: boolean
  error: ResponseError
}
interface ApiFunctions {
  getAttributes: () => {
    data?: IOptionsDocument
    isLoading: boolean
    error: ResponseError
  }
  getResourceCount: GetResourceCount
  getResource: GetResource
  getSingleResource: <T extends Resource>(
    resource: ResourceName | null,
    params?: string,
    shouldUseCache?: boolean,
  ) => {
    data?: T
    isLoading: boolean
    error: ResponseError
    refresh: () => void
    links: ICartLinks []
  }
  updateResource: <R extends Resource, T extends Partial<R>>(
    resource: ResourceName,
    data: T,
    params: string | Record<string, string>,
  ) => Promise<[R | R[], ResourceList<Resource>, ResourceList]>
  createResource: <R extends Resource, T extends Partial<R>>(
    resource: ResourceName,
    data: T | T[],
    params: string | Record<string, string>,
  ) => Promise<[R | R[], ResourceList<Resource>, ResourceList]>
  createResourceGraphql: (
    args: ResourceNameMap,
  ) => Promise<[SaveProductSuccess | undefined, ResponseError]>
  getResourceGraphql: GetResourceGql
  updateResourceGraphql: (
    args: ResourceNameMap
  ) => Promise<[SaveProductSuccess | undefined, ResponseError]>
  deleteResource: (
    resource: ResourceName,
    params: string,
  ) => Promise<boolean>
  getResourceOrderHistory: GetResourceOrderHistory
  deleteResourceGraphQl: (resource: string, query: string) => Promise<any>
}

interface ResourceCount {
  data: IAggregateIdentifierObject[]
  isLoading: boolean
  error: ResponseError
}

const useApiFunctions = (
  baseUrl: string | null,
  siteCode: string,
): ApiFunctions => {
  const { locale: countryAndLocale } = useRouter()

  const { country, locale } = getCountryAndLocaleStrings(countryAndLocale)
  const countryInfo = CountryData.find((ele) => ele.locale === country)
  const currency = countryInfo?.currency
    || getCurrencyFromCountryAndLocale(countryAndLocale as CountryAndLocale)

  // Must memoize param object, otherwise every render will
  // create a new object and trigger SWR revalidation
  const fetchParams = useMemo(() => ({ method: 'OPTIONS' }), [])

  const {
    data: options,
    error: optionsError,
  } = useSWR<IOptionsDocument>(baseUrl ? [`${baseUrl}${currency ? `?currency=${currency}&locale=${locale}` : ''}`, JSON.stringify(fetchParams)] : null)

  const getAttributes = () => ({
    data: options,
    isLoading: !options && !optionsError,
    error: optionsError,
  })

  const getResourceCount = (resource: typeof ResourceTag.product, param: string): ResourceCount => {
    const resourceUrl = options?.meta?.resources[resource]
      ? `${options?.meta?.resources[resource]}&country=${country}&${param}`
      : null
    const [isLoading, setIsLoading] = useState(true)
    const {
      data,
      error,
    } = useSWR<IAggregateObject>(() => resourceUrl)
    const respData = data && Array.isArray(data) ? data.at(0) : data

    if (isLoading && (data || error)) {
      setIsLoading(false)
    }

    return { data: respData?.data ?? [], isLoading: resource ? isLoading : false, error }
  }

  const getResource = <ResourceT extends Resource = any>(
    resource: string | null,
    params: string = '',
    getResourceOptions: GetResourceOptions = {},
  ): Combined<ResourceT> => {
    const {
      shouldUseCache = true,
      isInfinite = true,
      initialSize = 1,
      countrySpecific = true,
    } = getResourceOptions
    const resourceUrl = resource ? options?.meta?.resources[resource] : null
    const { openInvalidSessionModal, useRefreshCallback } = useAuth()
    const { pathname } = useRouter()
    // Add country filter to product queries that are not querying a specific id
    // Since single product pages (that use ids) should always find the product
    // but only in the frontend show if the product is unavailable

    let respDataRaw: IResponseDocument | IResponseDocument[] | undefined
    let respError: any = null
    let url: string | null = null
    let mutate: KeyedMutator<IResponseDocument> | KeyedMutator<IResponseDocument[]> | undefined
    let setSize: (
      size: number | ((_size: number) => number)
    ) => Promise<IResponseDocument[] | undefined>

    if (resourceUrl === undefined) {
      url = null
    } else {
      const shouldSkipCountryParam = ['id=', 'ids=', 'external='].some((param) => params.includes(param))
      url = resource === 'product' && countrySpecific && !shouldSkipCountryParam
        ? `${resourceUrl}&country=${country}`
        : resourceUrl
    }

    // If specifying explicitly not use useSWRInfinite, e.g. in case of pagination, we would
    // like to get the data using the useSWR hook
    if (!isInfinite) {
      const urlParams = params.startsWith('&') ? params : `&${params}`
      const dataUrl = url && params ? `${url}${urlParams}` : ''
      const {
        data,
        error,
        mutate: mutateFn,
      } = useSWR<IResponseDocument>(dataUrl, {
        fetcher: SWRFetcher(openInvalidSessionModal, pathname, shouldUseCache),
      })
      respDataRaw = data
      respError = error
      mutate = mutateFn
      // Otherwise use the useSWRInfinite hook
    } else {
      const getKey = (pageIndex: number, prevData: IResponseDocument | null): string | null => {
        if (pageIndex === 0 || !pageIndex) return url ? `${url}${params[0] === '&' ? '' : '&'}${params}` : null
        if (prevData && hasLinks(prevData)) {
          const link = prevData.links?.next
          return typeof link === 'string' ? link : link?.href || null
        }
        return null
      }
      const {
        data,
        error,
        mutate: mutateFn,
        setSize: setSizeFn,
      } = useSWRInfinite<IResponseDocument>(getKey, {
        initialSize,
        fetcher: SWRFetcher(openInvalidSessionModal, pathname, shouldUseCache),
      })
      respDataRaw = data
      respError = error
      mutate = mutateFn
      setSize = setSizeFn
    }
    // Register a refresh on user change for order & customer requests
    useRefreshCallback(`${resource}/${params}`, ['customer', 'order'].includes(resource || '') ? mutate : null)

    const respData = useMemo(() => {
      const respDataPages = !Array.isArray(respDataRaw) ? [respDataRaw] : respDataRaw
      return respDataPages?.map((dataPage) => parseResponse<ResourceT>(dataPage, respError))
    }, [respDataRaw, respError])
    const total = Number([respDataRaw].flat()[0]?.meta?.total) || 0

    return (respData || []).reduce<Combined<ResourceT>>((previousValue, currentValue) => ({
      data: previousValue.data.concat(currentValue.data),
      isLoading: url === null ? false : (previousValue.isLoading || currentValue.isLoading),
      error: previousValue.error || currentValue.error,
      refresh: previousValue.refresh,
      loadMore: previousValue.loadMore,
      links: previousValue.links.concat(currentValue.links),
      total,
    }), {
      data: [],
      isLoading: false,
      error: [],
      refresh: mutate,
      loadMore: (pages?: number) => {
        setSize((size) => pages ?? size + 1)
      },
      links: [],
      total,
    })
  }

  const getSingleResource = <ResourceT extends Resource = any>(
    resource: string | null,
    params: string = '',
    shouldUseCache: boolean = true,
  ): {
      data?: ResourceT
      isLoading: boolean
      error: ResponseError
      refresh: () => void
      links: ICartLinks []
    } => {
    const { data, ...rest } = getResource<ResourceT>(resource, params, {
      shouldUseCache,
      isInfinite: false,
    })
    return {
      data: data[0],
      ...rest,
    }
  }

  const updateResource = async <
    ResourceT extends Resource,
    PartialT extends Partial<ResourceT> = any,
    >(
    resource: ResourceName,
    data: PartialT,
    params: string | Record<string, string>,
  ): Promise<[
    ResourceT[],
    ResourceList<Resource>,
    ResourceList,
  ]> => {
    if (!options) {
      console.error('No resource links found')
      return [[], {}, { [resource]: ['No resource links found'] }]
    }
    if (!(resource in options.meta.resources)) {
      console.error(`Trying to update unavailable resource ${resource}`)
      console.log(options.meta.resources)
      return [[], {}, { [resource]: [`Resource ${resource} not found`] }]
    }

    const [updated, errors, addResultData, mergeData] = resultDataCollector()
    const url = options.meta.resources[resource]
    const queryString = typeof params === 'string' ? params : `&${recordToQueryString(params).join('&')}`
    const config: UseApiConfig = {
      queryString: '',
      options,
    }
    const relationships: Record<string, IRelationshipObject> = {}
    const promises = Object.entries(data)
      .filter(([key, value]) => savedAsRelationship.includes(key) && value.length > 0)
      .map(async ([key, value]: [key: string, value: Resource[]]) => {
        if (key === 'product') {
          const relatedFull = await Promise.all(value.map((prod) => {
            if (prod.id === '') {
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              return createResource<Product>(key, prod, '')
            }
            return updateResource<Product>(key, prod, `&id=${prod.id}`)
          }))
          const related = relatedFull.map(([mainData, dataList, errList]) => {
            mergeData(dataList, errList)
            return mainData
          }).flat()
          const oldIDs = value.map((res) => res.id)
          const shouldUpdateRelationship = (linked: Resource) => (
            !oldIDs.includes(linked.id)
              || resource === 'supplier'
              || resource === 'catalog'
          )
          // Set default list pos 50 so new products are below featured products
          const listAttributes: IJSONObject = resource === 'catalog' && key === 'product'
            ? { 'catalog.lists.position': 50 }
            : {}
          relationships[key] = {
            data: related.filter((linked) => shouldUpdateRelationship(linked)).map((linked) => ({
              id: linked.id,
              type: key,
              attributes: listAttributes,
            })),
          }
        } else {
          const { data: related, error } = await getRelationships(value, key as ResourceName, config, 'PATCH')
          addResultData(key as ResourceName, related, error)
          const oldIDs = value.map((res) => res.id)
          // Filter out resources with old id as they do not need
          // to be linked to main resource again
          // TODO: Attributes that exist shouldn't be filtered out
          const shouldUpdateRelationship = (linked: Resource) => (
            !oldIDs.includes(linked.id)
            || key === 'attribute'
          )
          const relationshipAttributes: Record<string, {
            'product.lists.type'?: string
            'product.lists.position'?: number
          }[]> = {}
          if (key === 'attribute') {
            // Check if there are new product.lists attributes, in that case add them
            await Promise.all((value as ProductAttribute[]).map(async (rel) => {
              if (rel['product.lists.type']) {
                if (!relationshipAttributes[rel.id]) {
                  relationshipAttributes[rel.id] = []
                }
                relationshipAttributes[rel.id].push({
                  'product.lists.type': rel['product.lists.type'],
                })
              }
              if (rel['product.lists.id'] && rel['product.lists.type']) {
                // Existing relationships is easiest to delete and add back with new data
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                await deleteResource('product/lists', `&id=${rel['product.lists.id']}`)
              }
            }))
          }
          relationships[key] = {
            data: related.filter((linked) => shouldUpdateRelationship(linked)).map((linked) => {
              const attributes = linked?.id && relationshipAttributes[linked.id]?.pop()
              return {
                id: linked?.id ?? '',
                type: key,
                attributes: attributes || {},
              }
            }),
          }
        }
      })

    const refPromises = Object.entries(data)
      .filter(([key, value]) => savedWithRef.includes(key) && value.length > 0)
      .map(async ([key, value]) => {
        // TODO: Currently only supports creating one resource
        switch (key) {
          case 'stock': {
            const newProdId = data.id
            const { data: respData, error } = await getRelationships<ProductStock>(
              value, 'stock', config, 'PATCH', { 'stock.productid': newProdId },
            )
            addResultData('stock', respData, error)
            return respData
          }
          case 'catalog': {
            const prodData = data as unknown as Product
            const catRelationship = {
              product: {
                data: [{
                  id: prodData.id,
                  type: 'product',
                  attributes: {
                    'catalog.lists.position': 50, // Default pos 50 so new products are below featured products
                  },
                }],
              },
            }
            const catRelationships = Array(value.length).fill(catRelationship)
            const { data: catalogData, error } = await getRelationships<Catalog>(value, 'catalog', config, 'PATCH', undefined, catRelationships)
            addResultData('catalog', catalogData, error)
            return catalogData
          }
          case 'media': {
            const newProdId = data.id
            const mediaData = (value as ProductMedia[])
              .filter((media) => media['media.label'] !== 'uploader')
              .map((media) => {
                if (media.id !== '') {
                  // Don't post file if existing image
                  const withoutFile = { ...media }
                  delete withoutFile.data
                  return withoutFile
                }
                return media
              })
              .map(async (media) => {
                const bodyWithFile = new FormData()
                bodyWithFile.append('0', media.data as Blob)
                bodyWithFile.append('data', JSON.stringify({ 0: media }))

                const mediaResp = await fetcher(
                  `${apiUrl}/api/v1/${siteCode}/upload/product/${newProdId}`,
                  'POST',
                  bodyWithFile,
                )
                const respData = await mediaResp.json()
                const {
                  data: mediaResource,
                  error: mediaError,
                } = parseResponse<ProductMedia>(respData)
                addResultData('media', mediaResource, mediaError)
                return mediaResource
              })
            const nested = await Promise.all(mediaData)
            return nested.flat()
          }
          case 'product/property': {
            const newProdId = data.id
            const { data: respData, error } = await getRelationships<ProductProperty>(
              value,
              'product/property',
              config,
              'PATCH',
              { 'product.property.parentid': newProdId },
            )
            addResultData('product/property', respData, error)
            return respData
          }
          default: {
            return []
          }
        }
      })
    // Await patching linked resources
    await Promise.all([...promises, ...refPromises])
    const hasRelationships = Object.keys(relationships).length !== 0
    const body = {
      data: {
        type: resource,
        id: data.id,
        attributes: data,
        ...(hasRelationships && { relationships }),
      },
    }
    const resp = await fetcher(`${url}${queryString}`, 'PATCH', JSON.stringify(body))
    const resultData = await resp.json()
    const {
      data: respData,
      error,
    } = parseResponse<ResourceT>(resultData)

    addResultData(resource, respData, error)
    return [respData, updated, errors]
  }

  const getResourceGraphql = <
    ResourceT extends Resource = any,
    ResourceGqlT extends ResourceGql = any,
  >(
      resource: ResourceName | null,
      params: string = '',
    ): {
      data: ResourceT[]
      isLoading: boolean
      error: ResponseError
      refresh: KeyedMutator<SearchResourceResponse<ResourceGqlT>>
    } => {
    const url = `${apiUrl}/admin/${siteCode}/graphql`
    const { user } = useAuth()

    const [isLoading, setIsLoading] = useState(true)

    const fetchGql = async (query: string) => {
      const resp = await fetcher(url, 'POST', JSON.stringify({ query }))
      return resp.json()
    }

    const query = getFetchResourceQuery(params, resource)
    const {
      data,
      error,
      mutate: mutateFn,
    } = useSWR<SearchResourceResponse<ResourceGqlT>>(
      (resource && user) ? query : null, fetchGql,
    )

    const stockFilter = resource === 'product' && Array.isArray(data?.data?.searchResource)
      ? {
        '||': data?.data?.searchResource?.flatMap((item) => ([{
          '==': { 'stock.productid': item.id },
        }].concat(item.lists?.product?.map(({ item: variant }) => ({
          '==': { 'stock.productid': variant?.id || '' },
        })) || []))),
      }
      : {}
    const stockQuery = getFetchResourceQuery(`filter:"${JSON.stringify(stockFilter).replace(/"/g, '\\"')}"`, 'stock')

    const {
      data: stockData,
    } = useSWR<SearchResourceResponse<ProductStockGql>>(data && resource === 'product' ? stockQuery : null, fetchGql)

    const resourceData = resource === 'product' && Array.isArray(data?.data?.searchResource)
      ? {
        data: {
          searchResource: data?.data?.searchResource?.map((product) => ({
            ...product as ProductGql,
            stock: stockData ? stockData.data?.searchResource
              ?.items?.filter((stock) => stock.productid === product.id) || [] : [],
            lists: {
              ...product.lists,
              product: product.lists?.product?.map(({ item, ...rest }) => ({
                ...rest,
                ...(item ? { item: {
                  ...item,
                  stock: stockData ? stockData.data?.searchResource
                    ?.items?.filter((stock) => stock.productid === item?.id) : [],
                } } : null),
              })) || [],
            },
          })) || [],
        },
      } as SearchResourceResponse<ResourceGqlT>// Cast back to generic type
      : data

    // If product resource then both product resource & stock resource loaded
    const resourceLoaded = resource === 'product' ? data && stockData : data

    if (isLoading && (resourceLoaded || error)) {
      setIsLoading(false)
    }

    const resourceResponse = (
      data ? restructureGqlResourceResponse<ResourceGqlT>(resourceData?.data, resource) : []
    ) as ResourceT[]

    return {
      data: resourceResponse || [],
      isLoading: resource ? isLoading || !data : false,
      refresh: mutateFn,
      error,
    }
  }

  const createResourceGraphql: ApiFunctions['createResourceGraphql'] = async (args) => {
    const { resource, data } = args
    if (!options) {
      console.error('No resource links found')
      return [undefined, { [resource]: ['No resource links found'] }]
    }
    if (!(resource in options.meta.resources)) {
      console.error(`Trying to add unavailable resource ${resource}`)
      console.log(options.meta.resources)
      return [undefined, { [resource]: [`Resource ${resource} not found`] }]
    }

    const [, errors, addResultData] = resultDataCollector()
    const query = getGraphQLQuery(args)
    // const query = getSaveProductMutationQuery(data)

    const resp = await fetcher(`${apiUrl}/admin/${siteCode}/graphql`, 'POST', JSON.stringify({ query }))
    const { data: respData }: SaveProductResponse = await resp.json()

    if (!respData?.saveProduct?.id) return [undefined, { [resource]: [`Failed to save ${resource}`] }]

    // Need to call upload file api after saving the product as it requires product id
    const mediaData = resource === 'product' ? data.media.filter((media) => media['media.label'] !== 'uploader')
      .map(async (media) => {
        const bodyWithFile = new FormData()
        bodyWithFile.append('0', media.data as Blob)
        bodyWithFile.append('data', JSON.stringify({ 0: media }))

        const mediaResp = await fetcher(
          `${apiUrl}/api/v1/${siteCode}/upload/product/${respData.saveProduct?.id}`,
          'POST',
          bodyWithFile,
        )
        const resultData = await mediaResp.json()
        const {
          data: mediaResource,
          error: mediaError,
        } = parseResponse<ProductMedia>(resultData)

        addResultData('media', mediaResource, mediaError)
      }) : []

    if (resource === 'product') {
      // Need to call stock api after saving the product as it requires product id
      const stockResp = await fetcher(
        `${apiUrl}/admin/${siteCode}/graphql`,
        'POST',
        JSON.stringify({
          query: getSaveStockMutationQuery(data.stock, respData.saveProduct?.id),
        }),
      )

      const {
        errors: stockError,
      } = await stockResp.json()

      addResultData('stock', [], stockError)
    }

    // Save stock resource for each variant
    if (resource === 'product' && data.product.length > 1) {
      const stockPromises = data.product.map(async (variant, index) => {
        const productId = respData.saveProduct?.lists?.product[index]?.refid as string
        const variantStockResp = await fetcher(
          `${apiUrl}/admin/${siteCode}/graphql`,
          'POST',
          JSON.stringify({
            query: getSaveStockMutationQuery(variant.stock, productId),
          }),
        )

        const {
          errors: variantStockError,
        } = await variantStockResp.json()

        addResultData('stock', [], variantStockError)
      })
      await Promise.all(stockPromises)
    }

    await Promise.all(mediaData)
    return [respData, errors]
  }

  // TODO: Non generic return type needs to be changed
  const updateResourceGraphql: ApiFunctions['updateResourceGraphql'] = async (args) => {
    const { resource, data, updatedFields } = args
    if (!options) {
      console.error('No resource links found')
      return [undefined, { [resource]: ['No resource links found'] }]
    }
    if (!(resource in options.meta.resources)) {
      console.error(`Trying to add unavailable resource ${resource}`)
      console.log(options.meta.resources)
      return [undefined, { [resource]: [`Resource ${resource} not found`] }]
    }

    const [, errors, addResultData] = resultDataCollector()
    const query = getGraphQLQuery(args)

    const resp = await fetcher(`${apiUrl}/admin/${siteCode}/graphql`, 'POST', JSON.stringify({ query }))
    const { data: respData }: SaveProductResponse = await resp.json()

    if (resource === 'product') {
      const productRules = data?.rule
      // Delete rules rules for products
      const rulesToBeDeleted = Array.from(productRules?.keys() ?? []).filter((ele) => ele.includes('delete'))
      const deleteRulePromises = rulesToBeDeleted.map(async (key) => {
        const ruleData = productRules?.get(key)
        if (ruleData?.id) {
          const deleteQuery = deleteRuleMutationQuery(ruleData?.id)

          await fetcher(`${apiUrl}/admin/default/graphql`, 'POST', JSON.stringify({ query: deleteQuery }))
        }
      })
      await Promise.all(deleteRulePromises)

      // Save / Update rules rules for products
      const rulesToBeupdated = Array.from(productRules?.keys() ?? []).filter((ele) => !ele.includes('delete'))
      const saveRulePromises = rulesToBeupdated.map(async (key) => {
        const ruleData = productRules?.get(key)

        if (ruleData?.ruleConfig) {
          await fetcher(
            `${apiUrl}/admin/default/graphql`,
            'POST',
            JSON.stringify({
              query: getSaveRulesMutationQuery(key?.split('_')?.[0], ruleData.ruleConfig, ruleData?.id),
            }),
          )
        }
      })
      await Promise.all(saveRulePromises)
    }

    if (!respData?.[`save${capitalize(resource) as Capitalize<ResourceName>}`]?.id) return [undefined, { [resource]: [`Failed to update ${resource}`] }]

    const mediaData = resource === 'product' && updatedFields ? updatedFields.media.filter((media) => media['media.label'] !== 'uploader' && !!media.data)
      .map(async (media) => {
        const bodyWithFile = new FormData()
        bodyWithFile.append('0', media.data as Blob)
        bodyWithFile.append('data', JSON.stringify({ 0: media }))

        if (data.id) {
          const mediaResp = await fetcher(
            `${apiUrl}/api/v1/${siteCode}/upload/product/${data.id}`,
            'POST',
            bodyWithFile,
          )
          const resultData = await mediaResp.json()
          const {
            data: mediaResource,
            error: mediaError,
          } = parseResponse<ProductMedia>(resultData)

          addResultData('media', mediaResource, mediaError)
        }
      }) : []

    // Need to call stock api after saving the product as it requires product id
    if (resource === 'product' && updatedFields?.stock.length) {
      const stockResp = await fetcher(
        `${apiUrl}/admin/${siteCode}/graphql`,
        'POST',
        JSON.stringify({
          query: getSaveStockMutationQuery(data.stock, respData.saveProduct?.id),
        }),
      )

      const {
        errors: stockError,
      } = await stockResp.json()

      addResultData('stock', [], stockError)
    }

    // Update stock for every variant everytime because as of current logic it is not
    // possible to determine weather it is updated or not
    if (resource === 'product' && data.product.length > 1) {
      const stockPromises = data.product.map(async (variantProd, index) => {
        // Need to get product id from the save product response
        // when a simple product is converted to variant product
        const productId = respData.saveProduct?.lists?.product[index]?.refid as string
        const variantStockResp = await fetcher(
          `${apiUrl}/admin/${siteCode}/graphql`,
          'POST',
          JSON.stringify({
            query: getSaveStockMutationQuery(variantProd.stock, variantProd.id || productId),
          }),
        )

        const {
          errors: variantStockError,
        } = await variantStockResp.json()

        addResultData('stock', [], variantStockError)
      })
      await Promise.all(stockPromises)
    }

    if (
      resource === 'product'
      && updatedFields?.deletedVariantSkus?.length
    ) {
      const deleteQuery = deleteProductMutationQuery(updatedFields.deletedVariantSkus)
      fetcher(`${apiUrl}/admin/default/graphql`, 'POST', JSON.stringify({ query: deleteQuery }))
    }

    await Promise.all(mediaData)
    return [respData, errors]
  }

  const createResource = async <
    ResourceT extends Resource,
    PartialT extends Partial<ResourceT> = any,
    >(
    resource: ResourceName,
    data: PartialT | PartialT[],
    params: string | Record<string, string>,
  ): Promise<[
    ResourceT | ResourceT[],
    ResourceList<Resource>,
    ResourceList,
  ]> => {
    if (!options) {
      console.error('No resource links found')
      return [[], {}, { [resource]: ['No resource links found'] }]
    }
    if (!(resource in options.meta.resources)) {
      console.error(`Trying to add unavailable resource ${resource}`)
      console.log(options.meta.resources)
      return [[], {}, { [resource]: [`Resource ${resource} not found`] }]
    }
    const [created, errors, addResultData, mergeData] = resultDataCollector()
    const queryString = typeof params === 'string' ? params : `&${recordToQueryString(params).join('&')}`
    const config: UseApiConfig = {
      queryString,
      options,
    }

    const relationships: Record<string, IRelationshipObject> = {}
    const promises = Object.entries(data).map(async ([key, value]) => {
      switch (key) {
        case 'text': {
          const { data: respData, error } = await getRelationships<ProductText>(value, 'text', config, 'POST')
          addResultData('text', respData, error)
          relationships.text = {
            data: respData.map((text) => ({
              id: text.id,
              type: 'text',
            })),
          }
          break
        }
        case 'product': {
          const productVariants: Product[] = value.map((variant: Product) => ({
            'product.status': getStatus.public,
            'product.code': variant['product.code'],
            'product.label': variant['product.label'],
            'product.type': 'default',
            text: [],
            catalog: [],
            price: [],
            stock: [],
            media: [],
            variants: [],
          }))
          const variants = (value as Product[])
          if (variants.flatMap((variant) => variant.attribute).some((attr) => attr.id === '')) {
            const newAttrs = variants
              .flatMap((variant) => variant.attribute)
              .filter((attr) => attr.id === '')
            const { data: addedAttrs, error } = await getRelationships<ProductAttribute>(newAttrs, 'attribute', config, 'POST')
            addResultData('attribute', addedAttrs, error)
            variants.forEach((prod) => prod.attribute.forEach((attr) => {
              if (attr.id === '') {
                // eslint-disable-next-line no-param-reassign
                attr.id = addedAttrs.find((added) => added['attribute.code'] === attr['attribute.code'])?.id || ''
              }
            }))
          }
          const variantAttributes = variants.map(
            (variant: Product): IResourceLinkage => (
              variant.attribute.map((attrib: ProductAttribute): IResourceIdentifierObject => ({
                id: attrib.id,
                type: 'attribute',
                attributes: {
                  // Variant attribute needs this type to be able to be added to cart
                  'product.lists.type': 'variant',
                },
              }))
            ),
          )
          const attribRelationships = variantAttributes.map((variant: IResourceLinkage) => {
            const relationShipObject: IRelationshipObject = { data: variant }
            return {
              attribute: relationShipObject,
            }
          })
          const { data: respData, error } = await getRelationships<Product>(productVariants, 'product', config, 'POST', undefined, attribRelationships)
          addResultData('product', respData, error)
          respData.map(async (newProduct) => {
            const variant = (value as Product[])
              .find((prodVariant) => prodVariant['product.code'] === newProduct['product.code'])
            if (variant) {
              const newId = newProduct.id
              const {
                data: variantStock,
                error: stockError,
              } = await getRelationships<ProductStock>(variant.stock, 'stock', config, 'POST', { 'stock.productid': newId })
              addResultData('stock', variantStock, stockError)
              const {
                data: variantProps,
                error: variantError,
              } = await getRelationships<ProductProperty>(
                variant['product/property'],
                'product/property',
                config,
                'POST',
                { 'product.property.parentid': newId },
              )
              addResultData('product/property', variantProps, variantError)
            }
          })
          relationships.product = {
            data: respData.map((product) => ({
              id: product.id,
              type: 'product',
            })),
          }
          break
        }
        case 'price': {
          const { data: respData, error } = await getRelationships<ProductPrice>(value, 'price', config, 'POST')
          addResultData('price', respData, error)
          relationships.price = {
            data: respData.map((price) => ({
              id: price.id,
              type: 'price',
            })),
          }
          break
        }
        case 'attribute': {
          const currentAttributes = value as ProductAttribute[]
          if (currentAttributes.some((attr) => attr.id === '')) {
            const newAttrs = currentAttributes
              .filter((attr) => attr.id === '')
            const { data: addedAttrs, error } = await getRelationships<ProductAttribute>(newAttrs, 'attribute', config, 'POST')
            addResultData('attribute', addedAttrs, error)
            currentAttributes.forEach((attr) => {
              if (attr.id === '') {
                // eslint-disable-next-line no-param-reassign
                attr.id = addedAttrs.find((added) => added['attribute.code'] === attr['attribute.code'])?.id || ''
              }
            })
          }
          relationships.attribute = {
            data: currentAttributes.map((attr) => ({
              id: attr.id,
              type: 'attribute',
              attributes: {
                // Add list type if defined on attribute
                ...(attr['product.lists.type'] && { 'product.lists.type': attr['product.lists.type'] }),
                // Variant attribute needs this type to be able to be added to cart
                // Should not clash with previous line
                // which handles other cases where this is defined
                ...(attr['attribute.type'] === 'variant' && { 'product.lists.type': 'variant' }),
              },
            })),
          }
          break
        }
        default: {
          break
        }
      }
    })
    await Promise.all(promises) // Mutates the relationships object

    const url = options.meta.resources[resource]

    const body = {
      data: [data].flat().map((dataPart) => ({
        type: resource,
        attributes: dataPart,
      })),
    }

    const resp = await fetcher(`${url}${queryString}`, 'POST', JSON.stringify(body))
    const resultData = await resp.json()
    const {
      data: respData,
      error: respError,
    } = parseResponse<ResourceT>(resultData)
    addResultData(resource, respData, respError)

    // Some resources like Stock needs a prodId so has to be added after the product
    const relatedPromises: Promise<Resource[]>[] = Object.entries(data)
      .map(async ([key, value]) => {
      // TODO: Currently only supports creating one resource
        switch (key) {
          case 'stock': {
            const newProdId = respData[0].id
            const { data: stockData, error } = await getRelationships<ProductStock>(value, 'stock', config, 'POST', { 'stock.productid': newProdId })
            addResultData('stock', stockData, error)
            return stockData
          }
          case 'catalog': {
            return Promise.all(value.map(async (catalog: Catalog) => {
              // eslint-disable-next-line no-param-reassign
              catalog.product = respData as Product[]
              const [catalogData, subUpdated, subErrors] = await updateResource<Catalog, Catalog>('catalog', catalog, `&id=${catalog.id}`)
              mergeData(subUpdated, subErrors)
              return catalogData
            }).flat())
          }
          case 'media': {
            const newProdId = respData[0].id
            const mediaData = (value as ProductMedia[])
              .filter((media) => media['media.label'] !== 'uploader')
              .map(async (media) => {
                const bodyWithFile = new FormData()
                bodyWithFile.append('0', media.data as Blob)
                bodyWithFile.append('data', JSON.stringify({ 0: media }))

                const mediaResp = await fetcher(
                  `${apiUrl}/api/v1/${siteCode}/upload/product/${newProdId}`,
                  'POST',
                  bodyWithFile,
                )
                const resultData2 = await mediaResp.json()
                const {
                  data: mediaResource,
                  error: mediaError,
                } = parseResponse<ProductMedia>(resultData2)
                addResultData('media', mediaResource, mediaError)
                return mediaResource
              })
            const nested = await Promise.all(mediaData)
            return nested.flat()
          }
          case 'product/property': {
            const newProdId = respData[0].id
            const { data: propData, error } = await getRelationships<ProductProperty>(value, 'product/property', config, 'POST', { 'product.property.parentid': newProdId })
            addResultData('product/property', propData, error)
            return propData
          }
          default: {
            return []
          }
        }
      })
    await Promise.all(relatedPromises)
    return [respData, created, errors]
  }

  const deleteResource = async (resource: string, params: string | Record<string, string>) => {
    try {
      if (!options) {
        console.error('No resource links found')
        return false
      }
      if (!(resource in options.meta.resources)) {
        console.error(`Trying to update unavailable resource ${resource}`)
        console.log(options.meta.resources)
        return false
      }
      const url = options.meta.resources[resource]
      const queryString = typeof params === 'string' ? params : `&${recordToQueryString(params).join('&')}`
      const resp = await fetcher(`${url}${queryString}`, 'DELETE')
      return resp.status === 200
    } catch (error) {
      return false
    }
  }

  const deleteResourceGraphQl = async (resource: string, query: string) => {
    if (!options) {
      console.error('No resource links found')
      return false
    }
    if (!(resource in options.meta.resources)) {
      console.error(`Trying to update unavailable resource ${resource}`)
      console.log(options.meta.resources)
      return false
    }

    const resp = await fetcher(`${apiUrl}/admin/${siteCode}/graphql`, 'POST', JSON.stringify({ query }))
    return resp.json()
  }

  const getResourceOrderHistory: GetResourceOrderHistory = () => {
    const url = options?.meta?.resources.order
      ? `${options?.meta?.resources.order}&include=order/address,order/coupon,order/product,order/service`
      : null

    const { user, useRefreshCallback } = useAuth()
    const {
      data,
      error,
      mutate,
    } = useSWR<OrderResponse>(user ? url : null)

    useRefreshCallback(url || '', user ? mutate : null)

    return {
      data: data || undefined,
      isLoading: !data && !error,
      error,
    }
  }

  return {
    getAttributes,
    getResource,
    getResourceCount,
    getSingleResource,
    updateResource,
    createResource,
    createResourceGraphql,
    getResourceGraphql,
    updateResourceGraphql,
    deleteResource,
    getResourceOrderHistory,
    deleteResourceGraphQl,
  }
}

const useApi = (siteCode: string = 'default') => {
  const baseUrl = `${apiUrl}/jsonapi/${siteCode}`
  return useApiFunctions(baseUrl, siteCode)
}

const useAdminApi = (supplierCode?: string) => {
  const { user } = useAuth()
  const code = supplierCode ?? getSiteCode(user)
  const isBuyerUser = getUserType(user) === 'buyer'
  const baseUrl = (user?.superuser || code !== 'default') && !isBuyerUser
    ? `${apiUrl}/admin/${code}/jsonadm`
    : null
  return useApiFunctions(baseUrl, code)
}

export { useApi, useAdminApi }
