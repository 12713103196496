import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { captureException } from '@sentry/nextjs'

import { CountryData, LOCAL_STORAGE_COUNTRY_KEY } from '../../utils/constants'
import { findCountry } from '../../utils/countries'
import { generateCountryAndLocaleString, getCountryAndLocaleStrings } from '../../utils/locales'
import BaseModal from './BaseModal'
import CountryPickerContent from '../Layouts/Header/CountryPicker/CountryPickerContent'
import useMounted from '../../services/useMounted'
import useReplaceUrlWithCorrectLocale from '../Layouts/Header/CountryPicker/hooks/useReplaceUrlWithCorrectLocale'
import { Locale } from '../../external'

import styles from '../../styles/ChangeLanguageModal.module.less'

interface ChangeLanguageModalProps {
  container?: HTMLDivElement
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  backdropClassName?: string
}

const ChangeLanguageModal: FC<ChangeLanguageModalProps> = (props) => {
  const { container, isOpen, setIsOpen, backdropClassName } = props

  const {
    isReady,
    pathname,
    locale: countryAndLocale,
    defaultLocale: defaultCountryAndLocale,
  } = useRouter()

  const { t } = useTranslation('modals')
  const storageLocale = useRef('')
  const isMounted = useMounted()
  const replaceUrlWithCorrectLocale = useReplaceUrlWithCorrectLocale()

  const { country, locale } = getCountryAndLocaleStrings(storageLocale.current)
  const selectedCountry = findCountry(country)

  useEffect(() => {
    const storedFullLocale = localStorage.getItem(LOCAL_STORAGE_COUNTRY_KEY)
    if (!storedFullLocale) return
    storageLocale.current = storedFullLocale
  }, [])

  // On initial load, we would like to check the user's designated locale - first, if not cached
  // in the localStorage, we call the IP registry API (https://ipregistry.co/) to get the country and set the locale after a check,
  // otherwise, get the locale from the localStorage and set it after checking the country-locale
  // combination is a correct one
  useEffect(() => {
    if (!isReady) return // don't do anything if the router isn't available
    if (pathname === '/oauth/[action]/[provider]') return // Don't open on oauth redirection page

    const findRelevantLanguage = (
      localesList: Locale[],
      targetLang: Locale,
    ) => (localesList.includes(targetLang) ? targetLang : localesList[0])

    const {
      locale: urlLang,
    } = getCountryAndLocaleStrings(countryAndLocale || defaultCountryAndLocale)

    // Needs isMounted to ensure localStorage is defined
    const storedFullLocale = isMounted
      ? localStorage.getItem(LOCAL_STORAGE_COUNTRY_KEY) || countryAndLocale
      : countryAndLocale

    const {
      country: storedCountry,
      locale: storedLang,
    } = getCountryAndLocaleStrings(storedFullLocale)

    if (!storedFullLocale || !storedFullLocale.includes('-')) {
      const fetchUserLocaleFromIPRegistry = async () => {
        try {
          const resp = await fetch(process.env.NEXT_PUBLIC_IPREGISTRY_API ?? '')
          const registryData = await resp.json()
          const responseCountryLocale = registryData?.location?.country?.code

          // if a user is from any other country where Droppe doesn't provide its services,
          // choose Finland as default
          const {
            locale: countryId,
            locales,
          } = findCountry(responseCountryLocale) || CountryData[0]

          const matchedLanguage = findRelevantLanguage(locales, urlLang)
          const newCountryAndLocale = generateCountryAndLocaleString(countryId, matchedLanguage)

          localStorage.setItem(LOCAL_STORAGE_COUNTRY_KEY, newCountryAndLocale)
          replaceUrlWithCorrectLocale(matchedLanguage, countryId)
        } catch (error) {
          captureException(error)
          // In case of API failure, add the local storage value anyway with the default locale
          localStorage.setItem(LOCAL_STORAGE_COUNTRY_KEY, defaultCountryAndLocale!)
          console.error(error)
        }
      }
      // fetch country code from external IP registry API
      fetchUserLocaleFromIPRegistry()
    } else {
      const { locale: countryId, locales } = findCountry(storedCountry) || CountryData[0]
      const matchedLanguage = findRelevantLanguage(locales, storedLang)
      const newLocale = generateCountryAndLocaleString(countryId, matchedLanguage)

      if ((newLocale !== countryAndLocale)) {
        setIsOpen(true)
        replaceUrlWithCorrectLocale(matchedLanguage, countryId)
      }
    }
  }, [isReady])

  const handleCloseModal = () => {
    setIsOpen(false)
  }

  const languageNameMap = {
    fi: t('finnish'),
    sv: t('swedish'),
    de: t('german'),
    en: t('english'),
  }

  if (!storageLocale.current || !selectedCountry) {
    return null
  }

  return (
    <BaseModal
      isOpen={isOpen}
      closeModal={handleCloseModal}
      modalAction={handleCloseModal}
      title={t('Change of language and country')}
      actionButtonText={t('Continue to browse in {{ language }}', { language: languageNameMap[locale] })}
      backdrop="static"
      shouldDisplayCloseIcon={false}
      additionalClassName={styles['change-language-modal']}
      container={container}
      backdropClassName={backdropClassName}
    >
      <div className="margin-bottom-spacer-double">
        {t("If you want to visit Droppe with a different language or browse another country's catalogue, please select the desired country and language from the country picker")}
      </div>
      <div className={styles['country-picker-wrapper']}>
        <CountryPickerContent handleSelect={handleCloseModal} />
      </div>
    </BaseModal>
  )
}

export default ChangeLanguageModal
