import { FC, MouseEvent } from 'react'
import { Highlight, HitsProps, useHits } from 'react-instantsearch'
import { useRouter } from 'next/router'
import { FlexboxGrid, List } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'

import { getCountryAndLocaleStrings } from '../../utils/locales'
import useUrls from '../../services/useUrls'
import Img from '../Img'
import { TypesenseSupplier } from '../../utils/types/search'
import searchResultClickEvent from '../../utils/analytics/searchResultClickEvent'

import styles from '../../styles/Search.module.less'

interface SupplierHitsProps extends HitsProps<TypesenseSupplier> {
  closeModal: () => void
  triggerSearchEvent: () => void
}

const SupplierHits: FC<SupplierHitsProps> = (props) => {
  const { closeModal, triggerSearchEvent, ...defaultProps } = props
  const { locale: countryAndLocale } = useRouter()
  const { locale, country } = getCountryAndLocaleStrings(countryAndLocale)
  const { urlT } = useUrls()
  const { t } = useTranslation('products')

  const {
    hits,
    results,
  } = useHits(defaultProps)

  const url = (code: string) => urlT(`/supplier/${code}`)

  const handleSearchResultClick = (
    event: MouseEvent<HTMLAnchorElement>,
    hit: TypesenseSupplier,
  ) => {
    if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button === 0) {
      closeModal()
    }

    // Trigger search event if it hasn't yet been triggered
    triggerSearchEvent()

    // Analytics event
    searchResultClickEvent({
      searchTerm: results?.query || '',
      locale,
      country,
      resultQuantity: results?.nbHits || 0,
      supplier: hit.name,
      clickType: event.button === 2 ? 'right' : undefined,
      context: 'search_modal',
      searchDomain: 'supplier',
    })
  }

  return (
    <>
      <FlexboxGrid
        align="middle"
        justify="space-between"
      >
        <FlexboxGrid.Item>
          <h5>{t('Suppliers')}</h5>
        </FlexboxGrid.Item>
        { results && (
          <FlexboxGrid.Item>
            {t('common:Showing {{ hits }} of {{ total }} results', { hits: results.hits.length, total: results.nbHits })}
          </FlexboxGrid.Item>
        ) }
      </FlexboxGrid>
      <List
        size="sm"
        className={styles['hits-list']}
        data-testid="search-supplier-hits"
      >
        { hits.map((hit) => (
          <List.Item
            key={hit.id}
            data-testid="search-supplier-hit"
          >
            <Link
              onClick={(event) => handleSearchResultClick(event, hit)}
              // Track right clicks
              onContextMenu={(event) => handleSearchResultClick(event, hit)}
              href={url(hit.code)}
            >
              <FlexboxGrid
                align="middle"
                justify="start"
              >
                <FlexboxGrid.Item className={styles['image-container']}>
                  <Img
                    src={hit.image}
                    layout="fill"
                  />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                  <Highlight
                    hit={hit}
                    attribute="name"
                  />
                  <br />
                  <h6>
                    {hit[`description_${locale ?? 'en'}`]?.slice(0, 50)}
                    ...
                  </h6>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </Link>
          </List.Item>
        )) }
      </List>
    </>
  )
}

export default SupplierHits
