import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { useRouter } from 'next/router'

import { useAuth } from './useAuth'
import { useCart } from '../components/Cart/CartProvider'
import useExchangeRate from './useExchangeRate'
import { getFullProductPrice, getTakeRatePercentage } from '../lib/resources/price'
import { roundToTwoDecimals } from '../utils/math'
import { getCartMaxPrice } from '../lib/cart'
import { Product, ProductPrice } from '../utils/types/Product'
import { CONSUMPTION_TAKE_RATE_TIERS } from '../utils/constants'
import { getCurrencyFromSelectedCountry, getIsImportedProduct } from '../lib/product'
import { getCountryAndLocaleStrings } from '../utils/locales'

export type CalculateTakeRate = (
  product: Product,
  selectedQuantity: number,
  excludeAmount?: number
) => {
  product: Product
  prices: ProductPrice[]
}

/**
 * Return a function that modifies the prices of the product to tweak take rate based
 * on selected quantity
 */
const useTakeRate = (): CalculateTakeRate => {
  const { consumptionTier } = useAuth()
  const { modifiedCart } = useCart()
  const { locale: countryAndLocaleString } = useRouter()
  const { country } = getCountryAndLocaleStrings(countryAndLocaleString)
  const currentCurrencySymbol = getCurrencyFromSelectedCountry(country)
  const exchangeRate = useExchangeRate(currentCurrencySymbol)
  const isTakeRateEnabled = useFeatureIsOn('consumption-based-take-rate')

  return (
    product: Product,
    selectedQuantity: number,
    excludeAmount?: number,
  ) => {
    if (!isTakeRateEnabled || getIsImportedProduct(product)) {
      return {
        product,
        prices: product.price,
      }
    }

    const cartTotal = modifiedCart ? getCartMaxPrice(modifiedCart) : 0

    const tier = ((consumptionTier ?? 0)
      + (getFullProductPrice(product) * selectedQuantity * (1 / (exchangeRate || 1)))
      + (cartTotal ?? 0) * (1 / (exchangeRate || 1))
      - ((excludeAmount ?? 0) * (1 / (exchangeRate || 1))))

    const prices = product?.price.map((price) => {
      // Fill up to minimum quantity as lower quantity is not possible
      const moqFillUp = selectedQuantity < price['price.quantity']
        ? (getFullProductPrice(product) * (Number(price['price.quantity']) - selectedQuantity)) / (exchangeRate || 1)
        : 0
      const maxPrice = Number(price['price.value']) + Number(price['price.rebate'])
      const takeRate = getTakeRatePercentage(tier + moqFillUp)
      const maxPercentage = Math.max(...Object.values(CONSUMPTION_TAKE_RATE_TIERS))
      // First remove take rate added in BE to be able to change it
      const priceWithoutTakeRate = maxPrice
        * (1 - maxPercentage)
      // Then add take rate back with new rate
      const priceWithTakeRate = roundToTwoDecimals(priceWithoutTakeRate / (1 - takeRate))
      return {
        ...price,
        'price.label': `${price['price.label']} - take rate tier ${tier + moqFillUp}`,
        'price.value': priceWithTakeRate.toFixed(2),
        'price.rebate': roundToTwoDecimals(priceWithoutTakeRate / 0.75 - priceWithTakeRate).toFixed(2),
      }
    })

    return {
      prices,
      product: {
        ...product,
        price: prices,
      },
    }
  }
}

export default useTakeRate
