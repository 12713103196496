import { useEffect, useRef, useState } from 'react'

const useMounted = () => {
  const mountedRef = useRef(false)
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    if (mountedRef.current === false) {
      mountedRef.current = true
      setIsMounted(true)
    }

    return () => {
      mountedRef.current = false
      setIsMounted(false)
    }
  }, [])
  return isMounted
}

export default useMounted
