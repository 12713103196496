import { useRouter } from 'next/router'

import useSupplierProfileData from '../components/hooks/useSupplierProfileData'
import { useAuth } from './useAuth'
import { SUPPLIER_TERMS_PATH } from '../utils/constants'

const IGNORE_ONBOARDING_URLS_LIST = ['/email/verify', '/password', '/logout', '/apply', SUPPLIER_TERMS_PATH]

export default (isShippingFirstMount: boolean) => {
  const { user, isLoadingUser } = useAuth()
  const router = useRouter()
  const {
    fetchedSupId,
    isDeliveryServiceLoading,
    isSupplierInfoLoading,
    supplierInfo,
    deliveryData,
  } = useSupplierProfileData()

  const isOnIgnoreUrl = IGNORE_ONBOARDING_URLS_LIST.some((url) => router.pathname.startsWith(url))

  const isSupplierDataLoading = isDeliveryServiceLoading || isSupplierInfoLoading

  const supplierInfoTextArr = supplierInfo?.[0]?.text
  const isSupplierDataAvailable = isShippingFirstMount && Object.keys(deliveryData?.['service.config'] || []).length
  const isNoSupplierStoryAvailable = (
    !isSupplierDataLoading
     && (!supplierInfoTextArr?.length || !supplierInfoTextArr?.[0]['text.content'])
  )
  const isNoDeliveryDataAvailable = (!isSupplierDataLoading && !isSupplierDataAvailable)

  const isDataMissing = !user?.firstname || isNoSupplierStoryAvailable || isNoDeliveryDataAvailable

  if (isLoadingUser
      || isSupplierDataLoading
      || !isDataMissing
      || isOnIgnoreUrl
      || fetchedSupId === -1
  ) {
    return false
  }

  return true
}
