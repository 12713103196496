import { useEffect, useState } from 'react'

import { useAuth } from './useAuth'
import { useAdminApi } from './useApi'
import { Customer, CustomerRelationList, Order } from '../utils/types/Product'
import { createUrlParams } from '../lib/params'
import { SELECTED_CUSTOMER_ID_STORAGE_KEY } from '../utils/constants'

export interface AdminCustomerFunctions {
  customerList: Customer[]
  selectedCustomer: Customer | undefined
  selectedCustomerSavedList: CustomerRelationList[]
  selectedCustomerOrders: Order[]
  customerAccountUsers: Customer[]
  isLoading: boolean
  isLoadingCustomer: boolean
  setSelectedId: (customer: Customer | null | undefined) => void
  setSearchWord: (data: string) => void
}

const useAdminCustomers = (shouldPersist = true): AdminCustomerFunctions => {
  const { user } = useAuth()
  const { getResource } = useAdminApi()
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null | undefined>(null)
  const [persistedId, setPersistedId] = useState<string>()
  const isSuperUser = (user?.superuser ?? 0) === 1
  const [searchWord, setSearchWord] = useState('')

  useEffect(() => {
    const storedId = sessionStorage.getItem(SELECTED_CUSTOMER_ID_STORAGE_KEY)
    if (storedId) {
      setPersistedId(storedId)
    }
  }, [])
  const urlParams = createUrlParams({
    include: 'customer',
    filter: {
      '||': [{
        '&&': [
          { '!': [{ '~=': { field: 'customer.siteid', value: '1.' } }] },
          { '==': { field: 'customer.status', value: '1' } },
          ...(searchWord ? [{ '~=': { field: 'customer.company', value: searchWord } }] : []
          ),
        ],
      }, {
        '&&': [
          { '!': [{ '~=': { field: 'customer.siteid', value: '1.' } }] },
          { '==': { field: 'customer.status', value: '1' } },
          { '||': [{ '~=': { field: 'customer.code', value: searchWord.replace(/\+/g, '%2B') } }] },
        ],
      },
      ...(persistedId ? [{ '==': { field: 'customer.id', value: persistedId } }] : []
      ),
      ],
    },
    page: { limit: '200' },
  })
  const { data, isLoading } = getResource<Customer>(isSuperUser ? 'customer' : null, urlParams)

  useEffect(() => {
    if (sessionStorage && shouldPersist) {
      const persisted = sessionStorage.getItem(SELECTED_CUSTOMER_ID_STORAGE_KEY) ?? persistedId
      if (persisted && data) {
        const persistedCustomer = data.find((customer) => customer.id === persisted)
        if (persistedCustomer && persistedCustomer.id !== selectedCustomer?.id) {
          setSelectedCustomer(persistedCustomer)
        }
      }
    }
  }, [data])

  const {
    data: selectedCustomers,
    isLoading: isLoadingCustomer,
  } = getResource<Customer>(selectedCustomer ? 'customer' : null, `&filter[%3D%3D][customer.parentid]=${selectedCustomer?.['customer.parentid']}&include=product`)

  const customerIdFilterQuery = selectedCustomers.map((customer) => `&filter[%7C%7C][][%3D%3D][order.customerid]=${customer.id}`)
  const {
    data: selectedCustomerOrders,
  } = getResource<Order>(selectedCustomer ? 'order' : null, `include=order,order/product${customerIdFilterQuery.join('')}`)

  const selectedCustomerSavedList = selectedCustomers
    ?.flatMap((customer) => (
      customer.product
        ?.filter((product) => product?.['customer.lists.type'] === 'favorite')
        ?.map((product) => ({
          'customer.lists.refid': product?.id || '',
          'customer.lists.domain': 'product',
          'customer.lists.type': 'favorite',
          'customer.lists.datestart': null,
          'customer.lists.dateend': null,
          'customer.lists.position': 0,
          'customer.lists.status': 1,
          'customer.lists.parentid': customer.id,
          'customer.lists.editor': '',
          'customer.lists.id': product['customer.lists.id'] || '',
        })) || []
    )) || []

  const setSelectedId = (customer: Customer | null | undefined) => {
    setSelectedCustomer(customer)
    if (sessionStorage && customer && shouldPersist) {
      setPersistedId(customer.id)
      sessionStorage.setItem(SELECTED_CUSTOMER_ID_STORAGE_KEY, customer.id)
    }
  }

  const filteredCustomerData = data
    .filter((customer) => !customer['customer.superuser'])

  if (selectedCustomer
    && !(filteredCustomerData?.find((customer) => customer.id === selectedCustomer.id))
  ) {
    filteredCustomerData?.push(selectedCustomer)
  }

  return {
    // Filter out accounts with no company name defined TODO: filter by superuser on BE side
    customerList: filteredCustomerData,
    selectedCustomer: selectedCustomer ?? undefined,
    selectedCustomerSavedList,
    selectedCustomerOrders,
    customerAccountUsers: selectedCustomers,
    isLoading,
    isLoadingCustomer,
    setSelectedId,
    setSearchWord,
  }
}

export default useAdminCustomers
