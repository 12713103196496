import TagManager from 'react-gtm-module'

import { AnalyticsContext } from '../types/analytics'

const viewLoginModal = (context: AnalyticsContext = '') => {
  TagManager.dataLayer({ dataLayer: {
    event: 'view_login_modal',
    context,
  } })
}

export default viewLoginModal
