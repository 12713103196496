import { MODAL_HASHES } from '../../../utils/constants'
import { Product } from '../../../utils/types/Product'
import { AnalyticsFunction, AnalyticsLocation } from '../../../utils/types/analytics'
import useModal from './useModal'

interface UseRFQModal {
  product?: Product
  analyticsLocation?: AnalyticsLocation
}

const useRFQModal = (params: UseRFQModal) => {
  const { product, analyticsLocation } = params

  const openRFQModal = useModal({
    analyticsLocation,
    analyticsFunction: AnalyticsFunction.RFQ,
    urlHash: MODAL_HASHES.RFQ_URL_HASH,
    product,
  })

  return openRFQModal
}

export default useRFQModal
