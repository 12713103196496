import TagManager from 'react-gtm-module'

import { AnalyticsContext, ContactContext } from '../types/analytics'

// Push event to datalayer every time language is changed
const contactEvent = (
  contactContext: ContactContext,
  analyticsContext?: AnalyticsContext,
) => {
  // TODO: Remove contact_context after 2 months once
  // context param has been sufficiently populated
  TagManager.dataLayer({
    dataLayer: {
      event: 'contact_event',
      contact_context: contactContext, // Deprecated
      context: analyticsContext,
    },
  })
}

export default contactEvent
