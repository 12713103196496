import { Translate } from 'next-translate'
import { ValueType } from 'rsuite/esm/Radio'

import { isDefined } from '../utils/types/misc'
import { Catalog, CategoryTreeCatalog, Product } from '../utils/types/Product'
import { DataItemType } from '../utils/types/rsuite'
import { getOrderedProductCategories } from './product'

export const getCatalog = (
  code: string,
  catalogs: Catalog[],
  t: Translate,
): Catalog | undefined => (
  catalogs.find((cat) => (
    // Accept both translated and default catalog code
    cat['catalog.code'] === code || t(`catalog:${cat['catalog.code']}`) === code))
  || catalogs.map((cat) => getCatalog(code, cat.catalog || [], t)).find(isDefined)
)

export const getCatalogName = (
  filterCatName: ValueType,
  catalog: Catalog[] | undefined,
  t: Translate,
): string | undefined => {
  if (!catalog) return undefined
  const childCatalogName = catalog
    .map((cat) => getCatalogName(filterCatName, cat.catalog, t))
    .find(isDefined)

  return (
    (catalog.find((cat) => (
      cat['catalog.code'] === filterCatName))
      ?.['catalog.label'])
      || childCatalogName
  )
}

export const selectDefaultCategory = (
  selectedCategory: string,
  categories: Catalog[],
): string[] => {
  const isInSelected = (cat: Catalog) => (
    selectedCategory === cat['catalog.code']
  )

  const findByChildren = (cat: Catalog): string[] => {
    if (isInSelected(cat)) return [cat['catalog.code']]
    return cat.catalog?.flatMap((child) => findByChildren(child)) || []
  }
  return categories.flatMap(
    (cat) => findByChildren(cat),
  )
}

export const createCatalogItemType = (
  current: Catalog,
  list: Catalog[],
): DataItemType | undefined => {
  const children = current.catalog?.map(
    (child: Catalog) => createCatalogItemType(child, list),
  ).filter(isDefined) || []
  if (children.length !== 0) {
    return {
      value: current['catalog.code'],
      label: current['catalog.label'],
      children,
    }
  }
  return {
    value: current['catalog.code'],
    label: current['catalog.label'],
  }
}

export const getFilteredCategories = (
  categories: Catalog[],
): DataItemType[] => (
  categories.flatMap((topCategory: Catalog) => (
    topCategory.catalog
      ?.map((child: Catalog) => (
        createCatalogItemType(child, categories)))
      .filter(isDefined) || []
  ))
)

export const formatCategoriesForAnalytics = (product: Product) => {
  const orderedCategories = getOrderedProductCategories(product)
  return orderedCategories.reduce<Record<string, string>>(
    (labels, cat, index) => {
      let categoryNumber: number | string = index + 1

      // Remove category number from first category to adhere to Google's GA4 specs
      if (index === 0) {
        categoryNumber = ''
      }

      return {
        ...labels,
        [`item_category${categoryNumber}`]: cat['catalog.code'],
      }
    }, {},
  )
}

export const removeHomeCategory = (categories: Catalog[]) => categories.filter((cat) => cat['catalog.code'] !== 'home')
export const parseCategoryTreeData = (
  catalogArray: Catalog[],
  level = 1,
): CategoryTreeCatalog[] => catalogArray.reduce(
  (
    previousValue: CategoryTreeCatalog[],
    current: Catalog,
  ) => {
    const catalog = level < 3
      ? parseCategoryTreeData(current.catalog || [], level + 1)
      : []

    return [...previousValue, {
      id: current.id,
      'catalog.code': current['catalog.code'],
      'catalog.label': current['catalog.label'],
      catalog,
    }]
  }, [],
)

export const findLabelFromCategoryTreeData = ({
  data,
  mainCategory,
  subCategory,
}: {
  data: CategoryTreeCatalog[] | undefined
  mainCategory: string
  subCategory?: string
}): string | null => {
  // Return only main category label if no subCategory is provided
  if (!subCategory) {
    return data?.find((cat) => cat['catalog.code'] === mainCategory)?.['catalog.label'] || null
  }

  // Find subCategory label from main category
  const topLevelCategory = data?.find((cat) => cat['catalog.code'] === mainCategory)
  if (!topLevelCategory) return null

  let foundSubCategory: CategoryTreeCatalog | undefined

  topLevelCategory.catalog.some((level2Cat) => {
    // Check if subCategory is a level 2 category
    if (level2Cat['catalog.code'] === subCategory) {
      foundSubCategory = level2Cat
      return true
    }

    // Check if subCategory is a level 3 category
    const level3Cat = level2Cat.catalog.find((cat) => cat['catalog.code'] === subCategory)
    if (level3Cat) {
      foundSubCategory = level3Cat
      return true
    }

    return false
  })

  return foundSubCategory ? foundSubCategory['catalog.label'] : null
}
