import { AnalyticsLocation, AnalyticsSubLocationValue } from '../../utils/types/analytics'
import useGetAnalyticsPageLocation from './useGetAnalyticsPageLocation'

const useGetAnalyticsLocation = (
  analyticsSubLocation: AnalyticsSubLocationValue,
): AnalyticsLocation => {
  const analyticsLocation = useGetAnalyticsPageLocation()
  return analyticsLocation ? `${analyticsLocation}_${analyticsSubLocation}` : analyticsSubLocation
}

export default useGetAnalyticsLocation
