import { useState, useEffect } from 'react'
import throttle from 'lodash/throttle'

const useHasWindowScrolledToTop = (): boolean => {
  const [hasScrolledToTop, setHasScrolledToTop] = useState<boolean>(true)

  const handleScroll = throttle(() => {
    const isScrolledToTop = window.scrollY === 0
    setHasScrolledToTop(isScrolledToTop)
  }, 200)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return hasScrolledToTop
}

export default useHasWindowScrolledToTop
