import TagManager from 'react-gtm-module'

const submitPasswordReset = (email?: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'submit_password_reset',
      email,
    },
  })
}

export default submitPasswordReset
