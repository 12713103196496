import { isSuperUser, isSupplier } from '../../lib/supplier'
import { UserI } from '../types/auth'

const getUserType = (user: UserI | null) => {
  if (!user) {
    return ''
  }

  if (isSuperUser(user)) {
    return 'superuser'
  }

  if (isSupplier(user)) {
    return 'supplier'
  }

  return 'buyer'
}

export default getUserType
