import { useEffect, useState } from 'react'

import exchangeRateApiRequest from '../utils/fetch-exchange-rate'
import { Currency } from '../utils/constants'
import { CurrencySymbol } from '../utils/types/Product'
import useMounted from './useMounted'

/**
 * Fetches and stores exchange rate for converting currencies.
 * Currently only supports SEk (and defaulting to 1 for EUR)
 * @param currentCurrencySymbol € or kr
 * @returns number
 */
const useExchangeRate = (currentCurrencySymbol: CurrencySymbol): number => {
  const [sekExchangeRate, setSekExchangeRate] = useState<number | null>(1)
  const isMounted = useMounted()

  const fetchExchangeRate = async (): Promise<number | null> => {
    if (sekExchangeRate && sekExchangeRate !== 1) return sekExchangeRate

    // Make sure request is only ever triggered once,
    // even if request is not completed during additional renders
    setSekExchangeRate(null)

    const rate = await exchangeRateApiRequest('EUR', 'SEK')
    if (rate) {
      sessionStorage.setItem('exchange-rate', rate.toString())
    }
    setSekExchangeRate(rate)

    return rate
  }

  useEffect(() => {
    const fetchRate = async () => {
      if (sekExchangeRate === 1 && currentCurrencySymbol !== Currency.EUR) {
        await fetchExchangeRate()
      }
    }
    if (isMounted) {
      fetchRate()
        .then(() => {
          if (typeof window !== 'undefined') {
            setSekExchangeRate(Number(sessionStorage.getItem('exchange-rate')) || 1)
          }
        })
    }
  }, [currentCurrencySymbol, isMounted])

  return currentCurrencySymbol !== Currency.EUR ? Number(sekExchangeRate || 1) : 1
}

export default useExchangeRate
