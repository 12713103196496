import { MODAL_HASHES } from '../../../utils/constants'
import { Product } from '../../../utils/types/Product'
import { AnalyticsFunction, AnalyticsLocation } from '../../../utils/types/analytics'
import useModal from './useModal'

interface UseSampleRequestModal {
  product?: Product
  analyticsLocation?: AnalyticsLocation
}

const useSampleRequestModal = (params: UseSampleRequestModal) => {
  const { product, analyticsLocation } = params

  const openSampleRequest = useModal({
    analyticsLocation,
    analyticsFunction: AnalyticsFunction.SampleRequest,
    urlHash: MODAL_HASHES.SAMPLE_REQUEST_URL_HASH,
    product,
  })

  return openSampleRequest
}

export default useSampleRequestModal
