import { useEffect, useMemo } from 'react'
import useSWR, { KeyedMutator } from 'swr'
import { useRouter } from 'next/router'

import { ICartLinks, IOptionsDocument } from '../../utils/types/aimeosApi'
import { useApi } from '../../services/useApi'
import { useAuth } from '../../services/useAuth'
import { getCountryAndLocaleStrings } from '../../utils/locales'
import { CountryData, ProductBaseIncludes, ResourceTag, SANCTUM_CSRF_ENDPOINT, XSRF_COOKIE_NAME } from '../../utils/constants'
import { Product, Supplier } from '../../utils/types/Product'
import { decycle } from '../../services/useApiHelpers'
import fetcher from '../../utils/fetcher'
import { isProductInclude } from '../../utils/types/guards/Order'
import { CartResponse } from '../../utils/types/Order'
import { filterByImportedProduct, getImportedProductIdsInCart, getProductIdsInCart } from './utils/getProductIdsInCart'

interface InitCart {
  cartResult: CartResponse | undefined
  error: any
  mutate: KeyedMutator<CartResponse>
  isCartValidating: boolean
  cartProducts: Product[]
  cartSuppliers: Supplier[]
  links: ICartLinks[]
  isLoading: boolean
}

const getProductsQuery = (productIds: string[]) => {
  const prodsFilter = productIds ? `&ids=${(productIds).join(',')}` : ''
  return `include=${ProductBaseIncludes.join(',')}${prodsFilter}`
}

const useInitCart = (): InitCart => {
  const { getResource } = useApi()
  const { user } = useAuth()

  const apiUrl = process.env.NEXT_PUBLIC_API_URL
  const baseUrl = `${apiUrl}/jsonapi/default`

  const { locale: countryAndLocale } = useRouter()

  const { country, locale } = useMemo(
    () => getCountryAndLocaleStrings(countryAndLocale),
    [countryAndLocale],
  )
  const countryInfo = useMemo(
    () => CountryData.find((ele) => ele.locale === country),
    [country],
  )

  const fetchParams = useMemo(() => ({ method: 'OPTIONS' }), [])

  // Hard coding the locale to en for now, new basket is getting created on each locale change
  // TODO: Fix this to use the locale from the router
  const { data: options } = useSWR<IOptionsDocument>([
    `${baseUrl}?currency=${countryInfo?.currency || 'EUR'}&locale=${locale}`,
    JSON.stringify(fetchParams),
  ])

  const cartUrl = options?.meta?.resources.basket
  const {
    data: cartResult,
    error,
    mutate,
    isValidating: isCartValidating,
  } = useSWR<CartResponse>(cartUrl || null)

  const getProducts = () => {
    const productIds = cartResult?.included ? getProductIdsInCart(cartResult.included) : []
    const productsQuery = getProductsQuery(productIds)

    return getResource<Product>(
      cartResult?.included?.filter(isProductInclude).length ? ResourceTag.product : null,
      productsQuery,
    )
  }

  const getExternalProducts = () => {
    const productIds = cartResult?.included ? getImportedProductIdsInCart(cartResult.included) : []
    const productsQuery = getProductsQuery(productIds)

    return getResource<Product>(
      filterByImportedProduct(cartResult?.included || []).length ? ResourceTag.product : null,
      productsQuery,
      { countrySpecific: false }, // Disable country specific filtering for imported products
    )
  }
  const { data: products, links, isLoading: areProductsLoading } = getProducts()
  const {
    data: importedProducts,
    links: importedProductLinks,
    isLoading: areImportedProductsLoading,
  } = getExternalProducts()

  const suppliers = [...products, ...importedProducts].flatMap((product) => product.supplier || [])

  useEffect(() => {
    if (products) {
      decycle(ResourceTag.product, products)
    }
  }, [products])

  useEffect(() => {
    // If user is not logged in, initialize a session so that cart is persisted
    if (user && !document.cookie.includes(XSRF_COOKIE_NAME)) {
      fetcher(`${apiUrl}${SANCTUM_CSRF_ENDPOINT}`)
    }
  }, [apiUrl, user])

  return {
    cartResult,
    error,
    mutate,
    isCartValidating,
    cartProducts: [...products, ...importedProducts],
    cartSuppliers: suppliers,
    links: [...links, ...importedProductLinks],
    isLoading: (!cartResult && !error) || areProductsLoading || areImportedProductsLoading,
  }
}

export default useInitCart
