import { FC } from 'react'
import { Container } from 'rsuite'
import { UrlObject } from 'url'

import useUrls from '../../../../services/useUrls'

import styles from '../../../../styles/MegaMenu.module.less'

interface MegaMenuLinkProps {
  label: string
  linkRedirectObjectData: UrlObject
  additionalClassNames?: string
  externalCallback?: () => void
}

const MegaMenuLink: FC<MegaMenuLinkProps> = (props) => {
  const { label, linkRedirectObjectData, additionalClassNames, externalCallback } = props

  const { pushT } = useUrls()

  const handleMegaMenuLinkClick = () => {
    pushT(linkRedirectObjectData, undefined, { shallow: true })
    if (externalCallback) externalCallback()
  }

  return (
    <Container
      role="link"
      className={`${styles['megamenu-link']} ${additionalClassNames} margin-bottom-spacer`}
      onClick={handleMegaMenuLinkClick}
    >
      {label}
    </Container>
  )
}

export default MegaMenuLink
