const CarIcon = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.83398 4H10.9175C12.5744 4 13.9175 5.34315 13.9175 7V12.8385C13.9175 13.3908 13.4698 13.8385 12.9175 13.8385H4.50068C3.9484 13.8385 3.50068 13.3908 3.50068 12.8385V8.91926"
      stroke="#575757"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <path
      d="M1 6.68323H5.58342"
      stroke="#575757"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <circle
      cx="7.54688"
      cy="13.6553"
      r="1.9"
      fill="white"
      stroke="#575757"
      strokeWidth="1.2"
    />
    <path
      d="M14.0351 6.68323H17.7232C18.4263 6.68323 19.0777 7.05239 19.439 7.65554L20.5732 9.54906C20.8522 10.0149 20.9995 10.5476 20.9995 11.0906V12.8385C20.9995 13.3908 20.5518 13.8385 19.9995 13.8385H12.666"
      stroke="#575757"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <circle
      cx="16.8359"
      cy="13.6553"
      r="1.9"
      fill="white"
      stroke="#575757"
      strokeWidth="1.2"
    />
  </svg>

)

export default CarIcon
