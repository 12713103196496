import useSWR from 'swr'
import { useRouter } from 'next/router'

import { MegaMenuFormattedData, MegaMenuStrapiResp } from '../../utils/types/strapi'
import { strapiFetcher } from '../../utils/fetcher'
import { buildStrapiUrl, formatColumnLinks } from '../../utils/strapi'
import { getCountryAndLocaleStrings } from '../../utils/locales'

const useMegaMenuData = () => {
  const { locale: countryAndLocaleString } = useRouter()
  const { locale, country } = getCountryAndLocaleStrings(countryAndLocaleString)

  const apiUrl = buildStrapiUrl({
    endpoint: 'mega-menus',
    locale,
    queryParams: `&populate[Column2][populate][0]=linkData&populate[Column3][populate][0]=linkData&populate[Column4][populate][0]=image,ctaLink&populate=product_category&populate[country]=*&filter&filters[country][countryCode][$eqi]=${country}`,
  })

  const { data: strapiResp, error } = useSWR<MegaMenuStrapiResp>(apiUrl, strapiFetcher)

  if (error) {
    console.error(error)
  }

  if (!strapiResp || !strapiResp.data) {
    return {}
  }

  const { data: strapiDataArr } = strapiResp

  const formattedMegaMenuStrapiData = strapiDataArr.reduce<Record<string, MegaMenuFormattedData>>(
    (strapiDataMap, { attributes }) => {
      const {
        Column2,
        Column3,
        Column4,
        product_category: productCategoryRelations,
      } = attributes || {}
      const { data: relationsData } = productCategoryRelations || {}
      const { attributes: productCategoryAttributes } = relationsData || {}

      if (!productCategoryAttributes?.code.startsWith('c_')) {
        console.error(`1st level category ${productCategoryAttributes?.code} is not correctly coded, should have the 'c_' predicate`)
      }

      return {
        ...strapiDataMap,
        [productCategoryAttributes?.code]: {
          popularBrandsColumn: formatColumnLinks(Column2),
          promotionalCollectionsColumn: formatColumnLinks(Column3),
          ctaColumn: Column4,
        },
      }
    }, {},
  )

  return formattedMegaMenuStrapiData
}

export default useMegaMenuData
