import { FC } from 'react'
import { Panel } from 'rsuite'

import ProductOrderInfo from '../../ProductOrderInfo'
import { getProductManualStockLimit, getBackInStockDate, getTotalProductQuantity } from '../../../lib/product'
import { getProductName } from '../../../lib/text'
import { isProductInclude } from '../../../utils/types/guards/Order'
import { OrderInclude, OrderProductInclude } from '../../../utils/types/Order'
import useTakeRate from '../../../services/useTakeRate'

interface ProductInfoProps {
  cartProduct: OrderProductInclude
  included?: OrderInclude[]
}

const ProductInfo: FC<ProductInfoProps> = ({ cartProduct, included }) => {
  const calculateTakeRate = useTakeRate()

  if (!cartProduct?.product) {
    return null
  }
  const { product } = calculateTakeRate(cartProduct.product, 0)

  const productLabel = getProductName(product) ?? ''
  const manualStockLimit = Number(getProductManualStockLimit(product)) ?? 0
  const stockDate = getBackInStockDate(product)

  const productObject = {
    productId: product?.id,
    timeframe: '', // For not having ts error
  }

  // If product with variants
  const productQuantity = cartProduct.attributes.attribute
    ? product.product?.map((prod) => {
      const addedVariant = included?.filter(isProductInclude)?.find(
        (item) => prod['product.code'] === item.attributes?.['order.product.prodcode'],
      )

      const manualLimit = Number(
        product['product/property']?.find(
          (prop) => prop['product.property.type'] === 'manual-stock-limit',
        )?.['product.property.value'],
      ) ?? manualStockLimit

      if (addedVariant) {
        return (
          {
            ...productObject,
            label: addedVariant.attributes.attribute
              ? addedVariant.attributes?.attribute[0]?.[
                'order.product.attribute.name'
              ]
              : '',
            quantity: addedVariant.attributes['order.product.quantity'],
            id: addedVariant.id.toString(),
            manualStockLimit: manualLimit,
            stockDate: getBackInStockDate(product),
            variantId: addedVariant.id.toString(),
          }
        )
      }

      return (
        {
          ...productObject,
          quantity: 0,
          manualStockLimit: manualLimit,
          stockDate: getBackInStockDate(prod),
          id: prod['product.code'],
          variantId: prod.attribute?.find((variantAttribute) => (
            // Variant attributes can be color or size. Old data can also have variant
            // TODO: Remove variant option when data is updated
            ['color', 'size', 'variant'].includes(variantAttribute['attribute.type'])))?.id ?? '',
          label: prod['product.label'],
          newVariant: true,
        }
      )
    }) ?? []
    : [{
      ...productObject,
      label: productLabel,
      quantity: cartProduct.attributes['order.product.quantity'],
      id: cartProduct.id.toString(),
      manualStockLimit,
      stockDate,
    }]

  const totalQuantity = getTotalProductQuantity(productQuantity)

  return (
    <Panel
      bordered
      className="margin-top-spacer-double"
    >
      <ProductOrderInfo
        product={product}
        productQuantity={productQuantity}
        totalQuantity={totalQuantity}
        isStockLimitReached={false}
        enableWarningBar
      />
    </Panel>
  )
}

export default ProductInfo
