import { FC } from 'react'

import useIsOnMobile from '../../services/useIsOnMobile'
import { ScreenSize } from '../../utils/constants'

import styles from '../../styles/EmptyState.module.less'

const ErrorBoundaryGraphic: FC = () => {
  const IsOnMobile = useIsOnMobile(ScreenSize.lg)
  return (
    <img
      className={`${styles['empty-state-error']}`}
      data-testid="error-boundary-graphic"
      alt="Error"
      src="/fallbackGraphics/error-boundary.svg"
      height={IsOnMobile ? 120 : 201}
    />
  )
}

export default ErrorBoundaryGraphic
