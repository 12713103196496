import useTranslation from 'next-translate/useTranslation'
import { FC } from 'react'

import ShieldCheckIcon from './Icons/ShieldCheckIcon'
import { UnFilledCheckIcon } from './Icons/CheckIcon'

import styles from '../styles/SatisfactionGuarantee.module.less'

interface SatisfactionGuaranteeProps {
  iconType?: 'shield' | 'circle'
  className?: string
  variant?: 'dark' | 'light'
  shieldIconClassName?: string
}

const SatisfactionGuarantee: FC<SatisfactionGuaranteeProps> = ({
  iconType = 'shield',
  className = '',
  variant = 'light',
  shieldIconClassName = '',
}) => {
  const { t } = useTranslation('common')

  const isDarkVariant = variant === 'dark'

  let Icon = () => <ShieldCheckIcon className={shieldIconClassName} />

  if (iconType === 'circle') Icon = UnFilledCheckIcon

  return (
    <div
      className={`
        align-middle
        ${className}
        ${styles.container}
        ${isDarkVariant ? styles['variant-dark'] : ''}
      `}
    >
      <Icon />
      <span className="margin-left-spacer">{t('Satisfaction guarantee')}</span>
    </div>
  )
}

export default SatisfactionGuarantee
