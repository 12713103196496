import TagManager from 'react-gtm-module'

import { Catalog } from '../types/Product'

export interface Preference {
  name: string
  category_code?: string // Optional case "Other" is selected
  info?: string // Add when "Other" is selected
}

const addPreferencesBuyerOnboarding = (categories: Catalog[], info?: string) => {
  const categoryList = categories.map((cat) => ({
    category_name: cat['catalog.label'],
    category_code: cat['catalog.code'],
  }))

  const categoryCodesString = categoryList.map((cat) => cat.category_code).join('|')

  TagManager.dataLayer({
    dataLayer: {
      event: 'add_preferences_buyer_onboarding',
      preferences: categoryList,
      category_codes: categoryCodesString,
      additional_info: info || '',
    },
  })
}

export default addPreferencesBuyerOnboarding
