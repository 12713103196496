import { FC } from 'react'
import { Button } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'

import useLoginModal from '../../../../services/useLoginModal'

import styles from '../../../../styles/SignInPicker.module.less'

const SignInPickerContent: FC = () => {
  const { t } = useTranslation('auth')
  const { openLoginModal, openRegisterModal } = useLoginModal()

  return (
    <div className={styles['content-container']}>
      <div className={styles['text-section']}>
        <h6 className={styles.title}>{t('Your Droppe account')}</h6>
        <p className="margin-top-spacer-half">{t('auth:All your suppliers in one place One process, one invoice')}</p>
      </div>
      <div className="margin-top-spacer-double">
        <Button
          appearance="primary"
          block
          onClick={() => openRegisterModal()}
        >
          {t('Create free account')}
        </Button>
      </div>
      <div className="margin-top-spacer">
        <Button
          appearance="ghost"
          onClick={() => openLoginModal()}
          block
          className={styles['login-button']}
        >
          {t('Login')}
        </Button>
      </div>
    </div>
  )
}

export default SignInPickerContent
