import { RefObject, forwardRef, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import MobileNavigation from './MobileNavigation'
import useHideHeaderOnScrollCondition from './hooks/useHideHeaderOnScrollCondition'
import { getRefDimension } from '../../../utils/util'
import useIsScrollingUp from '../../hooks/useIsScrollingUp'
import useIsOnMobile from '../../../services/useIsOnMobile'
import { ScreenSize } from '../../../utils/constants'
import useHasWindowScrolledToTop from '../../../services/useHasWindowScrolledToTop'

const DIVIDER_OFFSET = 4

const MobileHeader = forwardRef<HTMLElement>((_, mainHeaderRef) => {
  const [isOnTopOfPage, setIsOnTopOfPage] = useState(true)

  const { asPath } = useRouter()
  const { t } = useTranslation('url')

  const isScrollingUp = useIsScrollingUp()
  const isOnMobile = useIsOnMobile(ScreenSize.md)
  const hasScrolledToTop = useHasWindowScrolledToTop()

  const headerHeight = getRefDimension('height', mainHeaderRef as RefObject<HTMLElement>)

  // If user is on single product page, hide full header
  const isProductPage = asPath.includes(t('product'))

  // Detect whether user has scrolled to the top of the page
  useEffect(() => {
    if (hasScrolledToTop) {
      setIsOnTopOfPage(true)
    } else {
      setIsOnTopOfPage(false)
    }
  }, [hasScrolledToTop])

  // Since the mobile header is split into  2 equal sections, separated by a divider,
  // get required height by getting the header height and dividing by 2 and offset with
  // the divider height + padding to hide it as well
  const halfHeaderHeight = (headerHeight / 2) + DIVIDER_OFFSET

  // Pass the custom height into the hook that will hide the upper header half on scrolling
  // down, and show it again when scrolling up
  useHideHeaderOnScrollCondition(
    !isScrollingUp
    && !isOnTopOfPage
    && isOnMobile
    && !isProductPage, // Don't trigger on single product page, as it has own logic
    halfHeaderHeight,
  )

  return (
    <div className="box-shadow-none">
      <MobileNavigation />
    </div>
  )
})

export default MobileHeader
