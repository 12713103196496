import TagManager from 'react-gtm-module'

import { AnalyticsContext, AuthMethod } from '../types/analytics'

export interface SignUpEventParams {
  context?: AnalyticsContext
  method: AuthMethod
  email: string
  sendToProductionAnalytics?: boolean
  userType: 'buyer' | 'supplier' | 'superuser' | ''
}

const signUpEvent = ({ context = '', method, email, sendToProductionAnalytics = false, userType }: SignUpEventParams) => {
  TagManager.dataLayer({ dataLayer: {
    event: 'sign_up',
    method,
    context,
    email,
    send_to_production_analytics: sendToProductionAnalytics,
    user_type: userType,
  } })

  // Failsafe: Reset send_to_production_analytics datalayer value to false
  TagManager.dataLayer({ dataLayer: {
    send_to_production_analytics: false,
  } })
}

export default signUpEvent
