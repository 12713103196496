/* eslint-disable max-classes-per-file */

import { captureException } from '@sentry/nextjs'

import { AimeosError } from '../utils/types/response'

export class ResponseError extends Error {
  status: number

  constructor(message: string, status: number) {
    super(message)
    this.status = status
  }
}

export class ApiError extends Error {
  errors: AimeosError[]

  status: number

  constructor(message: string, status: number, errors: AimeosError[]) {
    super(message)
    this.errors = errors
    this.status = status
  }
}

export const isResponseError = (error: unknown):
  error is ResponseError => typeof error === 'object'
  && error instanceof ResponseError
  && 'status' in error

export const isApiError = (error: unknown):
   error is ApiError => error instanceof ApiError
   && Array.isArray((error as ApiError).errors)

export const hasApiError = (
  data: unknown,
): data is { errors: AimeosError[] } => data !== null
  && typeof data === 'object'
  && Array.isArray((data as { errors: AimeosError[] }).errors)
  && (data as { errors: AimeosError[] }).errors.length > 0

export const safeAwait = async <T>(
  response: Promise<Response>,
): Promise<[undefined, T] | [unknown, undefined]> => {
  try {
    const res = await response

    if (!res.ok) {
      throw new ResponseError(res.statusText, res.status)
    }

    const data: T | { errors: AimeosError[] } = await res.json()

    if (hasApiError(data)) {
      throw new ApiError(res.statusText, res.status, data.errors)
    }

    return [undefined, data]
  } catch (error) {
    console.error(error)
    captureException(error)
    return [error, undefined]
  }
}
