import { useRouter } from 'next/router'
import React, { FC, ReactNode, useEffect } from 'react'

import { Locale } from '../../../../external'
import { translateBaseUrl } from '../../../../lib/urls'
import { CountryData } from '../../../../utils/constants'
import { findCountry } from '../../../../utils/countries'
import { generateCountryAndLocaleString, getCountryAndLocaleStrings } from '../../../../utils/locales'
import useReplaceUrlWithCorrectLocale from './hooks/useReplaceUrlWithCorrectLocale'

const CountryPickerWrapper: FC<{ children: ReactNode }> = (props) => {
  const { children } = props

  const fullRouter = useRouter() // Full router passed as param elsewhere
  const {
    isReady,
    prefetch,
    locale: countryAndLocale,
    defaultLocale: defaultCountryAndLocale,
    pathname,
  } = fullRouter

  const replaceUrlWithCorrectLocale = useReplaceUrlWithCorrectLocale()

  const {
    country,
    locale: lang,
  } = getCountryAndLocaleStrings(countryAndLocale || defaultCountryAndLocale)

  const countryInfo = findCountry(country)

  useEffect(() => {
    CountryData.forEach(async ({ locale: countryLocale, locales }) => {
      const targetCountryAndLocale = generateCountryAndLocaleString(countryLocale, locales[0])
      const baseUrl = await translateBaseUrl(fullRouter, targetCountryAndLocale)
      await prefetch(
        baseUrl,
        undefined,
        { locale: targetCountryAndLocale },
      )
    })
  }, [])

  // This effect handles a scenario where the locale is in the old format, e,g. not 'xx-yy', in that
  // case we would like to replace the locale part of the URL with the new format while keeping the
  // correct language and country
  useEffect(() => {
    if (isReady && !countryAndLocale?.includes('-')) {
      const targetLocale = countryAndLocale as Locale

      // 'en' should default to 'fi' as a country
      replaceUrlWithCorrectLocale(targetLocale, targetLocale === 'en' ? 'fi' : targetLocale)
    }
  }, [isReady, countryAndLocale])

  // Redirect to primary language if current locale isn't included in selected country's languages
  useEffect(() => {
    // Exclude 404 pages because might cause redirection loop
    if (!countryInfo?.locales.includes(lang) && pathname !== '/404') {
      const firstLanguage = countryInfo?.locales[0]

      if (firstLanguage) {
        replaceUrlWithCorrectLocale(firstLanguage)
      }
    }
  }, [country, lang])

  return (
    <div>{children}</div>
  )
}

export default CountryPickerWrapper
