import dayjs, { Dayjs } from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import 'dayjs/locale/fi'
import 'dayjs/locale/sv'
import 'dayjs/locale/de'

type DateRangeEstimation = Record<'startDate' | 'endDate', Dayjs>

export const getDateFormat = (lang: string) => {
  switch (lang) {
    case 'en':
      return 'MMM D'
    case 'fi':
      return 'D MMMM[ta]'
    default:
      return 'D MMMM'
  }
}

const checkIfWeekend = (day: number) => (day === 6) || (day === 0)

const addDays = (date: string | Date | Dayjs, numOfdays: number) => dayjs(date).add(numOfdays, 'day')

/**
 * Format a time interval based on one or two string timestamps
 * If the end timestamp is undefined then a two day interval
 * will be returned based on the from timestamp
 * @param timeframe number of days forward from start timestamp
 * @param from start timestamp
 * @param to optional end timestamp. If undefined two days after start will be used
 * @param lang language to choose locale and formatting
 * @return return Formatted string eg. fi: 31 tammikuuta - 2 helmikuuta/Jan 31 - Feb 2
 */
export const formatTimeInterval = (timeframe: string, from: string, to?: string, lang: string = 'en'): string => {
  dayjs.locale(lang) // update the dayjs locale config to use the latest locale passed
  dayjs.extend(advancedFormat)
  const dateFormat = getDateFormat(lang)

  let fromDate = dayjs()

  // This function will try to to calculate the total number of weekends in a given
  // range recuresively. This will always try to extend the range based on the total weekends.
  // This will calculate the minimum date when the product could be delivered.
  const setFromDate = ({ startDate, endDate }: DateRangeEstimation) => {
    let tempTotalWeekend = 0
    let newStartDate = dayjs(startDate)

    while (endDate.diff(newStartDate, 'day') >= 0) {
      const currentDay = newStartDate.day()
      const isWeekend = checkIfWeekend(currentDay)

      if (isWeekend) {
        tempTotalWeekend += 1
      }

      newStartDate = addDays(newStartDate, 1)
    }

    if (!tempTotalWeekend) {
      fromDate = endDate
      return
    }

    setFromDate({
      startDate: addDays(endDate, 1),
      endDate: addDays(endDate, tempTotalWeekend),
    })
  }

  setFromDate({
    startDate: dayjs(from),
    endDate: to ? dayjs(to) : addDays(from, Number(timeframe)),
  })

  let toDate = dayjs(fromDate)

  const nextday = addDays(fromDate, 1).day()
  const secondNextDay = addDays(fromDate, 2).day()

  const isNextDayWeekend = checkIfWeekend(nextday)
  const isSecondNextDayWeekend = checkIfWeekend(secondNextDay)

  if (isNextDayWeekend && isSecondNextDayWeekend) {
    toDate = addDays(toDate, 3)
  } else if (!isNextDayWeekend && isSecondNextDayWeekend) {
    toDate = addDays(toDate, 4)
  } else {
    toDate = addDays(toDate, 2)
  }

  if (lang === 'en') {
    if (fromDate.month() === toDate.month()) {
      // If start and end are during the same month, only mention it once
      return `${fromDate.format(dateFormat)}-${toDate.format('Do')}`
    }
    // Otherwise mention both start and end month
    return `${fromDate.format(dateFormat)} - ${toDate.format(dateFormat)}`
  }
  if (fromDate.month() === toDate.month()) {
    // If start and end are during the same month, only mention it once
    return `${fromDate.format('D')}-${toDate.format(dateFormat)}`
  }
  // Otherwise mention both start and end month
  return `${fromDate.format(dateFormat)} - ${toDate.format(dateFormat)}`
}
