module.exports = {
  locales: [
    'fi-en', 'fi-fi', 'fi-sv',
    'sv-en', 'sv-sv',
    'de-en', 'de-de',
  ],
  defaultLocale: 'fi-en',
  // Dynamically handle importing the correct locale file after changes to the locale format
  loadLocaleFrom: async (passedLocale, ns) => {
    let locale = ''
    // The current locale format is 'xx-yy', i.e - 'fi-en', where the first part is the country and the second
    // is the language. So to get the correct locale file, we split the string in the middle and take the language part
    if(passedLocale.includes('-')) {
      locale = passedLocale.split('-')[1] || 'en'
    // If the locale is in the old format or already split (e.g. - in the case of getT), then use it as is
    } else {
      locale = passedLocale || 'en'
    }
    return require(`./locales/${locale}/${ns}.json`)
  },
  pages: {
    '*': ['common', 'industry', 'auth', 'meta', 'blocker', 'modals', 'url', 'countries', 'checkout', 'products', 'landing', 'buyerProfile', 'cart'],
    'rgx:/(suppliers|products)': ['products', 'feature', 'catalog', 'color', 'marketing', 'countries', 'useCase'],
    '/product/[id]': [
      'products',
      'catalog',
      'color',
      'feature',
      'standards',
      'cart',
      'countries',
      'marketing'
    ],
    "/":['landing'],
    '/profile': ['profile'],
    '/my-account': ['profile'],
    '/my-products': ['profile'],
    '/my-orders': ['profile', 'cart'],
    '/my-imported-products': ['products, profile'],
    '/my-catalogs': ['profile'],
    '/my-orders/[id]': ['profile', 'cart'],
    '/my-catalogs/[name]': ['profile'],
    '/checkout/[step]': ['checkout', 'products', 'cart', 'buyerProfile', 'profile'],
    '/manage/products': ['supplier', 'profile'],
    '/cart': ['cart', 'products', 'catalog', 'color', 'feature', 'standards'],
    '/supplier/[supplierCode]/[[...filters]]': ['products', 'feature', 'catalog', 'color', 'marketing', 'countries'],
    '/404': ['products']
  },
  logBuild: false,
  logger: (args) => { },
}
