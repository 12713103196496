import useTranslation from 'next-translate/useTranslation'

import { getCartProductIds } from '../../lib/cart'
import { showErrorBasedNotification, showNotification } from '../../utils/notify'
import { CartResponse, OrderProductInclude, SingleOrderResponse, WithSuppliers } from '../../utils/types/Order'
import { isProductInclude } from '../../utils/types/guards/Order'
import { useCart } from '../Cart/CartProvider'
import { selectOrderAddress, selectOrderPaymentDetails, selectOrderPaymentService } from '../Cart/selectors'
import { getOrderSupplierGroupByProducts } from '../../lib/OrderHistory'

type CartModifier = (
  cart: WithSuppliers<SingleOrderResponse<'order'>> | WithSuppliers<CartResponse>,
) => Promise<void>

const useCopyToCart = () => {
  const {
    cartState: { cart: mainCart },
    clearCart,
    addProduct,
    deleteProduct,
    addAddress,
    deleteAddresses,
    addPayment,
    updateCart,
  } = useCart()
  const { t } = useTranslation()

  const existingCartProductsCount = getCartProductIds(mainCart)?.length || 0

  const getVariantId = (orderProduct: OrderProductInclude) => orderProduct.product?.product?.find(
    (variant) => variant.id === orderProduct.attributes['order.product.productid'],
  )?.attribute[0]['attribute.id']

  const addOrderAddressToCart: CartModifier = async (cart) => {
    const addressUrl = mainCart?.links['basket.address'].href
    const paymentUrl = mainCart?.links['basket.service'].href

    if (!cart || !addressUrl || !paymentUrl || !cart.included) {
      return
    }

    const { deliveryAddress, billingAddress } = selectOrderAddress(cart)
    const { invoiceType, paymentNotes, taxNumber: businessId } = selectOrderPaymentDetails(cart)
    const paymentService = selectOrderPaymentService(cart)

    if (!deliveryAddress || !billingAddress || !paymentService) {
      return
    }

    await deleteAddresses()
    await addAddress(
      addressUrl,
      {
        deliveryAddress,
        billingAddress,
      },
    )
    await addPayment(paymentUrl, {
      invoiceType,
      paymentNotes,
      businessId,
    }, paymentService['order.service.serviceid'] as string)
    await updateCart({
      'order.comment': cart.data.attributes['order.comment'],
      'order.customerref': cart.data.attributes['order.customerref'],
      'order.parentid': cart.data.attributes['order.parentid'] || cart.data.attributes['order.id'] || null,
    })
  }

  const addOrderProductsToCart: CartModifier = async (cart) => {
    if (!cart || !cart.links || !cart.included) return
    const orderData = cart as SingleOrderResponse

    let addProductLink = ''
    // Prepare the data for all the products to be added to the cart
    const addToCartData = orderData.included
      ?.filter(isProductInclude)
      ?.filter((product) => Boolean(product.product))
      ?.map((product) => {
        const variantId = getVariantId(product)
        addProductLink = product.addToCartLink ?? ''

        return {
          id: product.attributes['order.product.parentproductid'] || product.attributes['order.product.productid'] || '',
          quantity: Number(product.attributes['order.product.quantity']),
          supplierId: product.product?.supplier[0].id ?? '',
          ...(variantId && { variant: variantId }), // pass only if the product is a variant
        }
      }) || []

    // If there are already cart items available, remove them first from the cart
    if (existingCartProductsCount > 0) {
      const { error } = await clearCart()
      if (error) {
        showErrorBasedNotification(error)
      }
    }

    if (addToCartData.length > 0) {
      const { error } = await addProduct(addProductLink, addToCartData)

      if (error) {
        showErrorBasedNotification(error)
        return
      }
    }

    showNotification('success', t('Products added'), t('You can confirm your order or continue shopping'))
  }

  const deleteInvalidProducts: CartModifier = async (cart) => {
    const modifiedCart = cart ? getOrderSupplierGroupByProducts(cart) : undefined
    if (!modifiedCart) {
      return
    }

    const { underMoqProductList, unavailableProductList } = modifiedCart
    const underMoqUrls = underMoqProductList?.map((product) => product.selfLink || '')
    const unavailableUrls = unavailableProductList?.map((product) => product.selfLink || '')

    const deleteUrls = [...underMoqUrls, ...unavailableUrls].filter((item) => Boolean(item))

    if (deleteUrls.length) {
      await deleteProduct(deleteUrls)
    }
  }

  const onAddOldCartToCart = async (oldCart: WithSuppliers<CartResponse>) => {
    await deleteInvalidProducts(oldCart)
    await addOrderProductsToCart(oldCart)
  }

  const onAddOrderToCart = async (order: WithSuppliers<SingleOrderResponse<'order'>>) => {
    await deleteInvalidProducts(order)
    await addOrderProductsToCart(order)
    await addOrderAddressToCart(order)
  }

  return {
    onAddOldCartToCart,
    onAddOrderToCart,
  }
}

export default useCopyToCart
