import { useEffect, useState } from 'react'

import { debounce } from '../utils/event-utils'

// Default value is 576px because it is the smallest breakpoint
const useIsOnMobile = (breakPointWidth: number = 576) => {
  const [isOnMobile, setIsOnMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsOnMobile(window.innerWidth <= breakPointWidth)
    }

    const debouncedHandleResize = debounce(handleResize, 100)
    handleResize()

    window.addEventListener('resize', debouncedHandleResize)
    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [])

  return isOnMobile
}

export default useIsOnMobile
