import { useContext } from 'react'

import { TrackingActions, TrackingEventName, TrackingEventParameters, UtilParameters } from '../../utils/types/analytics'
import { TrackingContext } from './trackingContext'

export interface TrackingDispatchParams {
  eventName?: TrackingEventName
  eventParameters?: TrackingEventParameters
  util?: UtilParameters
}

const useTrackingContext = () => {
  const { state, dispatch } = useContext(TrackingContext)

  const dispatchTrackingContext = (
    dispatchParams: TrackingDispatchParams,
    action: TrackingActions = TrackingActions.Add,
  ) => {
    const { eventName, eventParameters, util } = dispatchParams

    dispatch({
      type: action,
      payload: {
        eventName,
        eventParameters,
        util: {
          ...state.util,
          ...util,
        },
      },
    })
  }

  const clearTrackingContext = () => dispatch({ type: TrackingActions.Clear })

  return { state, dispatchTrackingContext, clearTrackingContext }
}

export default useTrackingContext
