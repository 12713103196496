import { FC } from 'react'

import { IAggregateIdentifierObject } from '../../../../utils/types/aimeosApi'

import styles from '../../../../styles/Header.module.less'

interface RenderCatalogNodeProps {
  catalogLabel: string
  productsCount: IAggregateIdentifierObject | undefined
}

const RenderCatalogNode: FC<RenderCatalogNodeProps> = ({ catalogLabel, productsCount }) => (
  <div className={!productsCount ? styles['disabled-category'] : ''}>
    <span>
      {catalogLabel}
    </span>
    <span className={`${styles['product-count']} ${
      !productsCount ? styles['disabled-category'] : ''
    }`}
    >
      {productsCount?.attributes || 0}
    </span>
  </div>
)

export default RenderCatalogNode
