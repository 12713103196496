import TagManager from 'react-gtm-module'

import { roundToTwoDecimals } from '../math'
import { emptyEcomDataLayer, getCartTotalDiscount, getCartTotalValue, getCartEcomEventItems } from './utils'
import { CartResponse, WithSuppliers } from '../types/Order'
import { Product } from '../types/Product'

interface AddPaymentInfoProps {
  cartData: WithSuppliers<CartResponse>
  paymentType: string
  getLocalizedProductUrl: (product?: Product) => string
}

const addPaymentInfo = ({ cartData, paymentType, getLocalizedProductUrl }: AddPaymentInfoProps) => {
  const { data } = cartData
  const items = getCartEcomEventItems({ cartData, getLocalizedProductUrl })

  if (data) {
    emptyEcomDataLayer() // First empty datalayer
    TagManager.dataLayer({ dataLayer: {
      event: 'add_payment_info',
      ecommerce: {
        payment_type: paymentType,
        affiliation: 'Droppe Marketplace',
        value: roundToTwoDecimals(data.attributes['order.price']),
        tax: roundToTwoDecimals(data.attributes['order.taxvalue']),
        shipping: roundToTwoDecimals(data.attributes['order.costs']),
        currency: data.attributes['order.currencyid'],
        coupon: 'none',
        totalValue: getCartTotalValue(cartData),
        discount: getCartTotalDiscount(cartData),
        items,
      },
    } })
  }
}

export default addPaymentInfo
