import singletonRouter, { NextRouter } from 'next/router'
import { Translate } from 'next-translate'
import getT from 'next-translate/getT'
import { ParsedUrlQuery } from 'querystring'
import sortBy from 'lodash/sortBy'
import { captureException } from '@sentry/nextjs'

import { getCountryAndLocaleStrings } from '../utils/locales'
import { parseCatalogRoute } from './route'
import { CountryData, QueryParam } from '../utils/constants'
import { countryAndLocaleList, getBasePathWithLocale } from '../utils/urls'
import { CountryAndLocale, LocaleHreflangMap } from '../external'
import { Product, Supplier } from '../utils/types/Product'
import { getSupplierDeliveryDetails } from './service'
import fetcher from '../utils/fetcher'
import { parseResponse } from '../services/useApiHelpers'
import { getProductPath } from './product'

interface NumericFilters {
  minFilters: Record<string, string >
  maxFilters: Record<string, string >
}

export const splitOnPlus = (slice: string, t: Translate, path: string) => {
  // Multiple catalogs selected or single product page
  if (path.split('/')[1] === 'product') {
    // Single product page, don't translate product code+name
    return slice
  }
  return slice
    .split('+')
    .map((categoryPathSlice) => (
      t(`catalog:${categoryPathSlice}`)))
    .join('+')
}

export const translateSlice = (pathSlice: string, idx: number, path: string, t: Translate) => {
  if (!pathSlice) return pathSlice
  if (!pathSlice.includes('?')) {
    if (path.split('/')[1] === 'supplier' && idx === 2) {
      // Don't translate supplierCode
      return pathSlice
    }
    if (pathSlice.includes('+')) {
      return splitOnPlus(pathSlice, t, path)
    }

    return t(
      `url:${pathSlice}`,
      {},
      { fallback: [`industry:${pathSlice}`, `catalog:${pathSlice}`] },
    )
  }

  const [lastPathSlice, query] = pathSlice.split('?')
  let pathWithParams = lastPathSlice.includes('+')
    ? splitOnPlus(lastPathSlice, t, path)
    : t(`url:${lastPathSlice}`, {}, { fallback: [`industry:${lastPathSlice}`, `catalog:${lastPathSlice}`] })
  if (pathWithParams.includes('catalog:')) {
    pathWithParams = lastPathSlice
  }

  return [pathWithParams, query].join('?')
}

export const translatePath = (path: string, t: Translate) => path.split('/').map((slice, idx) => {
  const translatedSlice = translateSlice(slice, idx, path, t)
  if (translatedSlice.includes('catalog:')) {
    // Could not translate, probably already translated
    return slice
  }
  return translatedSlice
}).join('/')

export type Url = Parameters<typeof singletonRouter.push>[0]

const urls = (url: Url, t: Translate): string => {
  if (typeof url === 'string') {
    return translatePath(url, t)
  }

  const { protocol, host, pathname, query, hash } = url

  let queryString = new URLSearchParams(query as { [key: string]: string }).toString()

  if (queryString) {
    queryString = `?${queryString}`
  }

  const hashString = hash ? `#${hash}` : ''

  const translatedPath = translatePath(pathname || '', t)
  const redirectPath = `${protocol || ''}${host || ''}${translatedPath}${queryString}${hashString}`

  return redirectPath
}

export const translateBaseUrl = async (
  router: NextRouter,
  newCountryAndLocale: CountryAndLocale,
  localizedProductUrls?: Record<CountryAndLocale, string>,
) => {
  try {
    const { asPath, query, pathname, locale: routerCountryAndLocale } = router
    const { locale: oldLocale } = getCountryAndLocaleStrings(routerCountryAndLocale)
    const { locale: newLocale } = getCountryAndLocaleStrings(newCountryAndLocale)

    const tOld = await getT(oldLocale, 'url')
    const tNewUrl = await getT(newLocale, 'url')
    const tUrlEn = await getT('en', 'url')
    const urlHash = asPath.includes('#') ? `#${asPath.split('#')[1]}` : ''

    const createCatalogUrl = (catalog: 'products' | 'suppliers') => {
      const { category } = parseCatalogRoute(query[QueryParam.filters])
      const categorySlice = category.length ? `${tNewUrl('category')}/${category}/` : ''
      return `/${tNewUrl(catalog)}/${categorySlice}/${urlHash}`
    }

    const createProductCatalogUrl = () => {
      const { filters: path, ...filters } = query

      // Get category path
      const categories = Array.isArray(path) ? path?.filter((cat) => cat.includes('c_')) : ''
      const categoriesPath = path?.length ? `/${tNewUrl('category')}/${Array.isArray(categories) ? categories?.join('/') : ''}` : ''

      // Get filter query params
      const activeFilters = Object.keys(filters)
      const filterParams = activeFilters.length ? `?${activeFilters.map((filter) => `${filter}=${filters[filter]}`).join('&')}` : ''

      const url = `/${tNewUrl('products')}${categoriesPath}${filterParams}${urlHash}`

      return url
    }

    switch (pathname) {
      case '/product/[id]': {
        const fullProductUrl = localizedProductUrls?.[newCountryAndLocale]
        const productPath = fullProductUrl?.split(newCountryAndLocale)[1] || asPath

        return `${productPath}${urlHash}`
      }
      case '/products/[[...filters]]': {
        // Product catalogue
        return createProductCatalogUrl()
      }
      case '/products': {
        // Product catalogue root
        return createCatalogUrl('products')
      }
      case '/suppliers': {
        // Supplier catalogue main page
        return createCatalogUrl('suppliers')
      }
      case '/suppliers/[[...filters]]': {
        // Supplier catalogue
        return createCatalogUrl('suppliers')
      }
      case '/supplier/[supplierCode]': {
        // Supplier store page
        const [supplierCode] = asPath.split('/').slice(-1)
        return `${urls(`/supplier/${supplierCode}`, tNewUrl)}${urlHash}`
      }
      case '/supplier/[supplierCode]/[[...filters]]': {
        const splitPath = asPath.split('/')
        const categoryCodes = splitPath.filter((cat) => cat.includes('c_'))

        const hasFiltersSubpath = splitPath.length > 3

        if (hasFiltersSubpath) {
          const supplierCode = splitPath[2]
          const categoryPath = categoryCodes.join('/')
          return `${urls(`/supplier/${supplierCode}/category/${categoryPath}`, tNewUrl)}${urlHash}`
        }

        return `${urls(asPath, tNewUrl)}${urlHash}`
      }
      case '/manage/products': {
        // Currently no support for translated urls on manage pages
        return asPath
      }
      case '/checkout/[step]': {
        // if switching a country during the checkout process, go back to the cart
        // unless in order summary
        const [step] = asPath.split('/').slice(-1)
        if (step === tOld('summary')) {
          return urls('/checkout/summary', tNewUrl)
        }
        return urls('/cart', tNewUrl)
      }
      case '/cart': {
        // If 'share' word is present in the url, it will mean the link is a share cart link
        const isOnShareCart = asPath.split('/').some((path) => tUrlEn(path) === 'share')
        if (isOnShareCart) {
          return urls(asPath, tNewUrl)
        }

        return `${urls('/cart', tNewUrl)}${urlHash}`
      }
      default: {
        return urls(asPath, tNewUrl)
      }
    }
  } catch (e) {
    captureException(e)
    const { asPath } = router

    // Something went wrong, probably the wanted locale was not available
    return asPath
  }
}

const extractNumericPostfix = (queryKey: string) => queryKey.slice(-4)

export const getTextFilter = (query: ParsedUrlQuery): Record<string, string > => {
  let filters = {}

  // Just extracting text filters from query params
  Object.keys(query).filter((queryKey) => queryKey.startsWith('f_')).filter(
    (queryKey) => queryKey !== 'f_delivery'
      && queryKey !== 'f_moq'
      && queryKey !== 'f_piece-package'
      && extractNumericPostfix(queryKey) !== '-min'
      && extractNumericPostfix(queryKey) !== '-max',
  ).forEach((queryKey) => {
    const filterCode = query[queryKey] as string
    filters = { ...filters, [queryKey.replace('f_', '')]: filterCode.split('-') }
  })

  return filters
}

export const getNumericFilter = (
  query: ParsedUrlQuery,
  secondLevelCategory: string,
): NumericFilters => {
  let minFilters: Record<string, string> = {}
  let maxFilters: Record<string, string> = {}
  Object.keys(query).filter(
    (queryKey) => extractNumericPostfix(queryKey) === '-min'
    || extractNumericPostfix(queryKey) === '-max'
    || queryKey === 'f_piece-package',
  ).forEach((queryKey) => {
    const queryRes = query[queryKey]
    const filterCode = typeof queryRes === 'string' ? queryRes : ''
    const postfix = extractNumericPostfix(queryKey)
    const attributeType = `${secondLevelCategory.replace('c_', '')}/${queryKey.replace(postfix, '').replace('f_', '')}` // Adding category code to the filter code

    if (postfix === '-min') {
      minFilters = { ...minFilters, [attributeType]: filterCode }
    }
    if (postfix === '-max') {
      maxFilters = { ...maxFilters, [attributeType]: filterCode }
    }
    if (queryKey === 'f_piece-package') {
      const values = sortBy(filterCode.split('-').map((val) => Number(val)))

      // when only one option selected then we will show all results
      // having products less than chosen per-box quantity
      const [low] = values
      const [high] = values.reverse()
      if (values.length === 1) {
        maxFilters = { ...maxFilters, 'per-box': low.toString() }
      } else {
        // else will take the highest and lowest from the multiple selected options
        minFilters = { ...minFilters, 'per-box': low.toString() }
        maxFilters = { ...maxFilters, 'per-box': high.toString() }
      }
    }
  })

  return { minFilters, maxFilters }
}

export const filterUnavailableCountryUrls = (
  supplier: Supplier | undefined,
  localizedUrls?: LocaleHreflangMap,
): Partial<LocaleHreflangMap> => {
  const unavailableCountries = CountryData
    .map((countryData) => countryData.locale)
    .filter((countryLocale) => !getSupplierDeliveryDetails(supplier, countryLocale).isAvailable)

  const unavailableCountryAndLangs = countryAndLocaleList
    .filter((countryAndLocaleItem) => unavailableCountries.some(
      (unavailableCountry) => countryAndLocaleItem.startsWith(unavailableCountry),
    ))

  return Object.entries(localizedUrls || {})
    .reduce((prev, [countryAndLang, localizedUrl]) => {
      const isCountryUnavailable = unavailableCountryAndLangs
        .includes(countryAndLang as CountryAndLocale)

      return isCountryUnavailable ? prev : { ...prev, [countryAndLang]: localizedUrl }
    }, {})
}

export const getLocalizedProductUrlBySKU = async (
  productSKU: string,
  countryAndLocale: CountryAndLocale,
) => {
  const { locale } = getCountryAndLocaleStrings(countryAndLocale)
  const tProducts = await getT(locale, 'products')
  const tUrl = await getT(locale, 'url')
  const baseUrl = getBasePathWithLocale(countryAndLocale)

  if (!productSKU.length) {
    console.error('Cannot get URL - missing product SKU')
    return ''
  }

  const includeQuery = `include=product/property,product/text,catalog&filter[%7E%3D][product.code]=${productSKU}`

  const localizedProductUrl = `${process.env.NEXT_PUBLIC_API_URL}/jsonapi/default/product?&locale=${locale}&${includeQuery}`

  try {
    const localizedProductResp = await fetcher(localizedProductUrl)
      .then((namesResp) => namesResp.json())
    const { data: localizedProduct } = parseResponse<Product>(localizedProductResp)

    return `${baseUrl}/${tUrl('product')}/${productSKU.toLowerCase()}+${getProductPath(localizedProduct[0], tProducts)}`
  } catch (error) {
    captureException(error)
    console.log(error)
    return ''
  }
}

export const getProductSkuFromUrl = (url: string) => url.split('+')[0].split('/').pop()?.toUpperCase()

export const translateSingleProductUrl = async (urlPath: string, locale: CountryAndLocale) => {
  const productSKU = getProductSkuFromUrl(urlPath)

  return getLocalizedProductUrlBySKU(
    productSKU || '',
    locale,
  )
}

export const getLocalizedProductUrls = async (
  apiUrl: string,
  product: Product,
) => Object.fromEntries(await Promise.all(
  countryAndLocaleList.map<Promise<[string, string]>>(async (countryAndLocale) => {
    const { locale } = getCountryAndLocaleStrings(countryAndLocale)
    const tProducts = await getT(locale, 'products')
    const tUrl = await getT(locale, 'url')
    const baseUrl = getBasePathWithLocale(countryAndLocale)

    const localizedProductUrl = `${apiUrl}/jsonapi/default/product?id=${product.id}&locale=${locale}&include=text,product/property,catalog`
    const localizedProductResp = await fetcher(localizedProductUrl)
      .then((namesResp) => namesResp.json())
    const { data: localizedProduct } = parseResponse<Product>(localizedProductResp)

    return [countryAndLocale, `${baseUrl}/${tUrl('product')}/${product['product.code'].toLowerCase()}+${getProductPath(localizedProduct[0], tProducts)}`]
  }),
)) as LocaleHreflangMap

/**
 * Canonical urls are used for duplicate content. It tells search engines
 * which url version is the main one to be crawled. Currently only english products
 * with EUR currency have duplicate content.
 *
 * This function gets the canonical url based on country availability.
 *
 * If there's no risk for duplicate content (ie. not english and EUR),
 * canonical url is self referencing.
 *
 * @param localizedUrls Hreflang urls object
 * @param countryAndLocaleString Country and locale string
 * @param supplier Supplier object
 * @returns Canonical url and hreflang urls
 */
interface GetSEOUrls {
  localizedUrls?: LocaleHreflangMap
  countryAndLocaleString: CountryAndLocale
  supplier?: Supplier
}

interface SEOUrls {
  canonicalUrl: string
  hreflangUrls: {
    [K in keyof LocaleHreflangMap | 'x-default']?: string
  }
}

export const getSEOUrls = (params: GetSEOUrls): SEOUrls => {
  const { localizedUrls, countryAndLocaleString, supplier } = params

  const filteredLocalizedUrls = filterUnavailableCountryUrls(supplier, localizedUrls)

  // Order based on countrySortOrder to get first available x-default link for a product.
  // Order prio is based on arbitrary decision because want to prio Finland and Germany
  // x-default links above Sweden.
  const countrySortOrder = ['fi', 'de']

  // Get x-default link
  const orderedlocalizedUrls = Object.entries(
    filteredLocalizedUrls,
  ).sort((a, b) => {
    const aCountry = a[0].split('-')[0]
    const bCountry = b[0].split('-')[0]

    // If country is not in countrySortOrder, push it last
    if (!countrySortOrder.includes(aCountry)) {
      return 1
    }

    if (!countrySortOrder.includes(bCountry)) {
      return -1
    }

    return countrySortOrder.indexOf(aCountry) - countrySortOrder.indexOf(bCountry)
  })

  const enLocaleLinks = orderedlocalizedUrls.filter((link) => link[0].split('-')[1] === 'en')

  // Use first available english link (most prioed x-default link)
  const xDefaultLink = enLocaleLinks?.[0]?.[1] || ''

  return {
    canonicalUrl: filteredLocalizedUrls[countryAndLocaleString] || '',
    hreflangUrls: {
      ...filteredLocalizedUrls,
      'x-default': xDefaultLink,
    },
  }
}

export default urls
