import useTranslation from 'next-translate/useTranslation'
import { FC, useEffect } from 'react'
import { FlexboxGrid, Modal, Button } from 'rsuite'

import UnsupportedIcon from '../Icons/UnsupportedIcon'
import useIsOnMobile from '../../services/useIsOnMobile'
import { ScreenSize, UPDATE_BROWSER_LINK } from '../../utils/constants'
import viewUnsupportedBrowserModalEvent from '../../utils/analytics/viewUnsupportedBrowserModalEvent'

import styles from '../../styles/UnsupportedBrowserModal.module.less'
import buttonStyles from '../../styles/CustomButtons.module.less'

const UnsupportedBrowserModal: FC = () => {
  const { t } = useTranslation('modals')
  const isOnMobile = useIsOnMobile(ScreenSize.sm)

  useEffect(() => {
    // Analytics event
    viewUnsupportedBrowserModalEvent()
  }, [])

  return (
    <Modal
      enforceFocus={false}
      size="lg"
      open
      className={styles['modal-section']}
      backdrop="static"
    >
      <Modal.Body>
        <div className={styles['mobile-header']}>
          <b>{t('Unsupported browser')}</b>
        </div>
        <FlexboxGrid
          justify="center"
          className="text-center"
        >
          <FlexboxGrid.Item className={styles.container}>
            <UnsupportedIcon />
            <h1 className={isOnMobile ? 'margin-top-spacer-tripple h4' : 'margin-top-spacer-quadruple'}>{t('Unsupported browser')}</h1>
            <p className={styles.text}>
              {t('For the best experience, we recommend using a recent version of browsers like')}
              {' '}
              <a
                href={UPDATE_BROWSER_LINK}
                target="_blank"
                rel="noopener"
              >
                Google Chrome
              </a>
              ,
              {' '}
              <a
                href={UPDATE_BROWSER_LINK}
                target="_blank"
                rel="noopener"
              >
                Safari
              </a>
              ,
              {' '}
              <a
                href={UPDATE_BROWSER_LINK}
                target="_blank"
                rel="noopener"
              >
                Mozilla Firefox
              </a>
              {' '}
              {t('or')}
              {' '}
              <a
                href={UPDATE_BROWSER_LINK}
                target="_blank"
                rel="noopener"
              >
                Microsoft Edge
              </a>
            </p>
            <a
              href={UPDATE_BROWSER_LINK}
              target="_blank"
              rel="noopener"
            >
              <Button
                appearance="primary"
                className={`margin-top-spacer-tripple ${buttonStyles['custom-button']} ${buttonStyles['button-primary']} ${buttonStyles['button-caret']}`}
                block
              >
                {t('Update browser')}
              </Button>
            </a>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Modal.Body>
    </Modal>
  )
}

export default UnsupportedBrowserModal
