import TagManager from 'react-gtm-module'

const submitBuyerOnboarding = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'submit_buyer_onboarding',
    },
  })
}

export default submitBuyerOnboarding
