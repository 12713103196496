import { useState } from 'react'

const useDrawerState = (initialState = false) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(initialState)

  const toggleDrawerOpenState = () => setIsDrawerOpen((prevState) => !prevState)

  return {
    isDrawerOpen,
    toggleDrawerOpenState,
  }
}

export default useDrawerState
