import { FC, useEffect, useState } from 'react'
import { Button, ButtonToolbar, Message, Panel } from 'rsuite'
import LegacySendIcon from '@rsuite/icons/legacy/Send'
import { captureException } from '@sentry/nextjs'

import { IAimeosResponse } from '../../utils/types/aimeosApi'
import { useAuth } from '../../services/useAuth'
import fetcher from '../../utils/fetcher'
import { StepActions } from '../../utils/types/misc'
import { SKIP_VERIFICATION_KEY } from '../../utils/constants'

const API_URL = process.env.NEXT_PUBLIC_API_URL

const SendEmailVerification: FC<StepActions> = (props) => {
  const { nextAction } = props

  const { user } = useAuth()

  const [resendStatus, setResendStatus] = useState<IAimeosResponse>({
    status: 'Unset',
    message: '',
  })

  // Skip if email already verified
  useEffect(() => {
    if (user?.email_verified_at) {
      nextAction()
    }
  }, [user?.email_verified_at])

  const resend = async () => {
    setResendStatus({ status: 'Loading', message: '' })
    try {
      const resp = await fetcher(`${API_URL}/api/v1/email/resend`)
      const respData: IAimeosResponse = await resp.json()
      setResendStatus(respData)
    } catch (error: any) {
      captureException(error)
      setResendStatus({
        status: 'Error',
        message: error,
      })
    }
  }

  const onSkipClick = () => {
    if (!localStorage.getItem(SKIP_VERIFICATION_KEY)) {
      localStorage.setItem(SKIP_VERIFICATION_KEY, 'true')
    }
    nextAction()
  }

  return (
    <Panel
      className="terms-panel"
      bordered
      header={<h3>Please verify your email</h3>}
    >
      <p>
        An email has been sent to you with a link to verify your email.
      </p>
      <Button
        onClick={resend}
        loading={resendStatus.status === 'Loading'}
        className="margin-top-spacer"
        appearance="ghost"
      >
        <LegacySendIcon />
        {' '}
        Resend to
        {' '}
        {user?.email}
      </Button>
      {resendStatus.status !== 'Unset' && resendStatus.status !== 'Loading' && (
        <Message
          type={resendStatus.status === 'Error' ? 'error' : 'success'}
          className="margin-top-form-group"
        >
          {resendStatus.message}
        </Message>
      )}
      <ButtonToolbar className="margin-top-form-group">
        <Button
          onClick={nextAction}
          appearance="primary"
        >
          Next
        </Button>
        <Button onClick={onSkipClick}>
          Skip
        </Button>
      </ButtonToolbar>
    </Panel>
  )
}

export default SendEmailVerification
