import TagManager from 'react-gtm-module'
import { Translate } from 'next-translate'

import { getModifiedCartTotalDiscount, getSupplierGroupByProducts, getSupplierMovData } from '../../lib/cart'
import { formatCategoriesForAnalytics } from '../../lib/category'
import { roundToTwoDecimals } from '../math'
import { Product, Supplier } from '../types/Product'
import { isProductInclude } from '../types/guards/Order'
import { CartResponse, OrderProductInclude, SingleOrderResponse, WithSuppliers } from '../types/Order'
import { UserIdResponse } from '../../pages/api/generate-user-id'
import { ActiveCountryId, CountryId, GENERATE_USER_ID_URL } from '../constants'
import { getBrandName, getProductName } from '../../lib/text'
import { getProductPrice } from '../../lib/resources/price'
import { getCurrencyCode } from '../locales'
import { getSalesUnit } from '../../lib/salesUnit'
import { getCurrentUpcomingDiscount } from '../../lib/resources/discount'
import { CartSupplierProducts } from '../types/Cart'
import { getProductMedia } from '../../lib/product'
import { OrderList } from '../types/buyerCatalogs'
import filterOutUnavailableCatalogProducts from '../buyerCatalogs'
import { AddToCartBuyerCatalogEventParams, AnalyticsProduct, SingleAddToCartBuyerCatalogEventParams } from '../types/analytics'

interface GetEcomItemsCartParams {
  cartData: WithSuppliers<SingleOrderResponse>
  country?: ActiveCountryId
  getLocalizedProductUrl?: (product?: Product) => string
}

export const getCartEcomEventItems = (params: GetEcomItemsCartParams): AnalyticsProduct[] => {
  const {
    cartData,
    country,
    getLocalizedProductUrl = () => '',
  } = params
  const { included, data } = cartData

  // If no products in cart, return empty array
  if (included?.filter(isProductInclude).length === 0) return []

  // If included is undefined return empty array, else return items list.
  return included
    ? included.filter(isProductInclude).map(
      (item): AnalyticsProduct => {
        const { attributes, product } = item
        const variantObj = attributes?.attribute?.find((attr: any) => attr['order.product.attribute.type'] === 'variant')
        const brand = product ? getBrandName(product) : ''
        const supplier = product?.supplier[0]['supplier.label'] || attributes['order.product.suppliername']
        const variant = variantObj ? variantObj['order.product.attribute.name'] : attributes['order.product.name']
        const orderedCategories = product ? formatCategoriesForAnalytics(product) : {}

        const supplierCode = product?.supplier[0]['supplier.code'] || ''
        const modifiedCart = getSupplierGroupByProducts(cartData)
        const { isUnderMov } = country
          ? getSupplierMovData(supplierCode, country, modifiedCart)
          : { isUnderMov: undefined }

        const productUrl = getLocalizedProductUrl(product)

        return {
          item_id: (product && product['product.code']) || '',
          item_name: attributes['order.product.name'],
          price: roundToTwoDecimals(attributes['order.product.price']),
          currency: data?.attributes['order.currencyid'],
          item_brand: brand ?? supplier,
          supplier,
          item_variant: variant,
          quantity: attributes['order.product.quantity'],
          is_under_mov: isUnderMov,
          ...orderedCategories,
          product_url: productUrl,
        }
      },
    )
    : []
}

interface GetAnalyticsProductItemParams {
  product: Product
  country: CountryId
  getLocalizedProductUrl: (product: Product) => string
}

export const getAnalyticsProduct = (
  params: GetAnalyticsProductItemParams,
): AnalyticsProduct => {
  const { product, country, getLocalizedProductUrl } = params

  const categories = formatCategoriesForAnalytics(product)
  const brand = getBrandName(product)
  const itemName = getProductName(product)
  const currency = getCurrencyCode(country)
  const price = getProductPrice(product, currency)
  const productUrl = getLocalizedProductUrl(product)

  return {
    affiliation: 'Droppe Marketplace',
    item_id: product['product.code'],
    item_name: itemName || product['product.label'],
    price,
    currency,
    item_brand: brand || `${product.supplier[0]['supplier.label']} (fallback, supplier name)`,
    supplier: product.supplier[0]['supplier.label'],
    product_url: productUrl,
    ...categories,
  }
}

interface GetEcomEventItemsFromProductsParams {
  products: Product[]
  country: CountryId
  getLocalizedProductUrl: (product: Product) => string
}

export const getEcomEventItemsFromProducts = (
  params: GetEcomEventItemsFromProductsParams,
): AnalyticsProduct[] => {
  const { products, country, getLocalizedProductUrl } = params
  return products.map((product: Product) => getAnalyticsProduct({
    product,
    country,
    getLocalizedProductUrl,
  }))
}

export const getCartTotalValue = (cartResponse: SingleOrderResponse) => roundToTwoDecimals(Number(cartResponse.data?.attributes['order.price']) + Number(cartResponse.data?.attributes['order.taxvalue']) + Number(cartResponse.data?.attributes['order.costs']))

export const getCartTotalDiscount = (cart: WithSuppliers<SingleOrderResponse>) => {
  const modifiedCart = getSupplierGroupByProducts(cart)
  return roundToTwoDecimals(getModifiedCartTotalDiscount(modifiedCart))
}

export const emptyEcomDataLayer = () => TagManager.dataLayer({ dataLayer: { ecommerce: null } })

export const getHashedUserId = async (email: string): Promise<UserIdResponse> => {
  try {
    const response = await fetch(GENERATE_USER_ID_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })

    if (!response.ok) {
      const errorData = await response.json()
      return { ...errorData, error: `Server responded with status code ${response.status}` }
    }

    const data: UserIdResponse = await response.json()
    return data
  } catch (error) {
    console.error('Network error:', error)
    return { error: 'Network error' }
  }
}

interface GetAnalyticsAllCartItemsParams {
  currency: string
  t: Translate
  modifiedCart?: CartSupplierProducts
  getLocalizedProductUrl: (product?: Product) => string
}

export const getAnalyticsAllCartItems = (
  params: GetAnalyticsAllCartItemsParams,
): (AnalyticsProduct | null)[] => {
  const {
    currency,
    modifiedCart,
    t,
    getLocalizedProductUrl,
  } = params

  const cartIncludedProducts = modifiedCart?.included
    .filter((item) => item.type === 'basket.product') as OrderProductInclude[] | undefined

  return cartIncludedProducts?.map((basketProduct) => {
    const { product } = basketProduct

    if (!product) {
      return null
    }

    // Find all product variants from cart included data, and calculate total quantity
    const variants = cartIncludedProducts.filter(
      (item) => item.product?.['product.code'] === product['product.code'],
    )
    // Create product quantity object, with keys as variants and quantities as values
      .reduce<Record<string, number>>((acc, item) => {
      const attribute = item.attributes.attribute?.[0]
      const quantity = Number(item.attributes['order.product.quantity'])
      if (attribute) {
        acc[attribute['order.product.attribute.value']] = quantity
      }
      return acc
    }, {})

    const totalQuantity = Object.values(variants).reduce((acc, quantity) => acc + quantity, 0)

    const { currentDiscount } = getCurrentUpcomingDiscount(
      product, totalQuantity,
    )

    const brand = getBrandName(product)
    const itemName = getProductName(product)
    const formattedCategories = formatCategoriesForAnalytics(product)
    const productPrice = currentDiscount
      ? Number(currentDiscount?.['price.value'])
      : getProductPrice(product)
    const quantity = basketProduct.attributes['order.product.quantity']
    const discount = roundToTwoDecimals((getProductPrice(product) - productPrice))
    const salesUnit = getSalesUnit(product)
    const salesUnitTranslated = t(`products:${salesUnit}`, { count: Number(quantity) })
    const media = product ? getProductMedia(product) : []
    const productUrl = getLocalizedProductUrl(product)

    return {
      affiliation: 'Droppe Marketplace',
      item_name: itemName ?? product['product.label'],
      item_id: product['product.code'],
      price: roundToTwoDecimals(productPrice),
      currency,
      item_brand: brand ?? `${product.supplier[0]['supplier.label']} (fallback, supplier name)`,
      discount,
      supplier: product.supplier[0]['supplier.label'],
      ...formattedCategories,
      item_variant: basketProduct.attributes.attribute?.[0]['order.product.attribute.value'],
      quantity,
      sales_unit: salesUnit,
      sales_unit_translated: salesUnitTranslated,
      image_url: media?.[0]?.original || '',
      product_url: productUrl,
    }
  }) ?? []
}

// Analytics utility function
export const triggerBuyerCatalogAddToCartAnalyticsEvent = async (
  orderList: OrderList,
  ids: string[] | undefined,
  singleAddToCartBuyerCatalogEvent: (params: SingleAddToCartBuyerCatalogEventParams) => void,
  addToCartBuyerCatalogEvent: (params: AddToCartBuyerCatalogEventParams) => void,
  refreshCart: (suppliers?: Supplier[], products?: Product[]) => Promise<{
    data: WithSuppliers<CartResponse>
    modifiedCart: CartSupplierProducts
  } | undefined>,
) => {
  const isSingleProduct = ids?.length === 1
  const catalogName = orderList.name
  const catalogId = orderList.id

  if (isSingleProduct) {
    const productItem = orderList.items.find((item) => item.productId === ids[0])

    const { modifiedCart } = await refreshCart(
      productItem?.product.supplier, productItem?.product ? [productItem.product] : [],
    ) || {}

    const singleProductData = productItem
      ? [{ product: productItem?.product, productVariants: productItem?.quantity }]
      : []

    singleAddToCartBuyerCatalogEvent({
      productsData: singleProductData,
      productSku: productItem?.product['product.code'] || '',
      catalogName,
      catalogId,
      modifiedCart,
    })
  } else {
    const hasSelectedProducts = !!ids?.length
    const productsData = filterOutUnavailableCatalogProducts(orderList.items)
      .filter((item) => (hasSelectedProducts ? ids.includes(item.productId) : true))
      .map((item) => ({
        product: item.product,
        productVariants: item.quantity,
      }))

    const productsList = productsData.map((productsDataItem) => productsDataItem.product)
    const supplierList = productsList.map((product) => product.supplier[0])

    const { modifiedCart } = await refreshCart(supplierList, productsList) || {}

    addToCartBuyerCatalogEvent({
      productsData,
      catalogName,
      catalogId,
      isPartial: hasSelectedProducts,
      modifiedCart,
    })
  }
}
