import { useRouter } from 'next/router'

import useUrls from '../../../services/useUrls'
import useIsUrlHashIncluded from '../../../services/useIsUrlHashIncluded'
import { ModalUrlHash, PREVIOUS_URL_STORAGE_KEY } from '../../../utils/constants'

/**
 * Uses session storage to handle proper history stack management and edge cases when closing modal.
 *
 * @param urlHash Modal hash
 * @returns Modal close handler for modal with given hash
 */
const useCloseHashModal = (urlHash: ModalUrlHash) => {
  const { replaceT } = useUrls()
  const { asPath } = useRouter()
  const isUrlHashIncluded = useIsUrlHashIncluded(urlHash)

  const handleCloseModal = () => {
    if (!isUrlHashIncluded) return

    const previousUrl = sessionStorage.getItem(PREVIOUS_URL_STORAGE_KEY)

    if (previousUrl === window.location.pathname) {
      window.history.back()
    } else {
      replaceT(
        asPath.replace(urlHash, ''),
        undefined,
        { shallow: true, scroll: false },
      )
    }
  }

  return handleCloseModal
}

export default useCloseHashModal
