import { Translate } from 'next-translate'

export const parseCatalogRoute = (
  filters: string | string[] | undefined,
) => {
  if (!filters || (Array.isArray(filters) && filters.length === 0)) {
    return { isInvalid: false, category: '' }
  }
  return { isInvalid: false, category: filters.at(-1) ?? '' }
}

export const isCategorySelected = (
  filters: string | string[] | undefined,
  t: Translate,
) => filters?.[0] === t('url:category')
