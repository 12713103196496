import { useMemo } from 'react'
import useSWR from 'swr'

import { getProductIdsInOrder } from '../../../lib/OrderHistory'
import { Product, Supplier } from '../../../utils/types/Product'
import { useApi } from '../../../services/useApi'
import { ProductBaseIncludes, ResourceTag } from '../../../utils/constants'
import { decycle } from '../../../services/useApiHelpers'
import { getProductAddToCartLink } from '../../../lib/product'
import {
  ErrorResponse,
  OrderProductInclude,
  SingleOrderResponse,
  WithSuppliers,
} from '../../../utils/types/Order'
import { isProductInclude, responseHasErrors } from '../../../utils/types/guards/Order'

interface InitOrder<T> {
  orderData: T | undefined
  errors: null
  isLoading: boolean
}

interface InitOrderError {
  orderData: undefined
  errors: ErrorResponse['errors']
  isLoading: boolean
}

const useInitOrder = <OrderDataType = WithSuppliers<SingleOrderResponse<'order'>>>(
  orderId?: string,
  isSuperUser?: boolean,
): InitOrder<OrderDataType> | InitOrderError => {
  const { getResource } = useApi()

  const apiUrl = process.env.NEXT_PUBLIC_API_URL
  let baseUrl = `${apiUrl}/jsonapi/default/order`
  let orderUrl = `${baseUrl}?id=${orderId}&include=order/address,order/coupon,order/product,order/service`

  if (isSuperUser) {
    baseUrl = `${apiUrl}/admin/default/jsonadm`
    orderUrl = `${baseUrl}/order?id=${orderId}&include=order,order/address,order/coupon,order/product,order/service`
  }

  const { data: orderResult } = useSWR<SingleOrderResponse<'order'> | ErrorResponse>(orderId ? orderUrl : null)
  const hasErrors = orderResult && responseHasErrors(orderResult)

  const useFetchProducts = () => {
    const productIds = !hasErrors && orderResult?.included
      ? getProductIdsInOrder(orderResult.included)
      : []
    const includedProductCount = !hasErrors
      ? orderResult?.included?.filter(isProductInclude).length
      : undefined
    const prodFilterSeparator = '&filter[%7C%7C][][%3D%3D][product.id]='
    const prodsFilter = productIds ? `${prodFilterSeparator}${(productIds).join(prodFilterSeparator)}` : ''
    const productsQuery = `include=${ProductBaseIncludes.join(',')}${prodsFilter}`

    const products = getResource<Product>(
      includedProductCount ? ResourceTag.product : null,
      productsQuery,
      { countrySpecific: false }, // disable country specific filtering
    )

    // Fetch supplier data for products that are not available anymore
    const supplierCodesForUnavailableProducts = orderResult
    && !responseHasErrors(orderResult)
    && orderResult?.included
      ? orderResult?.included
        .filter(isProductInclude)
        .map((included) => included.attributes['order.product.vendor'])
        .filter((supplierCode) => (
          !products.data
            .flatMap((product) => product.supplier.map((supplier) => supplier['supplier.code']))
            .includes(supplierCode)
        )) || []
      : []
    const supplierCodeFilterSeparator = '&filter[%7C%7C][][%3D%3D][supplier.code]='
    const supplierQuery = `${supplierCodeFilterSeparator}${supplierCodesForUnavailableProducts.join(supplierCodeFilterSeparator)}`
    const shouldLoadSuppliers = !products.isLoading
      && includedProductCount
      && products.data.length < includedProductCount
    const {
      data: suppliers,
    } = getResource<Supplier>(shouldLoadSuppliers ? 'supplier' : null, supplierQuery)

    return {
      ...products,
      suppliers,
    }
  }

  const {
    data: products,
    isLoading: areProductsLoading,
    links,
    suppliers: suppliersWithoutProducts,
  } = useFetchProducts()

  useMemo(() => decycle(ResourceTag.product, products), [JSON.stringify(products)])

  const addToCartLink = getProductAddToCartLink(links)
  const suppliers = products
    .flatMap((product) => product.supplier || [])
    .concat(suppliersWithoutProducts)

  const getProductSelfLink = (orderProduct: OrderProductInclude) => {
    const linkObject = links.find((link) => {
      const selfLink = typeof link.self === 'string' ? link.self : link.self?.href
      return selfLink.includes(orderProduct.attributes['order.product.parentproductid'] || orderProduct.attributes['order.product.productid'])
    })

    return typeof linkObject?.self === 'string' ? linkObject.self : linkObject?.self.href || ''
  }
  const buildOrderData = (
    updatedOrderResult: SingleOrderResponse<'order'>,
    orderProducts: Product[],
  ): WithSuppliers<SingleOrderResponse<'order'>> => ({
    ...updatedOrderResult,
    included: updatedOrderResult?.included
      ?.map((item) => {
        if (isProductInclude(item)) {
          return {
            ...item,
            addToCartLink,
            selfLink: getProductSelfLink(item),
            // Add full product data for each included product in the cart
            product: orderProducts.find((prod) => (
              // Find correct product based on code or variant code
              prod['product.code'] === item.attributes['order.product.prodcode']
                  || prod.product?.find((variant) => (
                    variant['product.code'] === item.attributes['order.product.prodcode'])))),
          }
        }

        return item // No need to add data to services (shipping/payment)
      }),
    suppliers,
  })

  const isOrderDataReady = !hasErrors && orderResult && !areProductsLoading
  const orderData = useMemo(() => (
    isOrderDataReady
      ? buildOrderData(orderResult, products)
      : undefined
  ),
  [
    isOrderDataReady,
    orderResult,
    JSON.stringify(products),
    JSON.stringify(suppliersWithoutProducts),
  ])

  if (orderResult && responseHasErrors(orderResult)) {
    return {
      orderData: undefined,
      errors: orderResult.errors,
      isLoading: false,
    }
  }

  return {
    orderData: orderData as OrderDataType,
    errors: null,
    isLoading: !orderResult?.data || areProductsLoading,
  }
}

export default useInitOrder
