import { useEffect, useRef, useState } from 'react'
import debounce from 'lodash/debounce'

const useIsScrollingUp = (): boolean => {
  const [scrollingUp, setScrollingUp] = useState<boolean>(false)
  const prevScrollY = useRef<number>(0)

  useEffect(() => {
    const handleScrollingUp = () => {
      debounce(() => {
        const currentScrollY = window.scrollY
        if (prevScrollY.current < currentScrollY && scrollingUp) {
          setScrollingUp(false)
        }
        if (prevScrollY.current > currentScrollY && !scrollingUp) {
          setScrollingUp(true)
        }
        prevScrollY.current = currentScrollY
      }, 100)()
    }

    window.addEventListener('scroll', handleScrollingUp, { passive: true })

    return () => window.removeEventListener('scroll', handleScrollingUp)
  }, [scrollingUp])

  return scrollingUp
}

export default useIsScrollingUp
