import { FC } from 'react'
import { FlexboxGrid } from 'rsuite'
import { useRouter } from 'next/router'
import AngleDownLegacyIcon from '@rsuite/icons/legacy/AngleDown'

import { findCountry, renderFlag } from '../../../../utils/countries'
import { getCountryAndLocaleStrings } from '../../../../utils/locales'
import useIsOnMobile from '../../../../services/useIsOnMobile'
import { ScreenSize } from '../../../../utils/constants'

import styles from '../../../../styles/CountryPicker.module.less'

interface CountryPickerTriggerProps {
  onClickCallback?: () => void
  isFlagVisible?: boolean
  showCountryLabelMobile?: boolean
}

const CountryPickerTrigger: FC<CountryPickerTriggerProps> = (props) => {
  const { onClickCallback, isFlagVisible = true, showCountryLabelMobile = false } = props

  const { locale: countryAndLocale, defaultLocale: defaultCountryAndLocale } = useRouter()
  const isOnMobile = useIsOnMobile(ScreenSize.md)

  const { country } = getCountryAndLocaleStrings(countryAndLocale || defaultCountryAndLocale)
  const countryInfo = findCountry(country)

  return (
    <FlexboxGrid
      className="no-flex-wrap"
      align="middle"
      data-testid="country-picker"
      onClick={onClickCallback}
    >
      {isFlagVisible && renderFlag(countryInfo?.locale || 'fi')}
      {(!isOnMobile || showCountryLabelMobile) && (
        <FlexboxGrid.Item className={styles['country-label']}>
          {countryInfo?.native}
        </FlexboxGrid.Item>
      )}
      <FlexboxGrid.Item>
        <AngleDownLegacyIcon className={`${styles.icon} ${!isOnMobile ? 'margin-left-spacer' : ''}`} />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  )
}

export default CountryPickerTrigger
