import { FC } from 'react'

const SwedishFlag: FC = () => (
  <svg
    width="50"
    height="42"
    viewBox="0 0 50 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="10"
      y="10"
      width="30"
      height="22"
      rx="1"
      fill="#066AA7"
    />
    <rect
      x="17.5"
      y="10"
      width="4.5"
      height="22"
      fill="#FECC00"
    />
    <rect
      x="40"
      y="18.7998"
      width="4.4"
      height="30"
      transform="rotate(90 40 18.7998)"
      fill="#FECC00"
    />
    <defs>
      <filter
        id="filter0_dd_3426_1732"
        x="0"
        y="0"
        width="50"
        height="42"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood
          floodOpacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.7625 0 0 0 0 0.7625 0 0 0 0 0.7625 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3426_1732"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_3426_1732"
          result="effect2_dropShadow_3426_1732"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_3426_1732"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

export default SwedishFlag
