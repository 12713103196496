import TagManager from 'react-gtm-module'
import { Translate } from 'next-translate'

import { formatCategoriesForAnalytics } from '../../lib/category'
import { getCurrentUpcomingDiscount } from '../../lib/resources/discount'
import { getProductPrice } from '../../lib/resources/price'
import { roundToTwoDecimals } from '../math'
import { Product } from '../types/Product'
import { getBrandName, getProductName } from '../../lib/text'
import { isDefined } from '../types/misc'
import { CountryId } from '../constants'
import { getCurrencyCode } from '../locales'
import { CalculateTakeRate } from '../../services/useTakeRate'
import { getSalesUnit } from '../../lib/salesUnit'
import { getProductMedia } from '../../lib/product'
import { AnalyticsContext, AnalyticsProduct } from '../types/analytics'
import { CartSupplierProducts } from '../types/Cart'
import { emptyEcomDataLayer, getAnalyticsAllCartItems } from './utils'

export interface AddToCartProps {
  productsData: {
    product: Product
    productVariants: Record<string, number>
    isUnderMov?: boolean
  }[]
  context?: AnalyticsContext
  isUnderMOQ?: boolean
  catalogName?: string
  country: CountryId
  calculateTakeRate: CalculateTakeRate
  t: Translate
  shareCartUrl?: string
  modifiedCart?: CartSupplierProducts
  getLocalizedProductUrl: (product?: Product) => string
}

const addToCart = (props: AddToCartProps) => {
  const {
    productsData,
    context,
    isUnderMOQ,
    catalogName,
    country,
    calculateTakeRate,
    shareCartUrl,
    modifiedCart,
    t,
    getLocalizedProductUrl,
  } = props

  // Is under MOQ uses a different event name, whereas is under MOV is used in add_to_cart params
  // Reason: Under MOQ event doesn't actually add products to cart, but under MOV does
  const eventName = isUnderMOQ ? 'add_to_cart_under_moq' : 'add_to_cart'
  const currency = getCurrencyCode(country)

  const items: AnalyticsProduct[] = productsData.flatMap((productData) => {
    const { product: productWithoutTakeRate, productVariants, isUnderMov } = productData
    const totalQuantity = Object.values(productVariants).reduce((acc, curr) => acc + curr, 0)
    const { product } = calculateTakeRate(productWithoutTakeRate, Number(totalQuantity))
    const { currentDiscount } = getCurrentUpcomingDiscount(product, Number(totalQuantity))
    const brand = getBrandName(product)
    const itemName = getProductName(product)
    const salesUnit = getSalesUnit(product)
    const media = product ? getProductMedia(product) : []
    const formattedCategories = formatCategoriesForAnalytics(product)
    const productPrice = currentDiscount ? Number(currentDiscount?.['price.value']) : getProductPrice(product)
    const discount = (getProductPrice(product) - productPrice)

    return Object.entries(productVariants)
      .map(([variant, quantity]) => {
        if (Number(quantity) > 0) {
          return {
            affiliation: 'Droppe Marketplace',
            item_name: itemName ?? product['product.label'],
            item_id: product['product.code'],
            price: Number(productPrice.toFixed(2)),
            currency,
            item_brand: brand ?? `${product.supplier[0]['supplier.label']} (fallback, supplier name)`,
            discount,
            supplier: product.supplier[0]['supplier.label'],
            ...formattedCategories,
            item_variant: variant,
            quantity,
            sales_unit: salesUnit,
            sales_unit_translated: t(`products:${salesUnit}`, { count: Number(quantity) }),
            image_url: media?.[0]?.original || '',
            is_under_mov: isUnderMov,
            product_url: getLocalizedProductUrl(product),
          }
        }
        return null
      })
      .filter(isDefined)
  })

  const allCartItems = getAnalyticsAllCartItems(
    { currency, t, modifiedCart, getLocalizedProductUrl },
  )

  // Sum of all item variant prices
  const value = items.reduce((acc, item) => acc + (Number(item.price) * Number(item.quantity)), 0)
  const isUnderMov = items.some((item) => item.is_under_mov)

  emptyEcomDataLayer() // First empty datalayer
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      context,
      is_under_mov: isUnderMov,
      catalog_name: catalogName,
      currency,
      value: roundToTwoDecimals(value),
      share_cart_url: shareCartUrl,
      ecommerce: {
        items,
        allCartItems,
      },
    },
  })
}

export default addToCart
