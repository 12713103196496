import {
  getStatus,
  PartialProduct,
  Product,
  ProductAttribute,
  ProductStock,
  VariantTypeValue,
} from '../utils/types/Product'
import { ProductProperty } from '../utils/types/Resource'

export const getVariantType = (variants?: Product[] | PartialProduct[]): VariantTypeValue => (
  variants?.flatMap((variant) => (
    variant.attribute.map((variantAttribute) => {
      switch (variantAttribute['attribute.type']) {
        case 'color':
          return 'color'
        case 'size':
          return 'size'
        case 'variant':
          // TODO: Can be removed when all data has been converted to 'size'
          return 'size'
        default:
          return undefined
      }
    })))[0]
)

export const getVariantAttributePlaceholder = (type?: VariantTypeValue, index?: number) => {
  const variantAttribute: ProductAttribute[] = []
  const code = index !== undefined ? `placeholder-${index + 1}` : 'placeholder'
  switch (type) {
    case 'color':
      variantAttribute.push({
        id: '',
        'attribute.code': code,
        'attribute.label': 'Placeholder',
        'attribute.type': 'color',
        'attribute.domain': 'product',
      })
      break
    case 'size':
      variantAttribute.push({
        id: '',
        'attribute.code': code,
        'attribute.label': 'Placeholder',
        'attribute.type': 'size',
        'attribute.domain': 'product',
      })
      break
    default:
      break
  }
  return variantAttribute
}
export const getVariantPlaceholder = (
  code: string,
  productProperty?: ProductProperty[],
  stock?: ProductStock[],
): Product => ({
  id: '',
  'product.label': 'Variant',
  variantIndex: 0, // to store some local id of the variant
  attribute: [],
  'product/property': productProperty?.filter((prop) => prop['product.property.type'] === 'ean')?.map((prop) => ({
    ...prop,
    id: '',
  })) || [],
  stock: [{
    id: '',
    'stock.type': 'default',
    'stock.stocklevel': null,
    'stock.productid': '',
    'stock.timeframe': (stock && stock.length > 0) ? stock[0]['stock.timeframe'] : '',
    'stock.dateback': null,
  }],
  'product.code': code,
  catalog: [],
  media: [],
  price: [],
  supplier: [],
  text: [],
  'product.id': '',
  'product.scale': '',
  'product.target': '',
  'product.status': getStatus.public,
  'product.type': 'default',
  'product.url': '',
})
