import { useEffect, useRef, useState } from 'react'
import {
  Button,
  ButtonToolbar,
  FlexboxGrid,
  Form,
  Panel,
  Schema,
  Message,
  FormInstance,
} from 'rsuite'

import { useAuth } from '../../services/useAuth'
import { useAdminApi } from '../../services/useApi'
import { Customer } from '../../utils/types/Product'
import { StepActions } from '../../utils/types/misc'

import customFormStyles from '../../styles/CustomForm.module.less'

type FormKeys = 'firstname' | 'lastname' | 'company'

const { StringType } = Schema.Types
const Model = Schema.Model({
  company: StringType().isRequired('This field is required'),
  firstname: StringType().isRequired('This field is required'),
  lastname: StringType().isRequired('This field is required'),
})

const RESOURCE_TAG = 'customer'

const FORM_INPUTS: {
  inputName: FormKeys
  label: string
  readOnly?: boolean
}[] = [
  {
    inputName: 'company',
    label: 'Company',
    readOnly: true,
  },
  {
    inputName: 'firstname',
    label: 'First Name',
  },
  {
    inputName: 'lastname',
    label: 'Last Name',
  },
]

const ProfileDetails: React.FC<StepActions> = (props) => {
  const { nextAction } = props

  const formRef = useRef<FormInstance>(null)
  const { user, refresh } = useAuth()
  const { getResource, updateResource } = useAdminApi()

  const [profileError, setProfileError] = useState('')
  const [isUpdating, setIsUpdating] = useState(false)
  const [formErrors, setFormErrors] = useState<Record<string, string | undefined>>({})
  const [formValue, setFormValue] = useState<Record<FormKeys, string>>({
    company: '',
    firstname: '',
    lastname: '',
  })

  const userIdParam = user ? `&id=${user.id.toString()}` : ''
  const {
    data,
    isLoading: isCustomerDataLoading,
  } = getResource<Customer>(RESOURCE_TAG, userIdParam)

  // Skip the profile details step if name and company data is available
  useEffect(() => {
    if (user?.company && user?.firstname) nextAction()
  }, [user?.company, user?.firstname])

  useEffect(() => {
    if (!isCustomerDataLoading && data?.length > 0) {
      data.forEach((info) => {
        setFormValue({
          // Added fallback to customer.label in case company is not available
          // TODO: Remove when root cause is fixed - DOD-2584
          company: info?.['customer.company'] || info?.['customer.label'],
          firstname: info?.['customer.firstname'],
          lastname: info?.['customer.lastname'],
        })
      })
    }
  }, [isCustomerDataLoading, data?.length])

  const updateProfileDetails = async () => {
    if (Object.values(formErrors).length || !formRef?.current?.check()) {
      console.error('Form Error')
      return
    }
    setIsUpdating(true)
    const [updateResult, errs] = await updateResource(
      RESOURCE_TAG, formValue as Partial<Customer>, userIdParam,
    )
    if (updateResult) {
      nextAction()
      await refresh()
    } else {
      setIsUpdating(false)
      setProfileError(JSON.stringify(errs))
      console.error('Failed to update profile details.', errs)
    }
  }

  const areAllFormValuesAvailable = !Object.values(formValue).every((val) => Boolean(val))

  return (
    <FlexboxGrid justify="center">
      <FlexboxGrid.Item>
        <Panel
          header={<h3>Supplier information</h3>}
          bordered
        >
          <Form
            onChange={(newFormValue: Record<FormKeys, string>) => setFormValue(newFormValue)}
            onCheck={(newFormError: Record<string, string>) => setFormErrors(newFormError)}
            formValue={formValue}
            ref={formRef}
            model={Model}
            noValidate
            className={customFormStyles['custom-form']}
          >
            {FORM_INPUTS.map(({ inputName, label, readOnly }) => (
              <Form.Group key={inputName}>
                <Form.ControlLabel htmlFor={inputName}>
                  {label}
                </Form.ControlLabel>
                <Form.Control
                  name={inputName}
                  type="text"
                  id={inputName}
                  autoComplete={inputName}
                  required
                  readOnly={readOnly}
                />
              </Form.Group>
            ))}
            <Form.Group key="buttons">
              <ButtonToolbar>
                <Button
                  appearance="primary"
                  type="submit"
                  onClick={updateProfileDetails}
                  loading={isUpdating || isCustomerDataLoading}
                  disabled={areAllFormValuesAvailable}
                >
                  Submit
                </Button>
              </ButtonToolbar>
            </Form.Group>
          </Form>
          {profileError && (
            <Message
              type="error"
              title="Error:"
              className="margin-top-form-group"
            >
              {profileError}
            </Message>
          )}
        </Panel>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  )
}

export default ProfileDetails
