import TagManager from 'react-gtm-module'

const exception = (description: string = '', fatal: boolean = false) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'exception',
      description,
      fatal,
    },
  })
}

export default exception
