import { FC } from 'react'
import { Drawer } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'

import CountryPickerTrigger from './CountryPickerTrigger'
import CountryPickerContent from './CountryPickerContent'
import useDrawerState from '../hooks/useDrawerState'
import MobileDrawerHeader from '../MobileUI/MobileDrawerHeader'

import styles from '../../../../styles/CountryPicker.module.less'

const MobileCountryPicker: FC = () => {
  const { t } = useTranslation('common')

  const { isDrawerOpen, toggleDrawerOpenState } = useDrawerState()

  const closeDrawerOnSelect = () => toggleDrawerOpenState()

  return (
    <>
      <div>
        <CountryPickerTrigger onClickCallback={toggleDrawerOpenState} />
      </div>
      <Drawer
        size="xs"
        placement="bottom"
        open={isDrawerOpen}
        onClose={toggleDrawerOpenState}
        className={styles['mobile-country-picker']}
      >
        <MobileDrawerHeader additionalHeaderClassNames={styles['mobile-country-picker-drawer-header']}>
          {t('Choose country and language')}
        </MobileDrawerHeader>
        <Drawer.Body className={styles['country-picker']}>
          <CountryPickerContent handleSelect={closeDrawerOnSelect} />
        </Drawer.Body>
      </Drawer>
    </>
  )
}

export default MobileCountryPicker
