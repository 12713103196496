import TagManager from 'react-gtm-module'

import { AnalyticsContext, AuthMethod } from '../types/analytics'

export interface LoginEventParams {
  context?: AnalyticsContext
  method: AuthMethod
  email: string
  userType: 'buyer' | 'supplier' | 'superuser' | ''
}

const loginEvent = ({ context, method, email, userType }: LoginEventParams) => {
  TagManager.dataLayer({ dataLayer: {
    event: 'login',
    method,
    context,
    email,
    user_type: userType,
  } })
}

export default loginEvent
