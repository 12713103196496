import { Translate } from 'next-translate'
import { toaster, Notification } from 'rsuite'
import { ValueType } from 'rsuite/esm/Radio'

import { selectDefaultCategory } from './category'
import { isDefined, ParentPointer } from '../utils/types/misc'
import { DataItemType } from '../utils/types/rsuite'
import { removeDuplicates } from '../utils/util'
import { ALERT_DURATION } from '../utils/constants'
import { Catalog } from '../utils/types/Product'

/**
 * Returns a path to redirect to if some category does not exist
 * Returns undefined if everything is fine and no redirects are needed
 */
export const categoryOnMountLogic = (
  parentCategory: string,
  selectedCategory: string,
  validParentCategoryId: string | undefined,
  categories: Catalog[],
  redirectTo: string,
  t: Translate,
): string | undefined => {
  const defaultCategory = selectDefaultCategory(selectedCategory, categories)
  const isParentCategoryWrong = parentCategory && !validParentCategoryId
  const isWrongSubCategoryPresent = !!categories?.length && defaultCategory.length === 0

  if (isParentCategoryWrong || isWrongSubCategoryPresent) {
    toaster.push(
      <Notification
        type="info"
        header={t('common:Oh no!')}
        duration={ALERT_DURATION}
        closable
      >
        {t('products:missing_category',
          isWrongSubCategoryPresent ? { category: `"${selectedCategory}"` } : { category: `"${parentCategory}"` })}
      </Notification>,
      {
        placement: 'bottomEnd',
      },
    )

    return redirectTo
  }

  return undefined
}

// recursive function to get all parent and child nodes of a category
export const getExpandedItemValues = (
  activeFilter: ValueType[],
  categories: DataItemType[],
): string[] => {
  const isInSelected = (cat: DataItemType) => activeFilter?.includes(cat.value)

  // to get all the children of the node
  const getAllChildNodes = (
    children: DataItemType<string>[] | undefined,
    childPointerKeys: string[],
  ): string[] => {
    // filter only those children who has children also
    const filteredChildren = children?.filter((child) => !!child.children)
    // when no more children left, return whole children array i.e. childPointerKeys
    if (!filteredChildren || filteredChildren?.length === 0) return childPointerKeys
    return filteredChildren.flatMap((child): string[] => getAllChildNodes(
      child.children,
      [...childPointerKeys, child.value], // passing the childen tree
    ))
  }

  // to get all the parents of the node
  const findByChildren = (
    children: DataItemType[],
    parentPointerKeys: string[],
  ): string[] => children.flatMap((cat) => {
    const found = isInSelected(cat)
    if (found) {
      const childNodes = cat.children
        ? getAllChildNodes(cat.children, [cat.value])
        : []
      return [...parentPointerKeys, ...childNodes]
    } if (cat.children) {
      return findByChildren(cat.children, [...parentPointerKeys, cat.value])
    }
    return []
  })

  return categories.flatMap((cat) => {
    const found = isInSelected(cat)
    if (found) {
      const childNodes = cat.children
        ? getAllChildNodes(cat.children, [cat.value])
        : []
      return childNodes
    } if (cat.children) {
      return removeDuplicates(findByChildren(cat.children, [cat.value]))
    }
    // case when category not selected
    return []
  })
}

// recursive function to get all parent nodes of a selected category
export const getParentNodes = (
  categoriesToShow: Catalog[],
  selectedCategory: string,
): ParentPointer[] => {
  let parentNodes: ParentPointer[] = []

  const setNodeStructure = (
    parentPointerKeys: ParentPointer[],
    id: string,
    code: string,
    label: string,
  ): ParentPointer[] => [
    ...parentPointerKeys,
    { id, code, label },
  ]

  const findInChildren = (
    children: Catalog[],
    parentPointerKeys: ParentPointer[],
  ) => children.every((cat) => {
    if (cat['catalog.code'] === selectedCategory) {
      parentNodes = setNodeStructure(parentPointerKeys, cat.id, cat['catalog.code'], cat['catalog.label'])
      return false
    }
    if (cat.catalog) {
      findInChildren(cat.catalog, setNodeStructure(parentPointerKeys, cat.id, cat['catalog.code'], cat['catalog.label']))
    }
    return true
  })

  categoriesToShow.every(
    (cat) => {
      if (cat['catalog.code'] === selectedCategory) {
        parentNodes = setNodeStructure([], cat.id, cat['catalog.code'], cat['catalog.label'])
        return false
      }
      if (cat.catalog) {
        findInChildren(cat.catalog, setNodeStructure([], cat.id, cat['catalog.code'], cat['catalog.label']))
      }
      return true
    },
  )
  return parentNodes
}

// Recursive function to get all children nodes of a selected category
export const getAllChildNodes = (
  categories: Catalog[],
  selectedCategory: string,
): { childNodes: Catalog[] | undefined, current: Catalog | undefined } => {
  let current: Catalog | undefined
  let childNodes: Catalog[] | undefined = []

  const findInChildren = (
    children: Catalog[], level: number,
  ) => children.every((cat) => {
    // Hide the category carousel for 3rd level category selection
    if (level >= 2) return false
    if (cat['catalog.code'] === selectedCategory) {
      current = cat
      childNodes = cat.catalog
      return false
    }
    if (cat.catalog) {
      findInChildren(cat.catalog, level + 1)
    }
    return true
  })

  categories.every(
    (cat) => {
      if (cat['catalog.code'] === selectedCategory) {
        current = cat
        childNodes = cat.catalog
        return false
      }
      if (cat.catalog) {
        findInChildren(cat.catalog, 1)
      }
      return true
    },
  )
  return {
    childNodes,
    current,
  }
}

export const getCategoryFilters = (
  categories: Catalog[] | undefined,
) => {
  if (!categories) return {}
  const allFilters = categories.flatMap((cat) => cat.attribute).filter(isDefined)
  const actualFilters = allFilters?.filter((cat) => cat.attribute?.some((attr) => attr['attribute.code'] === 'top-filter')) ?? []
  const moreFilters = allFilters?.filter((cat) => cat.attribute?.some((attr) => attr['attribute.code'] === 'advanced-filter')) ?? []

  return { actualFilters, moreFilters }
}
