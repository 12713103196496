import TagManager from 'react-gtm-module'

import { AnalyticsContext } from '../types/analytics'

const forgotPassword = (context: AnalyticsContext = '') => {
  TagManager.dataLayer({ dataLayer: {
    event: 'forgot_password',
    context,
  } })
}

export default forgotPassword
