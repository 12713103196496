import { FC, ReactNode } from 'react'
import { FlexboxGrid } from 'rsuite'
import ArrowRightLine from '@rsuite/icons/ArrowRightLine'
import ArrowDownLine from '@rsuite/icons/ArrowDownLine'
import ArrowUpLine from '@rsuite/icons/ArrowUpLine'

interface MobileMenuItemProps {
  handleClick?: () => void
  children: ReactNode
  isExpandable?: boolean
  isActive?: boolean
  additionalClassNames?: string
}

const MobileMenuItem: FC<MobileMenuItemProps> = (props) => {
  const {
    children,
    handleClick,
    isExpandable = false,
    isActive,
    additionalClassNames = '',
  } = props

  const renderRowIcon = () => {
    if (!isExpandable) return <ArrowRightLine />
    if (isActive) return <ArrowUpLine />
    return <ArrowDownLine />
  }

  return (
    <FlexboxGrid
      role="button"
      onClick={handleClick}
      justify="space-between"
      className={`
          margin-bottom-spacer-${isActive ? 'double' : 'tripple'}
          ${additionalClassNames}
          no-flex-wrap
      `}
    >
      {children}
      {renderRowIcon()}
    </FlexboxGrid>
  )
}

export default MobileMenuItem
